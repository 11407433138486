import React, { useState, useEffect, useReducer } from 'react';
import { Container, Form, Row, Col, Modal, Alert, Button, Nav } from "react-bootstrap";
import HeaderComp from '../common/header';
import FooterComp from '../common/Footer';
import Select from "react-dropdown-select";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getTopDestinations, saveTopDestinations, updateTopDestinations } from './operation';
import { FaEdit } from "react-icons/fa"
import ImageUpload from './ImageUpload';
import SidebarMenu from '../common/sidebar';


/**
 * Initial State Declaration
 */
const initialState = {
    _id: "", channel_type:"", country:"", url_en: "", url_ar: "",  active: false, selChannel:[], selCountry:[]}


  // Reducer function for useReact Hook which will update the state
  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
          ...state, id: "", channel_type:"", country:"", url_en: "", url_ar: "", active: false
        };
      case 'editTopDestinations':
        return Object.assign(state, action.payload);
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

/**
 * @description:TopDestinations Screen
 * @author: Ubedullah
 * @param {*}
 * @function TopDestinations - CMS
 * @date : 05-15-2023
 */

const Topdestinations = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [notiMessage, setNotiMessage] = useState('');
    const [notiMessageShow, setNotiMessageShow] = useState(false);
    const [notiVarient, setNotiVarient] = useState();
    const [validated, setValidated] = useState(false);
    const [enableUpdate, setEnableUpdate] = useState(false);
    const [countryTypes, setCountryTypes] = useState([{value:"India",label:"India"},{value:"Saudi Arabia",label:"Saudi Arabia"},{value:"United Arab Emirates",label:"United Arab Emirates"},{value:"Qatar",label:"Qatar"},{value:"Oman",label:"Oman"}]);
    const [chanelTypes, setChannelTypes] = useState([{value:"B2B",label:"B2B"},{value:"B2C",label:"B2C"}]);
    const [imgfile, setImgFile] = useState(false);
    const [act, setAct] = useState(true);
    const [res, setRes] = useState([]);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [cancelConfirmation, setCancelConfirmation] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [rowToDelete, setRowToDelete] = useState(null);
    const [imageUpload, setImageUpload] = useState(false)


    useEffect(() => {
        getTopDestinations({"type":"ALL"}).then(response => {
          console.log('TopDestinations response', response)
          if (response.data.suc && response.data.res) {
            convertTopDestinationsData(response.data.res)
          }
        }).catch(function (error) {
          console.log('TopDestinations response error ', error)
        });
      }, [])


/**
 * @description:TopDestinations Screen Edit functionality
 * @author: Ubedullah
 * @param {*}
 * @function editTopDestinations - CMS
 * @date : 05-15-2023
 */

  const editTopDestinations=(obj)=>{
    dispatch({ type: 'editTopDestinations', payload: obj })
    dispatch({ type: 'selChannel', payload: [{value:obj.channel,label:obj.channel}] })
    dispatch({ type: 'selCountry', payload: [{value:obj.region,label:obj.region}] })
    setRowData(obj.des_list);
    setAct(obj.status === "Active" ? true : false)
    setEnableUpdate(true)
  }

  /**
 * @description:TopDestinations Screen display UI in Table
 * @author: Ubedullah
 * @param {*}
 * @function convertTopDestinationsData - CMS
 * @date : 05-15-2023
 */

  const columns = [{
    dataField: 'country',
    text: 'Country Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'cha_type',
    text: 'Channel Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status'
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];


  const convertTopDestinationsData=(response)=>{
    let data = [];
    let index=1;
     for(let value of response){
      data.push({ country: value.region,cha_type:value.channel, status: value.status,
      options: <><span className="tbAction"><a className="ezyIcon icon-edit" onClick={()=>editTopDestinations(value)}><span>Edit</span></a></span>
      </> })
         index=index+1;
     }
     setRes(data)
  }

    const updateChanel=(obj)=>{
        dispatch({ type: 'channel_type', payload: obj[0].value })
        dispatch({ type: 'selChannel', payload: obj })
        setUnsavedChanges(true);
    }
    const updateCountry=(obj)=>{
        dispatch({ type: 'country', payload: obj[0].value })
        dispatch({ type: 'selCountry', payload: obj })
        setUnsavedChanges(true);
    }

    const updateFileName = (index, url) => {
      const updatedRows = [...rowData];
      updatedRows[index].url_img = url;
      setRowData(updatedRows);
      setUnsavedChanges(true);
      setImageUpload(true);
    }

    const handleChange = (e) => {
      const { id, value } = e.target;
      if (id === 'act') {
          setAct(e.target.checked)
      }
      console.log("checked type:::::", e.target.id);
      setUnsavedChanges(true);
    };


  /**
 * @description:TopDestinations Screen Hiding success and Error validation message
 * @author: Ubedullah
 * @param {*}
 * @function hidemessage
 * @date : 05-15-2023
 */

  const hidemessage=() =>{
    setTimeout(function () {
        setNotiMessageShow(false);
        setNotiMessage('');
        setNotiVarient('');
    }, 4000);
  }

  /**
 * @description:TopDestinations Screen Validating Form
 * @author: Ubedullah
 * @param {*}
 * @function Validating Form
 * @date : 05-15-2023
 */

  const validateTopDestinations = () => {
    setImgFile(false)
    // setIsDuration(false)
    if(state.country===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Select Country');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(state.channel_type===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Select Channel Type');
      setNotiVarient('danger')
      hidemessage();
      return false;
    } else if(rowData.length < 4) {
        setNotiMessageShow(true);
        setNotiMessage('Please Add Atleast Four You Will Love It');
        setNotiVarient('danger')
        hidemessage();
        return false;
    }
    if(rowData.length!==0){
     for(let val of rowData){
       if(val.city_en===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter City(English)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }if(val.city_ar===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter City(Arabic)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }
       if(val.des_en===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter description(English)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }if(val.des_ar===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter description(Arabic)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }
       if(val.url_img===null){
        setNotiMessageShow(true);
        setNotiMessage('Please Upload Display Image');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }
     }
    }else if(rowData.length < 4) {
        setNotiMessageShow(true);
        setNotiMessage('It is mandatory to add Four You Will Love It');
        setNotiVarient('danger')
        hidemessage();
        return false;
    }
    return true;
  }

  /**
   * @description:TopDestinations Screen Creation(save) data
   * @author: Ubedullah
   * @param {*}
   * @function handleTopDestinationsCreation - CMS
   * @date : 05-15-2023
   */

const handleTopDestinationsCreation = (event) => {
    event.preventDefault();
    if (validateTopDestinations()) {
      let suc = true;
      for (let val of res) {
        if (val.country?.toLowerCase() == state.country?.toLowerCase()) {
          if (
            val.country?.toLowerCase() == state.country?.toLowerCase() &&
            val.cha_type?.toLowerCase() == state.channel_type?.toLowerCase()
          ) {
            suc = false;
          }
        }
      }
      if(suc) {
        rowData.forEach(object => {
            delete object['showDeletePopup'];
            delete object['destination'];
        });
        const payload = {
            "region": state.country,
            "channel": state.channel_type,
            "status": act == true ? "Active" : "In-Active",
            "des_list": rowData,
            "created_by": 1
        }
        saveTopDestinations(payload).then(response => {
          console.log("Top Destinations Save response " + JSON.stringify(response.data));
          if (response.data.suc) {
            window.scrollTo(0, 0);
            setNotiMessageShow(true);
            setNotiMessage('You Will Love It are saved successfully...');
            setNotiVarient('success')
            setTimeout(function () {
              window.location="/cms/top-destinations";
            }, 2000);

          } else {
            setNotiMessageShow(true);
            setNotiMessage('You Will Love It details not saved');
            setNotiVarient('danger');
            hidemessage();
          }
        });
      } else {
        setNotiMessageShow(true);
        setNotiMessage('Country with saleschannel already exists');
        setNotiVarient('danger');
        hidemessage();
        return false;
      }
      }
    }

    /**
     * @description:TopDestinations Screen Updating(update) data
     * @author: Ubedullah
     * @param {*}
     * @function handleTopDestinationsUpdation - CMS
     * @date : 05-15-2023
     */

    const handleTopDestinationsUpdation = (event) => {
        event.preventDefault();
        if (validateTopDestinations()) {
            const status = act === true ? "Active" : "In-Active"
            state.des_list = rowData
            state.updated_by = 1;
            state.status = status
            updateTopDestinations(state).then(response => {
            console.log("Itinarary Save response " + JSON.stringify(response.data));
            if (response.data.suc) {
              window.scrollTo(0, 0);
              setNotiMessageShow(true);
              setNotiMessage('You Will Love It are updated successfully...');
              setNotiVarient('success')
              setTimeout(function () {
                window.location="/cms/top-destinations";
              }, 2000);
            } else {
              setNotiMessageShow(true);
              setNotiMessage('You Will Love It are not Updated');
              setNotiVarient('danger')
              hidemessage();
            }
          });
        }
    }

    /**
     * @description:TopDestinations Screen Image Rows Functionality (ADD)
     * @author: Ubedullah
     * @param {*}
     * @date : 05-15-2023
     */

    const [rowCount, setRowCount] = useState(1);
  const [rowData, setRowData] = useState([
    {
      destination: 1,
      city_en: "",
      city_ar: "",
      des_en: "",
      des_ar: "",
      url_img: null,
      showDeletePopup: false
    }
  ]);

  const handleAddRow = () => {
    setRowCount(rowCount + 1);
    setRowData([
      ...rowData,
      {
        destination: rowCount + 1,
        city_en: "",
        city_ar: "",
        des_en: "",
        des_ar: "",
        url_img: null,
        showDeletePopup: false
      }
    ]);
  };

  const handleTextChange = (index, field, value) => {
    const updatedRowData = [...rowData];
    updatedRowData[index][field] = value;
    setRowData(updatedRowData);
    setUnsavedChanges(true);
  };

  const updateDescEn = (data) => {
    for(let val of rowData){
      // if(Number(val.destination)===Number(data[1])){
      //   val.des_en=data[0];
      // }
         val.des_en=data;
     }
     console.log("List:::::::::::::::",rowData);
    //  const updatedRowData = [...rowData];
    //  updatedRowData[index][field] = value;
    //  setRowData(updatedRowData);
      setUnsavedChanges(true);
  }

  const updateDescAr = (data) => {
      for(let val of rowData){
           val.des_ar=data;
       }
       console.log("List:::::::::::::::",rowData);
       setUnsavedChanges(true);
    }

  const handleImageUpload = (index, file) => {
    // Check if the file is a valid image with the correct dimensions
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;
          image.onload = () => {
            if (image.width === 480 && image.height === 580) {
              const updatedRowData = [...rowData];
              updatedRowData[index].url_img = URL.createObjectURL(file.split(" ").join(""));
              setRowData(updatedRowData);
              setUnsavedChanges(true);
            } else {
                // Invalid dimensions
                setNotiMessageShow(true);
                setNotiMessage('The image dimensions should be 480x580.');
                setNotiVarient('danger')
                hidemessage();
                return false;
            }
          };
        };
        reader.readAsDataURL(file);
      } else {
        setNotiMessageShow(true);
        setNotiMessage('Please select a JPG or PNG image.');
        setNotiVarient('danger')
        hidemessage();
        return false;
      }

    // if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
    // const img = new Image();
    // img.src = URL.createObjectURL(file);

    // img.onload = () => {
    // if (img.width === 480 && img.height === 580) {
    //     const updatedRowData = [...rowData];
    //     updatedRowData[index].url_img = URL.createObjectURL(file);
    //     setRowData(updatedRowData);
    // } else {
    //     // Invalid dimensions
    //     setNotiMessageShow(true);
    //     setNotiMessage('The image dimensions should be 480x180.');
    //     setNotiVarient('danger')
    //     hidemessage();
    //     return false;
    // }
    // };
    // } else {
    //     // Invalid file type
    //     setNotiMessageShow(true);
    //     setNotiMessage('Please upload a JPG or PNG image.');
    //     setNotiVarient('danger')
    //     hidemessage();
    //     return false;
    // }
  };

  const handleDeleteImage = (index) => {
    const updatedRowData = [...rowData];
    updatedRowData[index].showDeletePopup = true;
    setRowData(updatedRowData);
  };

  const handleClosePopup = (index, confirmDelete) => {
    const updatedRowData = [...rowData];
    updatedRowData[index].showDeletePopup = false;

    if (confirmDelete) {
      updatedRowData[index].url_img = null;
    }

    setRowData(updatedRowData);
  };

  const handleCancel = () => {
    if (unsavedChanges) {
      setCancelConfirmation(true);
    } else {
      if(state.channel_type !== "" || state.country !== "") {
        dispatch({ type: 'channel_type', payload: "" })
        dispatch({ type: 'country', payload: "" })
      }
      setRowData([{
            destination: "",
            city_en: "",
            city_ar: "",
            des_en: "",
            des_ar: "",
            url_img: null,
          }]);
        setAct(true)
    }
  };

  const handleCancelConfirmation = (confirmed) => {
    if (confirmed) {
      if(state.channel_type !== "" || state.country !== "") {
        dispatch({ type: 'channel_type', payload: "" })
        dispatch({ type: 'selChannel', payload: [{value:"", label: ""}] })
        dispatch({ type: 'country', payload: "" })
        dispatch({ type: 'selCountry', payload: [{value:"",label:""}] })
      }
      setRowData([{
        destination: "",
        city_en: "",
        city_ar: "",
        des_en: "",
        des_ar: "",
        url_img: null,
      }]);
      setAct(true)
      setUnsavedChanges(false);
    }
    setCancelConfirmation(false);
  };

  const handleDeleteRow = (index) => {
    setRowToDelete(index);
    setDeleteConfirmation(true);
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed) {
      const updatedRows = [...rowData];
      updatedRows.splice(rowToDelete, 1);
      setRowData(updatedRows);
    }
    setDeleteConfirmation(false);
    setRowToDelete(null);
  };

    return (
        <>
        <HeaderComp />
        <Alert className="notification" variant={notiVarient} show={notiMessageShow}
          onClose={() => setNotiMessageShow(false)}
          dismissible> {notiMessage}</Alert>
        <Container fluid>
        <Row>
          <Col xs={6} md={2} className="sideBar">
            <SidebarMenu active="Top Destinations" />
          </Col>

          <Col xs={12} md={10} className="innerBgcolor">
                <Col className="d-flex justify-content-between align-center mt-4 pl-0 spaceBet">
                    <h6 className="ml-2 mb-0">You Will Love It</h6>
                </Col>
            <Row>

            <div className="tableForm">


            <div className="tableForminner">
                  <Form noValidate validated={validated} id="formId">
                  <div className='formSectionn'>
                    <Row>
                    <Col xs={3}>

                      <Form.Label>Select Country <sup>*</sup></Form.Label>
                      <Form.Group
                      controlId="ctyp"
                      >
                      <Select
                        disabled={enableUpdate}
                        options={countryTypes}
                        searchable={false}
                        values={state.selCountry}
                        onChange={(value) => updateCountry(value)}
                      />
                    </Form.Group>

                    </Col>


                    <Col xs={3}>

                      <Form.Label>Channel Type <sup>*</sup></Form.Label>
                      <Form.Group
                      controlId="ctyp"
                      >
                      <Select
                        disabled={enableUpdate}
                        options={chanelTypes}
                        searchable={false}
                        values={state.selChannel}
                        onChange={(value) => updateChanel(value)}
                      />
                    </Form.Group>

                    </Col>

                    <Col xs={2}>

                    </Col>

                    </Row>
                    </div>



                    <div>
      {rowData.map((row, index) => (
        <div key={index} className='upload-image mt-4'>
            <Row>
                {/* <Col xs={4}>
                    <Form.Label>
                    City Name(English) <sup>*</sup>
                    </Form.Label>
                    <Form.Group>
                    <Form.Control
                        type="text"
                        disabled={state.disabled}
                        onChange={(e) => handleTextChange(index, "destination", e.target.value)}
                        value={row.destination}
                    ></Form.Control>
                    </Form.Group>
                </Col> */}
                <Col xs={3}>
                    <Form.Label>
                    City Name(English) <sup>*</sup>
                    </Form.Label>
                    <Form.Group>
                    <Form.Control
                        type="text"
                        disabled={state.disabled}
                        onChange={(e) => handleTextChange(index, "city_en", e.target.value)}
                        value={row.city_en}
                    ></Form.Control>
                    </Form.Group>
                </Col>
                <Col xs={3}>
                    <Form.Label>
                    City Name(Arabic) <sup>*</sup>
                    </Form.Label>
                    <Form.Group>
                    <Form.Control
                        type="text"
                        disabled={state.disabled}
                        onChange={(e) => handleTextChange(index, "city_ar", e.target.value)}
                        value={row.city_ar}
                    ></Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col xs={3}>
                    <Form.Group as={Col} xs={16} controlId="desc_en">
                      <Form.Label>Description English <sup>*</sup></Form.Label>
                      <textarea
                          className='form-control'
                          value={row.des_en}
                          onChange={(e) =>
                          handleTextChange(index, "des_en", e.target.value)
                          }
                      ></textarea>
                      {/* <TextEditor textType={"Desc_EN"} sendDescEn={updateDescEn} enableUpdate={enableUpdate} updateDescEn={row.des_en} index={index+1}/> */}
                    </Form.Group>
                </Col>
                <Col xs={3}>
                    <Form.Group className="mb-3" xs={16} controlId="desc_ar">
                      <Form.Label>Description Arabic <sup>*</sup></Form.Label>
                      <textarea
                          className='form-control'
                          value={row.des_ar}
                          onChange={(e) =>
                          handleTextChange(index, "des_ar", e.target.value)
                          }
                      ></textarea>
                      {/* <TextEditor textType={"Desc_AR"} sendDescAr={updateDescAr} enableUpdate={enableUpdate} updateDescAr={row.des_ar} index={index+1}/> */}
                    </Form.Group>
                </Col>
                <Col xs={3}>

                <Form.Group controlId="recImgEn" className="mb-3">
                    <Form.Label>Display Image <sup>*</sup></Form.Label>
                      <ImageUpload reportUrls={row.url_img} sendFileUrl={updateFileName} index={index} />
                        {imgfile && (
                          <p className="vError">Please upload the Image</p>
                        )}
                        {/* <input
                        type="file"
                        accept="image/jpeg, image/png"
                        onChange={(e) => handleImageUpload(index, e.target.files[0])}
                        />
                        <span>JPG or PNG (Dimensions: 480x580)</span> */}
                    {/* {row.url_img && (
                        <div>
                            <img
                                src={row.url_img}
                                alt="Uploaded"
                                width="100"
                                height="100"
                            />
                            <button onClick={() => handleDeleteImage(index)}>×</button>
                            {row.showDeletePopup && (
                                <div>
                                    <Modal
        show={row.showDeletePopup}
        onHide={() => handleClosePopup(false)}
        backdrop="static"
        size="small"
        className="custom-dailog"
        keyboard={false}>
            <Modal.Header>
                <Modal.Title>Delete Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    <div>
                    <Form.Label className="custom-dailog-title">Are you sure you want to delete selected Image ?</Form.Label>
                    </div>

                                <Button onClick={() => handleClosePopup(index, true)}
                                    size="xs"
                                    variant="outline-primary"
                                    // disabled = {isLoading && pleasewait ? true : false }
                                    >
                                    Yes
                                </Button>
                        <Button
                            size="xs"
                            variant="outline-dark"
                            onClick={() => handleClosePopup(index, false)}
                            type="submit"> No </Button>
                </div>
             </Modal.Body>
    </Modal>
                                </div>
                            )}
                        </div>
                    )} */}
                    </Form.Group>
                    </Col>
                    {rowData.length > 4 &&
                      <Col xs={1} className='btnAlign'>
                        <span
                          className="close-icon plusBtn topClose btn btn-primary"
                          onClick={() => handleDeleteRow(index)}
                        >
                          X
                        </span>
                      </Col>
                    }

                    <Modal
                      show={deleteConfirmation}
                      onHide={() => handleClosePopup(false)}
                      backdrop="static"
                      size="small"
                      className="custom-dailog deleRow"
                      keyboard={false}>
                      <Modal.Header>
                          <Modal.Title>Delete Row </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="">
                          <div>
                            <Form.Label className="custom-dailog-title">Are you sure you want to delete this Row ?</Form.Label>
                          </div>

                          <div className="buttonGrop">
                            <Button onClick={() => handleConfirmation(true)}
                                size="xs"
                                variant="outline-primary">
                                Yes
                            </Button>
                            <Button
                                size="xs"
                                variant="outline-dark"
                                onClick={() => handleConfirmation(false)}
                                type="submit">
                                No
                            </Button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
            </Row>
          {/* <textEditorComponent value={textEditorState} /> */}
        </div>
      ))}
      <div className='addButton'>
      {rowData.length <= 5 ? <Button className={imageUpload ? "plusBtn topDesti imageUpload" : "plusBtn topDesti"} onClick={handleAddRow}><span>+</span></Button> : null}
      </div>
   
    </div>


                    <hr />

                    <Row>
                        <Col>
                        {/* <Form.Label>Status</Form.Label> */}
                        <Form.Group as={Col} className="topSwitch">
                            <Form.Check
                            type="switch"
                            id="act"
                            label="Status"
                            checked={act}
                            value={act}
                            onChange={handleChange}
                            />
                        </Form.Group>
                        </Col>


                    <Row className="submitBtn">
                      <Col className="d-flex">
                      {!enableUpdate && <Button type="button" variant="primary" size="sm" className="mr-3" onClick={handleCancel}>Cancel</Button>}
                      {cancelConfirmation && (
                            <div className="confirmation-popup">
                                <Modal
                        show={cancelConfirmation}
                        onHide={() => handleClosePopup(false)}
                        backdrop="static"
                        size="small"
                        className="custom-dailog alignDelc"
                        keyboard={false}>
                        {/* <Modal.Header>
                        <Modal.Title>Delete Image</Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body>
                        <div className="">
                        <div>
                        <Form.Label className="custom-dailog-title">Any unsaved data will be lost. Click OK to continue.</Form.Label>
                        </div>
                        <Button onClick={() => handleCancelConfirmation(true)}
                            size="xs"
                            variant="outline-primary"
                            className='mr-2'
                            // disabled = {isLoading && pleasewait ? true : false }
                            >
                            OK
                        </Button>
                        <Button
                            size="xs"
                            variant="outline-dark"
                             onClick={() => handleCancelConfirmation(false)}
                            type="submit"> Cancel </Button>
                        </div>
                        </Modal.Body>
                        </Modal>
                            </div>
                        )}
                        {!enableUpdate && <Button className="saveBttn mr-2" type="submit" variant="primary" size="sm" onClick={handleTopDestinationsCreation}><span>Save</span> </Button>
                        }
                        {enableUpdate && <Button type="submit" variant="primary" size="sm" onClick={handleTopDestinationsUpdation}>Update</Button>}
                      </Col>
                    </Row>
                    </Row>

                    </Form>
                    </div>


            </div>
            <div className='tableForm mt-3'>
            <div className="bodyContent">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbll taFeeq"
                  data={res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={res.length >10 && res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  res.length >25 && res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                {res.length === 0 &&
                 <div className="norecord">
                 <strong>No Record Found.</strong>
                 </div>
                }
            </div>
            </div>
            </Row>
          </Col>
        </Row>
    </Container>
    <FooterComp />
    </>
    );
}

export default Topdestinations;