import axios from 'axios'

const API_URL = process.env.REACT_APP_B2B_URL

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'

/**
 * @author: Lakshmi
 * @description: Integrated the Login and Roles service
 * @param {*} Service 
 */
class AuthenticationService {

    async userLogin(userData) {
        return await axios.post(`${API_URL}/att/user/confirmotp`,userData)
    }

    async handleForgotPWD(data) {
        return await axios.post(`${API_URL}/att/user/forgot-password`,data)
    }
    logout() {
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    }
}

export default new AuthenticationService()