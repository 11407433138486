import React, { useState } from 'react';
// const JSON = require("circular-json");

function Tabs(props) {

  return (
    <div className="react-tabs">
      <ul className="react-tabs__tab-list">

        {props.children.length > 0 && props.children.map((child, i) => {

          let class_name = 'react-tabs__tab ';

          if (child.key === props.active) {

            class_name = `${class_name} react-tabs__tab--selected`;

          }
          return (
            <li key={i} className={class_name} onClick={() => (console.log('child.key---' + child.key), props.onChange(child.key))}>

              {child}


            </li>
          );
        })}
      </ul>
    </div>
  );



}

export default Tabs;