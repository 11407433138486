import React, { useState, useEffect, useReducer } from 'react';
import { Container, Form, Row, Col, Modal, Alert, Button, Nav, Table, Footer } from "react-bootstrap";
import TextEditor from '../common/TextEditor';
import FileUpload from '../common/FileUpload';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { saveItinarary, updateItinarary,getItinararies,updatePackageStatus } from './operation';
import { useHistory } from 'react-router-dom';
import Select from "react-dropdown-select";
import RichTextEditor from 'react-rte';
import "react-datepicker/dist/react-datepicker.css";
import { FaEdit } from "react-icons/fa";
import SidebarMenu from '../common/sidebar';
const _ = require('lodash')
/**
 * Initial State Declaration
 */
const initialState = {
  _id: "", channel_type:"", itinerary_name_en: "", itinerary_name_ar: "", url: "", days:0,active: false,selChannel:[]}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, id: "", channel_type:"", itinerary_name_en: "", itinerary_name_ar: "", url: "", days:0,active: false
      };
    case 'editItin':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};
/**
 * @description:This Itinarary listing
 * @author: Satya
 * @param {*}
 * @function NoRecord
 * @date : 14-10-2021
 */
function Itinarary(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [notiMessage, setNotiMessage] = useState('');
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [validated, setValidated] = useState(false);
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [imgfile, setImgFile] = useState(false);
  const [showDuration, setShowDuration] = useState(false);
  const [isDuration, setIsDuration] = useState(false);
  const [chanelTypes, setChannelTypes] = useState([{value:"Saudi Arabia",label:"Saudi Arabia"},{value:"United Arab Emirates",label:"United Arab Emirates"},{value:"Qatar",label:"Qatar"}]);
  const [itinararyList, setItinararyList] = useState([]);
  const [daysSize, setDaysSize] = useState(false);
  const [res, setRes] = useState([]);

  useEffect(() => {
    getItinararies({"type":"ALL"}).then(response => {
      console.log('Package response', response)
      if (response.data.suc &&  response.data.res) {
        convertPackageData(response.data.res)
      }
    }).catch(function (error) {
      console.log('Package response error ', error)
    });
  }, [])

  const handleChangeActive = (obj,resp) => (e) => {
    let isActive=false;
    if(!obj.active){
     isActive=true;
     obj.active=true
    }else{
    obj.active=false;
    }
    updateItinarary(obj).then(response => {
         if(response.data.suc){
          setNotiMessageShow(true);
          setNotiMessage("Itinarary Status updated Successfully...");
          setNotiVarient('success')
          hidemessage();
         for(let val of resp){
           if(val._id===obj._id){
              val.active=isActive;
              break;
           }
         }
         convertPackageData(resp)
         }
     })
 }

 const editItinarary=(obj)=>{
  dispatch({ type: 'editItin', payload: obj })
  dispatch({ type: 'selChannel', payload: [{value:obj.channel_type,label:obj.channel_type}] })
  setItinararyList(obj.itinerary_list);
  setEnableUpdate(true)
}

const columns = [{
  dataField: 'name',
  text: 'Itinarary Name',
  sort: true,
  filter: textFilter()
},
{
  dataField: 'cha_type',
  text: 'Channel Type',
  sort: true,
  filter: textFilter()
},
{
  dataField: 'days',
  text: 'Itinarary Days',
  sort: true,
  filter: textFilter()
},
{
  dataField: 'options',
  text: 'Actions',
  class: 'tes'
}];

const defaultSorted = [{
  dataField: 'id',
  order: 'asc'
}];

  const convertPackageData=(response)=>{
    let data = [];
    let index=1;
     for(let value of response){
      data.push({ name: value.itinerary_name_en,cha_type:value.channel_type, days: value.days,
      options: <><span className="tbAction"><a className="ezyIcon icon-edit" onClick={()=>editItinarary(value)}><span>Edit</span></a></span>
      <div className='custom-switch'>
      <Form.Group className="d-flex align-items-center m-0" controlId="is_active">
         <Form.Check  className="p-2"
          checked={value.active}
         type="checkbox"
         label=""
         value={value.active}
         onChange={handleChangeActive(value,response)}
         id={index+"pkg"+value.id}
         custom
          />
         </Form.Group>
         </div> </> })
         index=index+1;
     }
     setRes(data)
  }

  const handleChange = (e) => {
    setDaysSize(false);
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
    console.log("checked type:::::", e.target.id);
    if(Number(value)>=1 && Number(value)<=10){
    if(id==="days"){
      let val=Number(value)+1;
      let list=[];
      for (var i = 1; i < Number(val); i++) {
        const req={
          "day" : i,
          "title_en":"",
          "title_ar":"",
          "desc_en":"",
          "desc_ar":""
        }
        list.push(req);
      }
      setItinararyList(list);
    }
  }else{
    setDaysSize(true)
    setItinararyList([])
  }
  };

  const handleChangeIti =(idx)=> (e) => {
    const { id, value } = e.target;
    dispatch({ type: id, payload: value });
   for(let val of itinararyList){
     if(Number(val.day)===Number(idx+1)){
      if(id==="title_en"){
        val.title_en=value;
      }else if(id==="title_ar"){
        val.title_ar=value;
      }else if(id==="desc_en"){
        val.desc_en=value;
      }else if(id==="desc_ar"){
        val.desc_ar=value;
      }
      }
    }
  console.log("List:::::::::::::::",itinararyList);
  };

  const updateFileName = (url) => {
    dispatch({ type: "url", payload: url });
  }

  const updateDescEn = (data) => {
    for(let val of itinararyList){
      if(Number(val.day)===Number(data[1])){
         val.desc_en=data[0];
       }
     }
     console.log("List:::::::::::::::",itinararyList);
  }

  const updateDescAr = (data) => {
      for(let val of itinararyList){
        if(Number(val.day)===Number(data[1])){
           val.desc_ar=data[0];
         }
       }
       console.log("List:::::::::::::::",itinararyList);
    }

  const validateItinarary = () => {
    setImgFile(false)
    setIsDuration(false)
    if(state.channel_type===""){
      setNotiMessageShow(true);
      setNotiMessage('Select Channel Type');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(state.itinerary_name_en===""){
      setNotiMessageShow(true);
      setNotiMessage('Itinarary Name is required');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(state.url===""){
      setNotiMessageShow(true);
      setNotiMessage('Please upload image');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(state.days===0 || state.days==="0" || state.days.includes("-")){
      setNotiMessageShow(true);
      setNotiMessage('Please enter proper No of Days');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(state.days===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Enter No of Days');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(state.days!==0 && state.days>10){
      setNotiMessageShow(true);
      setNotiMessage('No of Days should be minimum 1 to maximum 10');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }
    if(itinararyList.length!==0){
     for(let val of itinararyList){
       if(val.title_en===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter Title for day'+val.day);
        setNotiVarient('danger')
        hidemessage();
        return false;
       }else if(val.desc_en==="" || (val.desc_en!=="" && val.desc_en==="<p><br></p>")){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter Desription for'+val.day);
        setNotiVarient('danger')
        hidemessage();
        return false;
       }
     }
    }

    return true;
  }

  const updateChanel=(obj)=>{
    dispatch({ type: 'channel_type', payload: obj[0].value })
    dispatch({ type: 'selChannel', payload: obj })
  }

  function hidemessage() {
    setTimeout(function () {
          setNotiMessageShow(false);
          setNotiMessage('');
          setNotiVarient('')
    }, 3000);
  }

  const handleItinararyCreation = (event) => {
    event.preventDefault();
    if (validateItinarary()) {
      state.active = true;
      state.created_by = 1;
      state.itinerary_list=itinararyList;
      saveItinarary(state).then(response => {
        console.log("Itinarary Save response " + JSON.stringify(response.data));
        if (response.data.suc) {
          window.scrollTo(0, 0);
          setNotiMessageShow(true);
          setNotiMessage('Itinarary details are saved successfully...');
          setNotiVarient('success')
          setTimeout(function () {
            window.location="/cms/create-itinarary";
          }, 2000);

        } else {
          setNotiMessageShow(true);
          setNotiMessage('Itinarary details not saved');
          setNotiVarient('danger')
        }
      });
    }
  }

  const handleItinararyUpdatation = (event) => {
    event.preventDefault();
    if (validateItinarary()){
      state.itinerary_list=itinararyList;
      state.updated_by = 1;
      updateItinarary(state).then(response => {
        console.log("Itinarary Save response " + JSON.stringify(response.data));
        if (response.data.suc) {
          window.scrollTo(0, 0);
          setNotiMessageShow(true);
          setNotiMessage('Itinarary details are updated successfully...');
          setNotiVarient('success')
          setTimeout(function () {
            window.location="/cms/create-itinarary";
          }, 2000);
        } else {
          setNotiMessageShow(true);
          setNotiMessage('Itinarary details are not saved');
          setNotiVarient('danger')
          hidemessage();
        }
      });
    }
  }

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
  };

  return (
    <>
        <Alert className="notification" variant={notiVarient} show={notiMessageShow}
          onClose={() => setNotiMessageShow(false)}
          dismissible> {notiMessage}</Alert>
        <Row>
          <Col xs={6} md={2} className="sideBar">
            <SidebarMenu active="Itinararies" />
          </Col>
          <Col xs={12} md={10} className="innerBgcolor">

            <Row>

              <div className="tableForm">

                <Col className="d-flex justify-content-between align-center mt-4 mb-4 pl-0 spaceBet">
                  <h6 className="m-0">Itinarary</h6>
                </Col>
                <div className="tableForminner">
                  <Form noValidate validated={validated} id="formId">

                    <Row>
                    <Col xs={3}>

                      <Form.Label>Channel Type : <sup>*</sup></Form.Label>
                      <Form.Group
                      controlId="ctyp"
                      >
                      <Select
                        disabled={enableUpdate}
                        options={chanelTypes}
                        searchable={false}
                        values={state.selChannel}
                        onChange={(value) => updateChanel(value)}
                      />
                    </Form.Group>

                    </Col>
                    </Row>

                    <Row>
                      <Col xs={3}>
                        <Form.Label>Itinarary Name in English<sup>*</sup></Form.Label>
                        <Form.Group controlId="itinerary_name_en">
                          <Form.Control
                            required
                            type="text"
                            maxLength="30"
                            autoComplete="off"
                            placeholder="Enter Itinarary Name in English"
                            value={state.itinerary_name_en}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={3}>
                        <Form.Label>Itinarary Name in Arabic</Form.Label>
                        <Form.Group controlId="itinerary_name_ar">
                          <Form.Control
                            required
                            type="text"
                            maxLength="30"
                            placeholder="Enter Itinarary Name in Arabic"
                            value={state.itinerary_name_ar}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                          <FileUpload reportUrls={state.url} sendFileUrl={updateFileName} />
                          {imgfile && (
                            <p className="vError">Please upload the Image</p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                    <Col xs={3}>
                        <Form.Group controlId="days">
                          <Form.Label>No of Days <sup>*</sup></Form.Label>
                          <Form.Control
                             required
                             type="number"
                             maxLength="10"
                             min={1}
                             disabled={enableUpdate}
                             className="amountField"
                             value={state.days}
                             onChange={handleChange}
                          />
                          {daysSize && (
                            <p className="vError">Days should be maximum 10 and Not allow - values</p>
                          )}
                        </Form.Group>
                        </Col>
                    </Row>
                    {itinararyList.length!==0 && itinararyList.map((itinary, idx) => (
                      <>
                      <span>Day <b>{idx+1}</b></span>
                      <Row>
                      <Col xs={3}>
                        <Form.Label>Title in English<sup>*</sup></Form.Label>
                        <Form.Group controlId="title_en">
                          <Form.Control
                            required
                            type="text"
                            maxLength="30"
                            autoComplete="off"
                            placeholder="Enter Title in English"
                            value={itinary.title_en}
                            onChange={handleChangeIti(idx)}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={3}>
                        <Form.Label>Title in Arabic</Form.Label>
                        <Form.Group controlId="title_ar">
                          <Form.Control
                            required
                            type="text"
                            maxLength="30"
                            placeholder="Enter Title in Arabic"
                            value={itinary.title_ar}
                            onChange={handleChangeIti(idx)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Col} xs={16} controlId="desc_en">
                          <Form.Label>Description English<sup>*</sup></Form.Label>
                          <TextEditor textType={"Desc_EN"} sendDescEn={updateDescEn} enableUpdate={enableUpdate} updateDescEn={itinary.desc_en} index={idx+1}/>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className="mb-3" xs={16} controlId="desc_ar">
                          <Form.Label>Description Arabic</Form.Label>
                          <TextEditor textType={"Desc_AR"} sendDescAr={updateDescAr} enableUpdate={enableUpdate} updateDescAr={itinary.desc_ar} index={idx+1}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    </>

                    ))}
                    <Row className="submitBtn">
                      <Col className="d-flex">
                        {!enableUpdate && <Button  className="saveBttn" type="submit" variant="primary" size="sm" onClick={handleItinararyCreation}><span>Save</span></Button>
                        }
                        {enableUpdate && <Button type="submit" variant="primary" size="sm" onClick={handleItinararyUpdatation}>Update</Button>}
                      </Col>
                    </Row>
                  </Form>
                </div>

              </div>
              <div className='tableForm mt-3'>
              <div className="bodyContent">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl taFeeq itinararyT"
                  data={res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={res.length >10 && res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  res.length >25 && res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                {res.length === 0 &&
                 <div className="norecord">
                 <strong>No Record Found.</strong>
                 </div>
                }
            </div>

              </div>


            </Row>
          </Col>
        </Row>
    </>
  )
}
export default Itinarary