import axios from "axios";

const B2B_API_URL = process.env.REACT_APP_B2B_URL;
const B2C_API_URL = process.env.REACT_APP_B2C_URL;
const B2B_PREDECTIVE_CITIES_API_URL = process.env.REACT_APP_B2B_PREDECTIVE_CITIES_URL;
const B2C_PREDECTIVE_HOTELS_API_URL = process.env.REACT_APP_PREDICTIVE_URL;

export const USER_NAME_SESSION_ATTRIBUTE_NAME = "authenticatedUser";

/**
 * @author: Lakshmi
 * @description: Integrated the Login and Roles service
 * @param {*} Service
 */
class PackageService {
  //Packages
  async loadPackages() {
    const obj = {
      type: "ALL",
    };
    return await axios.post(`${B2B_API_URL}/att/cms/retrievepkg`, obj);
  }

  async savePackage(data) {
    return await axios.post(`${B2B_API_URL}/att/cms/savepkg`, data);
  }

  async updatePackage(data) {
    return await axios.post(`${B2B_API_URL}/att/cms/updatepkg`, data);
  }

  async editPackage(data) {
    return await axios.post(`${B2B_API_URL}/att/cms/editpkg`, data);
  }

  async updatePackageStatus(data) {
    return await axios.post(`${B2B_API_URL}/cms/update_package_status`, data);
  }

  //Itinararies
  async loadItinararies(obj) {
    return await axios.post(`${B2B_API_URL}/att/cms/retrieveiti`, obj);
  }

  async saveItinarary(data) {
    return await axios.post(`${B2B_API_URL}/att/cms/saveiti`, data);
  }

  async updateItinarary(data) {
    return await axios.post(`${B2B_API_URL}/att/cms/updateiti`, data);
  }

  //Location
  async getLocationList(obj) {
    return await axios.post(`${B2B_API_URL}/att/cms/retrieveloc`, obj);
  }

  async saveLoc(data) {
    return await axios.post(`${B2B_API_URL}/att/cms/saveloc`, data);
  }

  async updateLoc(data) {
    return await axios.post(`${B2B_API_URL}/att/cms/updateloc`, data);
  }

  //Events
  async getEvenlist(obj) {
    return await axios.post(`${B2B_API_URL}/att/cms/retrieveevents`, obj);
  }

  async saveEventData(data) {
    return await axios.post(`${B2B_API_URL}/att/cms/saveevent`, data);
  }

  async updateEventData(data) {
    return await axios.post(`${B2B_API_URL}/att/cms/updateevents`, data);
  }

  //Recommendations
  async loadRecommendations(data) {
    if (data.type && data.type.toUpperCase() === "B2C") {
      return await axios.post(`${B2B_API_URL}/att/cms/fetchCnt`, data);
    } else {
      //   return await axios.post(`${B2B_API_URL}/att/cms/fetchCnt`, data);
      return await axios.post(`${B2C_API_URL}/cnt/cms/fetchCnt`, data);
    }
  }

  async saveRecommendations(data) {
    if (data.channel && data.channel.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/l/cms/saveCnt`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/saveCnt`, data);
    }
  }

  async updateRecommendations(data) {
    if (data.channel_type && data.channel_type.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/updateCnt`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/updateCnt`, data);
    }
  }

  //Top Routes
  async loadTopRoutes(data) {
    if (data.type && data.type.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/fetchMfr`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/fetchMfr`, data);
    }
  }

  async saveTopRoutes(data) {
    if (data.channel && data.channel.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/saveMfr`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/saveMfr`, data);
    }
  }

  async updateTopRoutes(data) {
    if (data.channel_type && data.channel_type.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/updateMfr`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/updateMfr`, data);
    }
  }

  //Top Destinations
  async loadTopDestinations(data) {
    if (data.type && data.type.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/fetchLoveit`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/fetchLoveit`, data);
    }
  }

  async saveTopDestinations(data) {
    if (data.channel && data.channel.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/saveLoveit`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/saveLoveit`, data);
    }
  }

  async updateTopDestinations(data) {
    if (data.channel_type && data.channel_type.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/updateLoveit`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/updateLoveit`, data);
    }
  }

  //Popular Hotels
  async loadPopularHotels(data) {
    if (data.type && data.type.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/fetchhtl`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/fetchhtl`, data);
    }
  }

  async savePopularHotels(data) {
    if (data.channel && data.channel.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/savehtl`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/savehtl`, data);
    }
  }

  async updatePopularHotels(data) {
    if (data.channel_type && data.channel_type.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/updatehtl`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/updatehtl`, data);
    }
  }

  //High In Demand(packages)
  async loadHighInDemand(data) {
    if (data.type && data.type.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/fetchpkges`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/fetchpkges`, data);
    }
  }

  async saveHighInDemand(data) {
    if (data.channel && data.channel.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/savepkges`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/savepkges`, data);
    }
  }

  async updateHighInDemand(data) {
    if (data.channel_type && data.channel_type.toUpperCase() === "B2B") {
      return await axios.post(`${B2B_API_URL}/att/cms/updatepkges`, data);
    } else {
      return await axios.post(`${B2C_API_URL}/cnt/cms/updatepkges`, data);
    }
  }
  
  //For retrieving Cities based on inputs
  async retreieveSearch(data) {
    const obj = {
      searchCriteria: data,
    };
    console.log(obj);
    return await axios.post(`${B2C_API_URL}/cnt/common/airports`, obj);
  }

  //For retrieving Cities based on inputs
  async retriveRecentSearch(id) {
    return await axios.get(`${B2B_PREDECTIVE_CITIES_API_URL}/common/fetchPredictive/${id}`);
  }
  
  //For retrieving Hotels based on inputs
  async retreieveHotelsSearch(data) {
    return await axios.get(
      `${B2C_PREDECTIVE_HOTELS_API_URL}/search/` +
        data +
        "?skip=0&limitCity=10&limitProperty=50"
    );
  }

  //For retrieving Hotels based on inputs
  async retriveRecentHotelsSearch(id) {
    return await axios.get(`${B2C_PREDECTIVE_HOTELS_API_URL}/common/fetchPredictive/${id}`);
  }

}

export default new PackageService();
