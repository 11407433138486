import React, { Component } from 'react';
import { Nav } from "react-bootstrap";
import { Link } from 'react-router-dom';

const SidebarMenuFun = (props) =>{
  const { state } = props || {}
    return (
        <>
            <Nav defaultActiveKey="/cms/package">
                <Nav.Item className={state.active==="packages" ? 'active':''}>
                    <Link to="/cms/package" title="Packages">Packages<span className="zyIcon icon-arrowDown"></span></Link>
                </Nav.Item>
                <Nav.Item className={state.active==="Itinararies" ? 'active':''}>
                    <Link to="/cms/create-itinarary" title="Itinararies" >Tawfeeq Travel Itinararies<span className="zyIcon icon-arrowDown"></span></Link>
                </Nav.Item>
                <Nav.Item className={state.active==="CNT Recommendations" ? 'active':''}>
                    <Link to="/cms/cnt-recommendations" title="CNT Recommendations" >CNT Recommendations<span className="zyIcon icon-arrowDown"></span></Link>
                </Nav.Item>
                <Nav.Item className={state.active==="Top Routes" ? 'active':''}>
                    <Link to="/cms/top-routes" title="Top Routes">People Flying Here<span className="zyIcon icon-arrowDown"></span></Link>
                </Nav.Item>
                <Nav.Item className={state.active==="High In Demand" ? 'active':''}>
                    <Link to="/cms/high-in-demand" title="High In Demand">High In Demand<span className="zyIcon icon-arrowDown"></span></Link>
                </Nav.Item>
                <Nav.Item className={state.active==="Top Destinations" ? 'active':''}>
                    <Link to="/cms/top-destinations" title="Top Destinations" >You will love it<span className="zyIcon icon-arrowDown"></span></Link>
                </Nav.Item>
                <Nav.Item  className={state.active==="Popular Hotels" ? 'active':''}>
                    <Link to="/cms/popular-hotels" title="Popular Hotels">Most Popular Hotels<span className="zyIcon icon-arrowDown"></span></Link>
                </Nav.Item>
            </Nav>
        </>
    )
}

class SidebarMenu extends Component {
    constructor(props) {
    super(props);
        console.log("propspropsprops",props)
        this.state = {
            active:props.active
        };
    }

    render() {
    return <SidebarMenuFun state={this.state} />
  }
  }

  export default SidebarMenu;