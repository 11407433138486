import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col,Modal,Alert,Button,Navbar,FormControl,NavDropdown,Nav } from "react-bootstrap";
export const USER_AUTH_DATA = "userAuthData";

/**
 * @description:This function will work for Payment Failed pop Up.
 * @author: Satya
 * @param {*}
 * @function NoRecord
 * @date : 01-09-2020
 */
function Header(props) {
  const { state } = props || {}
  const [data,setData] = useState(JSON.parse(localStorage.getItem(USER_AUTH_DATA)))

  const logout=()=>{
    localStorage.removeItem(USER_AUTH_DATA);
    window.location="/cms/login";
  }

  return (
      <>




    <Container fluid >
    <Row>
    <Col className="d-flex justify-content-between align-center topbar">
    <h5 className="m-0">Content Management System</h5>


    <Navbar expand="lg">
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="ml-auto ezyIcon icon-customer-service">
      {data!==null &&
        <NavDropdown title={"Welcome "+data.nm} id="basic-nav-dropdown">
         <NavDropdown.Item onClick={logout} >Logout</NavDropdown.Item>
         </NavDropdown>
      }

    </Nav>

  </Navbar.Collapse>
</Navbar>
    </Col>
    </Row>
  </Container>
  </>
  )
}
export default Header