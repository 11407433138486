import React,{ useState, useEffect } from 'react';
import axios from "axios";
import { Form, Row, Col, Modal, Alert, Button } from "react-bootstrap";
import { useFileUpload } from 'use-file-upload'

const API_URL_COMMON = process.env.REACT_APP_B2B_SEARCH

const ImageUpload = (props) => {
  const [files, selectFiles] = useFileUpload()
  const [urls,setUrls] = useState({urlsList:[]})
  const [enableUrl,setEnableUrl] = useState(false)
  const [showPopup, setShowPopup] = useState(false);
  const [file, setFile] = useState();
  const [isLoadingMore, setIsLoadingMore,] = useState(false);
  const [notiMessage, setNotiMessage] = useState('');
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [isLoading, setIsLoading,] = useState(false);
  const [pleasewait, setPleasewait,] = useState(false);
  const [single, setSingle,] = useState(true);
  const [selFile, selectFile] = useFileUpload()

  let name;
  useEffect(() => {
    if(props.reportUrls!=="" && props.reportUrls!==null && props.reportUrls!==undefined){
      let url = props.reportUrls.split("/");
      name = url[url.length - 1];
      console.log("uploaded image name >", name)
      const payload={
        "name":name,
        "url":props.reportUrls
      }
      {urls.urlsList.length==0 &&
        urls.urlsList.push(payload);
        setUrls({urlsList:urls.urlsList});
        setEnableUrl(true);
      }
    }else{
      setUrls({urlsList:[]});
    }
  }, [props.reportUrls]);

  const showLogoPopup = (obj) =>()=> {
    setShowPopup(true);
    setFile(obj);
   }

   //Call Delete Images
  const deleteFile=()=>{
    setIsLoadingMore(true);
    setIsLoading(true);
    setPleasewait(true);
    const url = API_URL_COMMON+"/file/delete";
    const req={"url":file.url,"type":'cms'}
    axios.post(url, req).then((response) => {
       if (response.data.suc){
        const finalUrls = urls.urlsList.filter((item) => item.name !== file.name);
        setUrls({urlsList:finalUrls});
        setShowPopup(false);
        setIsLoadingMore(false);
        setIsLoading(false);
        setPleasewait(false);
        props.sendFileUrl(props.index, "");
        setNotiMessageShow(true);
        setNotiMessage('Image deleted successfully..');
        setNotiVarient('success');
        hidemessage();
       }else{
        setShowPopup(false);
        setIsLoadingMore(false);
        setNotiMessageShow(true);
        setIsLoading(false);
        setPleasewait(false);
        setNotiMessage('Image deletion failed');
        setNotiVarient('danger');
        window.scrollTo(0,0);
        hidemessage();
       }
   });
  }

  //Validating the Image
  const validateImage=(fileObj)=>{
    if(fileObj){
      const size = Math.round((fileObj.size / 1024));
      if (size >= 1024) {
        setNotiMessageShow(true);
        setNotiMessage('Image size should not exceed 1MB');
        setNotiVarient('danger');
        hidemessage();
        return false;
      }
      const allowedTypes = ['image/jpeg', 'image/png'];
      if (!allowedTypes.includes(fileObj.type)) {
        setNotiMessageShow(true);
        setNotiMessage('Please upload a valid JPG or PNG image with dimensions 350x480.');
        setNotiVarient('danger');
        hidemessage();
        return false;
      }
    }
    return true;
  }

  //Call ther to upload selected file or Image
  const UploadImage=()=>{
    setIsLoadingMore(true);
    if(single && urls.urlsList.length===0 ){
      setUrls({urlsList:[]});
      selectFile({}, ({ source, name, size, file, type }) => { //Single file - is the raw File Object
        console.log("SDebug Upload file >", { source, name, size, file })
        if(validateImage(file)){
        const url = API_URL_COMMON+"/file/upload";
        console.log("file upload url : "+url)
        const formData = new FormData();
        formData.append('image',file)
        formData.append('type',"cms")
        axios.post(url, formData).then((response) => {
          console.log("file upload response : "+JSON.stringify(response))

          const img = new Image();
          img.src = response.data.res.url;

          img.onload = () => {
            // validating image dimensions
            if(response.data.suc && response.data.res.length!==0){
              if(img.width === 350 && img.height === 480) {
                const payload={
                  "name":name,
                  "url":response.data.res.url
                }
                urls.urlsList.push(payload);
                setUrls({urlsList:urls.urlsList});
                setEnableUrl(true);
                props.sendFileUrl(props.index, response.data.res.url);
                setIsLoadingMore(false);
                setNotiMessageShow(true);
                setNotiMessage('Image uploaded successfully..');
                setNotiVarient('success');
                hidemessage();
              } else {               
                setNotiMessageShow(true);
                setNotiMessage('Please upload a image with dimensions 350x480.');
                setNotiVarient('danger');
                hidemessage();
                return false;
              }            
           }
        }
       });
      }
      })
    }else{
      setIsLoadingMore(false);
      setNotiMessageShow(true);
      setNotiMessage('Already Image is uploaded');
      setNotiVarient('danger');
      hidemessage();
    }
    // Multiple File Upload
    {!single &&
    selectFiles({ multiple: false }, (files) => {
        files.map(({ source, name, size, file }) =>{
          const url = "";
          const formData = new FormData();
          formData.append('image',file);
          formData.append('type','companyimage');
          axios.post(url, formData).then((response) => {
             if (response.data.suc && response.data.res.length!==0){
              const payload={
                "name":name,
                "url":response.data.res.url
              }
              urls.urlsList.push(payload);
              setUrls({urlsList:urls.urlsList});
              setEnableUrl(true);
              setIsLoadingMore(false);
              setNotiMessageShow(true);
              setNotiMessage('Image uploaded successfully..');
              setNotiVarient('success');
              hidemessage();
             }
         });
      })
    })
  }
  }

  const hidemessage=() =>{
    setTimeout(function () {
        setNotiMessageShow(false);
        setNotiMessage('');
        setNotiVarient('');
    }, 4000);
  }

  return (
    <div className="upload-report">
      {showPopup &&
    <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        backdrop="static"
        size="small"
        className="custom-dailog alignDelc"
        keyboard={false}>
                 <Modal.Body>
                <div className="">
                    <div>
                    <Form.Label className="custom-dailog-title">Are you sure you want to delete selected Image ?</Form.Label>
                    </div>
                    <div className="text-center buttonGrop">
                        <Button
                        size="xs"
                        variant="outline-primary"
                        onClick={deleteFile}
                        disabled = {isLoading && pleasewait ? true : false } >
                          Yes
                        </Button>
                        <Button
                            size="xs"
                            variant="outline-dark"
                            onClick={() => setShowPopup(false)}
                            type="submit"> No </Button>
                    </div>
                </div>
             </Modal.Body>
    </Modal>
}
    <div className="uploadButton">
      <Row>
        <Col>
          <span className="addlogo ezyIcon icon-attach-ico"
          onClick={UploadImage}
          > &nbsp;{urls.urlsList.length > 0 ? urls.urlsList[0].name : "Browse"}
          </span>
        </Col>
      </Row>

      </div>
      {/* {isLoadingMore ?  <InnerLoader/>:""} */}
      <div className="addedlayout">
      <Alert className="notification" variant={notiVarient} show={notiMessageShow}
       onClose={() => setNotiMessageShow(false)}
       dismissible> {notiMessage}</Alert>
      {urls.urlsList!==undefined && urls.urlsList.length!==0  && enableUrl ? (
        urls.urlsList.map((file,idx) => (
          <React.Fragment key={idx}>
          <div className="d-flex flex-column mt-1 mb-2">
           {/* <a className="d-flex mr-3" href={file.url} target='_blank'>{file.name} </a> */}
           <div className="imgUplo">
                  <img
                    src={file.url}
                />

        {/* <button onClick={showLogoPopup(file)}>x</button> */}
            <span
              className="iconBtn add ezyIcon icon-close"
              onClick={showLogoPopup(file)}
              >
            </span>

          </div></div>
          </React.Fragment>
        ))
      ) : (
        <span className="d-flex mt-1 mb-2">No file selected </span>
      )}
      </div >
      <p  className='jpgPNg'> JPG or PNG (Dimensions: 350x480)
      </p>

    </div>
  )

}

export default ImageUpload