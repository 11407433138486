import PackageService from '../../services/PackageService';
/**
 * @description: CRUD operations for Branch
 * @author: Ubedullah
 * @param {*}
 * @function CNT HighInDemand
 * @date : 23-05-2023
 */

//Loading HighInDemand data
export const getHighInDemand = async (obj) => {
    let list = [];
    await PackageService.loadHighInDemand(obj).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}


//Save HighInDemand 
export const saveHighInDemand = async (data) => {
    let list = [];
    await PackageService.saveHighInDemand(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Update HighInDemand 
export const updateHighInDemand = async (data) => {
    let list = [];
    await PackageService.updateHighInDemand(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}




