

import React, {useRef, useState } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import log from "loglevel";
import {useHistory } from 'react-router-dom';
import AuthenticationService from '../../services/AuthenticationService';
import { emailValidation} from '../common/FormValidations';
export const USER_AUTH_DATA = "userAuthData";
export const USER_NAME = "userName";

 function LoginForm(props) {
    const history = useHistory();
    const [state, setState] = useState({ eid: "", pwd: "", is_admin: true })
    const [isLoad, setIsLoad] = useState(false);
    const [showUserName, setShowUserName] = useState(false);
    const [showUserPw, setShowUserPw] = useState(false)
    const [error, setError] = useState(false);
    const [notiMessage, setNotiMessage] = useState('');
    const [notiMessageShow, setNotiMessageShow] = useState(false);
    const [notiVarient, setNotiVarient] = useState();
    const [passwordShown, setPasswordShown] = useState(false);
    const focusRef = useRef();

    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const handleUserLogin = (e) => {
        e.preventDefault();
        setIsLoad(true);
        setShowUserName(false);
        setShowUserPw(false);
        console.log('i m in faileddddd user', state.eid)
        if (state.eid !== "" && state.pwd !== "") {
            AuthenticationService.userLogin(state).then(response => {
                console.log("Login test : " + JSON.stringify(response.data));
                if (response.data.suc) {
                    if (response.data.res.roles && response.data.res.roles !== undefined && response.data.res.company_active !== 0) {
                        response.data.res.butype = response.data.res.utype;
                        if(response.data.res.utype==="att_user"){
                            console.log("Login SUC : " + JSON.stringify(response.data));
                            localStorage.setItem(USER_AUTH_DATA, JSON.stringify(response.data.res));
                            history.push("/cms/package");
                        }else{
                        setIsLoad(false);
                        setNotiMessageShow(true);
                        setNotiMessage('Access denied.Please check with administrator');
                        setNotiVarient('danger')
                        }
                    } else {
                        setIsLoad(false);
                        setNotiMessageShow(true);
                        setNotiMessage('Access denied.Please check with administrator');
                        setNotiVarient('danger')
                        history.push("/cms/login");
                        //history.push("/ezytrip/dashboard");
                        console.log('i m in faileddddd user', state.eid)
                    }
                }
                else {
                    setError(true);
                    setIsLoad(false);
                    setShowUserName(false);
                    setShowUserPw(false);
                }
            }).catch(function (error) {
                log.error(
                    "Exception occured in userLogin  function---" + error
                );
            });
            //}
        } else {
            setIsLoad(false);
            if (state.eid === "") {
                setShowUserName(true);
            }
            if (state.pwd === "") {
                setShowUserPw(true);
            }
        }

    }

    return (
        <div className="loginLayout">
        <div className="error">
        <Alert className="notification" variant={notiVarient} show={notiMessageShow}
            onClose={() => setNotiMessageShow(false)}
            dismissible> {notiMessage}</Alert>
       </div>
        <div className="container">
        <div className="loginForm">
            <h5>Login</h5>
            <form onSubmit={e => handleUserLogin(e)} ref={focusRef}>

    <Form.Row>

        <Form.Group as={Col} xs={12} controlId="eid">
        <Form.Label>Email/Username<sup>*</sup></Form.Label>
            <Form.Control type="text" className="username" placeholder="Enter User name" name="eid" autoComplete="off" value={state.eid} onChange={handleChange} autoFocus />
                {showUserName && (
                <p className="vError">Enter User name</p>
                )}

        </Form.Group>
        <Form.Group as={Col} xs={12} controlId="pwd" className="password">
        <Form.Label>Password<sup>*</sup></Form.Label>
            <Form.Control type={passwordShown ? "text" : "password"} className="password" placeholder="Enter Password" name="pwd" autoComplete="off" value={state.pwd} onChange={handleChange} />

            <span onClick={togglePasswordVisiblity} className={"ezyIcon icon-vieww " + (passwordShown ? "show" : "hide")}></span>
                {showUserPw && (
                <p className="vError">Enter Password</p>
                )}
        </Form.Group>

    </Form.Row>
    {error && <Form.Text className="loginError">
        User Name and Password Incorrect
    </Form.Text>}
    <div className="text-right submitButton">
        <Button type="submit" variant="primary" className={(isLoad ? 'progress-bar-striped progress-bar-animated' : '')} size="lg" block onClick={handleUserLogin}>
            {(isLoad ? 'Logging In...' : 'Login')}
        </Button>
    </div>
</form>
        </div>
        </div>
        </div>
    )

}

export default LoginForm
