import React, { useState, useEffect,useReducer } from 'react';
import { Container, Form, Row, Col, Modal, Alert, Button, Table, Nav } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {getAllPackages,updatePackageStatus} from '../package/operation';
import HeaderComp from '../common/header';
import FooterComp from '../common/Footer';
import DateUtils from '../common/DateUtils'
import { useHistory } from 'react-router-dom';
import { FaEdit } from "react-icons/fa"
import SidebarMenu from '../common/sidebar';

/**
 * Initial State Declaration
 */
 const initialState = {
  res: [],notiMessage: '', notiMessageShow: false, notiVarient: ''
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, categoryName: ''
      };
    case 'editCompany':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};
/**
 * @description:This Package listing
 * @author: Satya
 * @param {*}
 * @function NoRecord
 * @date : 14-10-2021
 */
function PackageCreate(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [update,setUpdate] = useState(true)
  const history = useHistory();

  useEffect(() => {
    getAllPackages().then(response => {
      console.log('Package response', response)
      if (response.data.suc &&  response.data.res) {
        convertPackageData(response.data.res)
      }
    }).catch(function (error) {
      console.log('Package response error ', error)
    });
  }, [])

  const handleChangeActive = (obj,resp) => (e) => {
   let isActive=false;
   if(obj.active==="false"){
    isActive=true;
   }
    updatePackageStatus({"_id":obj._id,"active":isActive}).then(response => {
        if(response.data.suc){
        setUpdate(false)
        dispatch({ type: 'notiMessageShow', payload: true })
        dispatch({ type: 'notiMessage', payload: 'Package Status updated Successfully...' })
        dispatch({ type: 'notiVarient', payload: 'success' })
        for(let val of resp){
          if(val._id===obj._id){
             val.active=isActive?"true":"false";
             break;
          }
        }
        convertPackageData(resp)
        setUpdate(true)
        setTimeout(function () {
          dispatch({ type: 'notiMessageShow', payload: false })
          dispatch({ type: 'notiMessage', payload: '' })
          dispatch({ type: 'notiVarient', payload: '' })
        }, 2000);
        }
    })
}

  const convertPackageData=(response)=>{
    let data = [];
    let index=1;
     for(let value of response){
       var type="";
       var chaType="";
       if(value.package_name_en!==undefined){
       if(value.is_trending){
        type="Trending";
       }
       if(value.is_staynearby){
         if(type===""){
          type="Stay near by";
         }else{
          type=type+"- Stay near by";
         }
       }
       if(value.is_topdestination){
        if(type===""){
         type="Top Destination";
        }else{
         type=type+"- Top Destination";
        }
      }
      if(value.cha_b2b && value.cha_b2c){
        chaType="B2B - B2C";
      }else if(value.cha_b2b){
        chaType="B2B";
      }else if(value.cha_b2c){
        chaType="B2C";
      }
      data.push({ pname: value.package_name_en, type : type,cha_type:chaType, price: value.amt, fromDate: DateUtils.convertToDateFormatewithoutTime(value.from_date), toDate: DateUtils.convertToDateFormatewithoutTime(value.to_date),
      options: <><span className="tbAction"><a className="ezyIcon icon-vieww" onClick={()=>editPackage(value)}><FaEdit/></a></span>
      <div className='custom-switch'>
      <Form.Group className="d-flex align-items-center m-0" controlId="is_active">
         <Form.Check  className="p-2"
          checked={value.active==="true"?true:false}
         type="checkbox"
         label=""
         value={value.active==="true"?true:false}
         onChange={handleChangeActive(value,response)}
         id={index+"pkg"+value.id}
         custom
          />
         </Form.Group>
         </div> </> })
         index=index+1;
      }
     }
     dispatch({ type: 'res', payload: data })
  }

  const editPackage=(obj)=>{
    history.push({
      pathname: '/cms/create-package',
      state: {
        req: obj
      }
    });
  }

  const columns = [{
    dataField: 'pname',
    text: 'Package Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'type',
    text: 'Marketing Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'cha_type',
    text: 'Channel Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'price',
    text: 'Package Price [QAR]',
    sort: true,
    filter: textFilter()
  },
  {
    sort: true,
    filter: textFilter(),
    dataField: 'fromDate',
    text: 'Validity From',
  },
  {
    sort: true,
    filter: textFilter(),
    dataField: 'toDate',
    text: 'Validity To',
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];



  return (
    <>
     {state.notiMessageShow &&
        <Alert className="notification" variant={state.notiVarient} show={state.notiMessageShow}
          onClose={() => dispatch({ type: "notiMessageShow", payload: false })}
          dismissible> {state.notiMessage}</Alert>
      }

      <HeaderComp />
      <Container fluid>
        <Row>
          <Col xs={6} md={2} className="sideBar">
            <SidebarMenu active="packages" />
          </Col>
          <Col xs={12} md={10}>
            <Row>
              <Col className="d-flex justify-content-between align-center mt-4 mb-4">
                <h6 className="m-0">Packages</h6>
                <a href="/cms/create-package"><Button variant="primary">Create</Button></a>
              </Col>
            </Row>

            <div className="bodyContent">
              {update &&
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbl"
                  data={state.res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={state.res.length >10 && state.res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  state.res.length >25 && state.res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  state.res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
              }

                {state.res.length === 0 &&
                 <div className="norecord">
                 <strong>No Record Found.</strong>
                 </div>
                }
            </div>

            <FooterComp />
          </Col>

        </Row>

      </Container>




    </>
  )
}
export default PackageCreate