import PackageService from '../../services/PackageService';
/**
 * @description: CRUD operations for Branch
 * @author: Lakshmi
 * @param {*}
 * @function Package
 * @date : 13-08-2020
 */

//Loading Package data
export const getItinararies = async (obj) => {
    let list = [];
    await PackageService.loadItinararies(obj).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}


//Save Package 
export const saveItinarary = async (data) => {
    let list = [];
    await PackageService.saveItinarary(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Save Package 
export const updateItinarary = async (data) => {
    let list = [];
    await PackageService.updateItinarary(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}


//Loading Package data
export const getLocations = async (obj) => {
    let list = [];
    await PackageService.getLocationList(obj).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}


//Save Package 
export const saveLocation = async (data) => {
    let list = [];
    await PackageService.saveLoc(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Save Package 
export const updateLocation = async (data) => {
    let list = [];
    await PackageService.updateLoc(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Loading Package data
export const getEvents = async (obj) => {
    let list = [];
    await PackageService.getEvenlist(obj).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Save Package 
export const saveEvent = async (data) => {
    let list = [];
    await PackageService.saveEventData(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Save Package 
export const updateEvent = async (data) => {
    let list = [];
    await PackageService.updateEventData(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}




