import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
export const USER_AUTH_DATA = "userAuthData";

/* Currently we are checking user is locked in or not from store */
const ProtectedRoute = ({ component: Component, path,login, ...rest }) => {
 const [valid, setValid] = useState(false);
 const userData = localStorage.getItem(USER_AUTH_DATA);
  console.log(`isValid :::: `, userData?.length)
    return (
      <Route
        path={path}
        {...rest}

        render={props => userData?.length > 0 ? <Component {...props} /> : <Redirect to="/cms/login" />}
      />
    );
  }
  

export default ProtectedRoute;