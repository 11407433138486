import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { Container, Form, Row, Col, Modal, Alert, Button, Nav, FormControl } from "react-bootstrap";
import HeaderComp from '../common/header';
import FooterComp from '../common/Footer';
import Select from "react-dropdown-select";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getTopRoutes, saveTopRoutes, updateTopRoutes } from './operation';
import { FaEdit } from "react-icons/fa"
import ImageUpload from './ImageUpload';
import SidebarMenu from '../common/sidebar';
import { debounce } from "lodash";
import PackageService from '../../services/PackageService'


/**
 * Initial State Declaration
 */
const initialState = {
    _id: "", channel_type:"", country:"", active: false, selChannel:[], selCountry:[], srcCity: [], selOrigin: [], destCity: [], selDestination: [], 
    srcRowZero: [], srcRowOne: [], srcRowTwo: [], srcRowThree: [], srcRowFour: [], srcRowFive: [], srcRowSix: [], srcRowSeven: [],
    destRowZero: [], destRowOne: [], destRowTwo: [], destRowThree: [], destRowFour: [], destRowFive: [], destRowSix: [], destRowSeven: []}


  // Reducer function for useReact Hook which will update the state
  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
          ...state, id: "", channel_type:"", country:"", active: false, srcCity: [], selOrigin: [], destCity: [], selDestination: []
        };
      case 'editToproutes':
        return Object.assign(state, action.payload);
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

/**
 * @description:TopRoutes Screen
 * @author: Ubedullah
 * @param {*}
 * @function TopRoutes - CMS
 * @date : 05-15-2023
 */

const TopRoutes = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [notiMessage, setNotiMessage] = useState('');
    const [notiMessageShow, setNotiMessageShow] = useState(false);
    const [notiVarient, setNotiVarient] = useState();
    const [validated, setValidated] = useState(false);
    const [enableUpdate, setEnableUpdate] = useState(false);
    const [countryTypes, setCountryTypes] = useState([{value:"India",label:"India"},{value:"Saudi Arabia",label:"Saudi Arabia"},{value:"United Arab Emirates",label:"United Arab Emirates"},{value:"Qatar",label:"Qatar"},{value:"Oman",label:"Oman"}]);
    const [chanelTypes, setChannelTypes] = useState([{value:"B2B",label:"B2B"},{value:"B2C",label:"B2C"}]);
    const [imgfile, setImgFile] = useState(false);
    const [act, setAct] = useState(true);
    const [res, setRes] = useState([]);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [cancelConfirmation, setCancelConfirmation] = useState(false);

    // predective search state variables
    const [sourceClearEnable,setSourceClearEnable] = useState(false);
    const [source, setSource] = useState();
    const [destination, setDestination] = useState();
    const [sourceEnable,setSourceEnable] = useState(false);
    const [srcPredectiveLoading,setSrcPredectiveLoading] = useState(false);
    const [srcLoaded,setSrcLoaded] = useState(false);
    const [srcInfo, setSrcInfo] = useState({});
    const [destPredectiveLoading,setDestPredectiveLoading] = useState(false);

    const [destLoaded,setDestLoaded] = useState(false);
    const [destinationEnable,setDestinationEnable] = useState(false);
    const [destinationClearEnable,setDestinationClearEnable] = useState(false);


    useEffect(() => {
        getTopRoutes({"type":"ALL"}).then(response => {
          console.log('Toproutes response', response)
          if (response.data.suc && response.data.res) {
            convertToproutesData(response.data.res)
          }
        }).catch(function (error) {
          console.log('Toproutes response error ', error)
        });
      }, [])


/**
 * @description:TopRoutes Screen Edit functionality
 * @author: Ubedullah
 * @param {*}
 * @function editToproutes - CMS
 * @date : 05-15-2023
 */

  const editToproutes=(obj)=>{
    dispatch({ type: 'editToproutes', payload: obj })
    dispatch({ type: 'selChannel', payload: [{value:obj.channel,label:obj.channel}] })
    dispatch({ type: 'selCountry', payload: [{value:obj.region,label:obj.region}] })
    setRows(obj.sec_data);
    setAct(obj.status === "Active" ? true : false)
    setEnableUpdate(true)
  }

  /**
 * @description:Toproutes Screen display UI in Table
 * @author: Ubedullah
 * @param {*}
 * @function convertToproutesData - CMS
 * @date : 05-15-2023
 */

  const columns = [{
    dataField: 'country',
    text: 'Country Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'cha_type',
    text: 'Channel Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status'
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];


  const convertToproutesData=(response)=>{
    let data = [];
    let index=1;
     for(let value of response){
      data.push({ country: value.region,cha_type:value.channel, status: value.status,
      options: <><span className="tbAction"><a className="ezyIcon icon-edit" onClick={()=>editToproutes(value)}><span>Edit</span></a></span>
      </> })
         index=index+1;
     }
     setRes(data)
  }

    const updateChanel=(obj)=>{
        dispatch({ type: 'channel_type', payload: obj[0].value })
        dispatch({ type: 'selChannel', payload: obj })
        setUnsavedChanges(true);
    }
    const updateCountry=(obj)=>{
        dispatch({ type: 'country', payload: obj[0].value })
        dispatch({ type: 'selCountry', payload: obj })
        setUnsavedChanges(true);
    }

    const handleChange = (e) => {
      const { id, value } = e.target;
      if (id === 'act') {
          setAct(e.target.checked)
      }
      console.log("checked type:::::", e.target.id);
      setUnsavedChanges(true);
    };


  /**
 * @description:Toproutes Screen Hiding success and Error validation message
 * @author: Ubedullah
 * @param {*}
 * @function hidemessage
 * @date : 05-15-2023
 */

  const hidemessage=() =>{
    setTimeout(function () {
        setNotiMessageShow(false);
        setNotiMessage('');
        setNotiVarient('');
    }, 4000);
  }

  /**
 * @description:Toproutes Screen Validating Form
 * @author: Ubedullah
 * @param {*}
 * @function Validating Form
 * @date : 05-15-2023
 */

  const validateTopRoutes = () => {
    setImgFile(false)
    if(state.country===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Select Country');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(state.channel_type===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Select Channel Type');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(rows.length < 8) {
        setNotiMessageShow(true);
        setNotiMessage('It is mandatory to add Eight top routes');
        setNotiVarient('danger')
        hidemessage();
        return false;
    }
    if(rows.length!==0){
     for(let val of rows){
       if(val.dep_city_en===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter Departure City(English)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }if(val.arr_city_en===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter Arrival City(English)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }if(val.dep_city_ar===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter Departure City(Arabic)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }if(val.arr_city_ar===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter Arrival City(Arabic)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }
       if(val.url_destination===null){
        setNotiMessageShow(true);
        setNotiMessage('Please Upload Destination Image');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }
     }
    }
    return true;
  }

  /**
   * @description:Toproutes Screen Creation(save) data
   * @author: Ubedullah
   * @param {*}
   * @function handleTopRoutesCreation - CMS
   * @date : 05-15-2023
   */

const handleTopRoutesCreation = (event) => {
    event.preventDefault();
    if (validateTopRoutes()) {
      let suc = true;
      for (let val of res) {
        if (val.country?.toLowerCase() == state.country?.toLowerCase()) {
          if (
            val.country?.toLowerCase() == state.country?.toLowerCase() &&
            val.cha_type?.toLowerCase() == state.channel_type?.toLowerCase()
          ) {
            suc = false;
          }
        }
      }
      if(suc) {
        rows.forEach(object => {
            delete object['showDeletePopup'];
            delete object['srcCity'];
            delete object['destCity'];
            delete object['clearSourceField'];
            delete object['clearDestinationField'];
        });
        const payload = {
            "region": state.country,
            "channel": state.channel_type,
            "status": act == true ? "Active" : "In-Active",
            "sec_data": rows,
            "created_by": 1
        }
        saveTopRoutes(payload).then(response => {
          console.log("Top Routes Save response " + JSON.stringify(response.data));
          if (response.data.suc) {
            window.scrollTo(0, 0);
            setNotiMessageShow(true);
            setNotiMessage('People Flying Here are saved successfully...');
            setNotiVarient('success')
            setTimeout(function () {
              window.location="/cms/top-routes";
            }, 2000);

          } else {
            setNotiMessageShow(true);
            setNotiMessage('People Flying Here details not saved');
            setNotiVarient('danger');
            hidemessage();
          }
        });
      } else {
        setNotiMessageShow(true);
        setNotiMessage('Country with saleschannel already exists');
        setNotiVarient('danger');
        hidemessage();
        return false;
      }
      }
    }

    /**
     * @description:Toproutes Screen Updating(update) data
     * @author: Ubedullah
     * @param {*}
     * @function handleTopRoutesUpdation - CMS
     * @date : 05-15-2023
     */

    const handleTopRoutesUpdation = (event) => {
        event.preventDefault();
        if (validateTopRoutes()) {            
            rows.forEach(object => {
              delete object['showDeletePopup'];
              delete object['srcCity'];
              delete object['destCity'];
              delete object['clearSourceField'];
              delete object['clearDestinationField'];
            });
            const payload = {
                "_id": state._id,
                "region": state.country,
                "channel": state.channel_type,
                "status": act == true ? "Active" : "In-Active",
                "sec_data": rows,
                "created_by": 1,
                "updated_by": 1
            }
            updateTopRoutes(payload).then(response => {
            console.log("Toproutes Save response " + JSON.stringify(response.data));
            if (response.data.suc) {
              window.scrollTo(0, 0);
              setNotiMessageShow(true);
              setNotiMessage('People Flying Here are updated successfully...');
              setNotiVarient('success')
              setTimeout(function () {
                window.location="/cms/top-routes";
              }, 2000);
            } else {
              setNotiMessageShow(true);
              setNotiMessage('People Flying Here are not Updated');
              setNotiVarient('danger')
              hidemessage();
            }
          });
        }
    }

    /**
     * @description:Toproutes Screen Image Rows Functionality (ADD)
     * @author: Ubedullah
     * @param {*}
     * @date : 05-15-2023
     */

    const [rows, setRows] = useState([
        { dep_city_en: '', arr_city_en: '', dep_city_ar: '', arr_city_ar: '', url_destination: null, src_info: {}, dest_info: {}, srcCity: [], destCity: [], clearSourceField: false, clearDestinationField: false,
        showDeletePopup: false },
      ]);

      const handleAddRow = () => {
        setRows([...rows, { dep_city_en: '', arr_city_en: '', dep_city_ar: '', arr_city_ar: '', url_destination: null, src_info: {}, dest_info: {}, srcCity: [], destCity: [], clearSourceField: false, clearDestinationField: false,
        showDeletePopup: false }]);
      };

    const handleDeleteImage = (index) => {
      const updatedRowData = [...rows];
      updatedRowData[index].showDeletePopup = true;
      setRows(updatedRowData);
    };

    const handleClosePopup = (index, confirmDelete) => {
      const updatedRowData = [...rows];
      updatedRowData[index].showDeletePopup = false;

      if (confirmDelete) {
        updatedRowData[index].url_destination = null;
      }

      setRows(updatedRowData);
    };

      const handleInputChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
        setUnsavedChanges(true);
      };

      const handleImageUpload = (index, file) => {
        if (file) {
          const image = new Image();
          const reader = new FileReader();
          reader.onload = (e) => {
            image.src = e.target.result;
            image.onload = () => {
              if (image.width === 480 && image.height === 180 && (file.type === 'image/jpeg' || file.type === 'image/png')) {
                const updatedRows = [...rows];
                updatedRows[index].url_destination = URL.createObjectURL(file.split(" ").join(""));
                setRows(updatedRows);
                setUnsavedChanges(true);
              } else {
                // Display an error message indicating invalid image dimensions or format
                setNotiMessageShow(true);
                setNotiMessage('The image dimensions should be 480x180.');
                setNotiVarient('danger')
                hidemessage();
                return false;
              }
            };
          };
          reader.readAsDataURL(file);
        }
      };


      const handleCancel = () => {
        if (unsavedChanges) {
          setCancelConfirmation(true);
        } else {
          if(state.channel_type !== "" || state.country !== "") {
            dispatch({ type: 'channel_type', payload: "" })
            dispatch({ type: 'country', payload: "" })
          }
          setRows([{
                dep_city_en: '',
                arr_city_en: '',
                dep_city_ar: '',
                arr_city_ar: '',
                src_info: {}, 
                dest_info: {},
                url_destination: null
              }]);
            setAct(true)
        }
      };

      const handleCancelConfirmation = (confirmed) => {
        if (confirmed) {
          if(state.channel_type !== "" || state.country !== "") {
            dispatch({ type: 'channel_type', payload: "" })
            dispatch({ type: 'selChannel', payload: [{value:"", label: ""}] })
            dispatch({ type: 'country', payload: "" })
            dispatch({ type: 'selCountry', payload: [{value:"",label:""}] })
          }
          setRows([{
            dep_city_en: '',
            arr_city_en: '',
            dep_city_ar: '',
            arr_city_ar: '',
            src_info: {cityName: ''}, 
            dest_info: {cityName: ''},
            url_destination: null
          }]);
          setAct(true)
          setUnsavedChanges(false);
        }
        setCancelConfirmation(false);
      };

      const updateFileName = (index, url) => {
        const updatedRows = [...rows];
        updatedRows[index].url_destination = url;
        setRows(updatedRows);
        setUnsavedChanges(true);
      }

    // predictive search for city  
    //For handling origin related actions in city search panel
    const handleOrigin = (index, input) => {
      console.log("input--", input);
      dispatch({ type: "selOrigin", payload: [] })
   
      if (input.length > 2) {
          state.selOrigin = [];
          let obj = {
              textValue: input,
              type: 'srcCity',
              index: index
          }
          debouncedSave(obj);
      }
      else {
          state.selOrigin = [];
          dispatch({ type: 'srcCity', payload: [] })  
      }
  }

  const debouncedSave = useCallback(
      debounce(obj => predectiveCall(obj.textValue, obj.type, obj.index), 500),
      [], // will be created only once initially
  );

  //For handling destination related actions in flight search panel
  const handleDestination = (index, input, e) => {
      console.log("input--", input);
      dispatch({ type: "selDestination", payload: [] })
      
      if (input.length > 2) {
          state.selDestination = [];
          let obj = {
              textValue: input,
              type: 'destCity',
              index: index
          }
          debouncedSave(obj);
      }
      else {
          state.selDestination = [];
          dispatch({ type: 'destCity', payload: [] })
      }
  }

  //For handling predective search hits
  const predectiveCall = async (input, field, index) => {
      if (input.length > 1) {
          await PackageService.retreieveSearch(input).then((data) => {
              console.log('res.......', data.data)
              const options = [];
              if(data.data.suc !== false) {
              data && data.data && data.data.map(val => {
                  options.push({
                    priority: `${val.priority}`,
                    airportName: `${val.airportName}`,
                    airportCode: `${val.airportCode}`,
                    cityId: `${val.cityId}`,
                    cityName: `${val.cityName}`,
                    countryName: `${val.countryName}`,
                    countryCode: `${val.countryCode}`,
                    score: `${val.score}`,
                  })
              })
             }
             if(index === 0) {
              dispatch({ type: "srcRowZero", payload: data.data !== "" ? options : [] })  
              dispatch({ type: "destRowZero", payload: data.data !== "" ? options : [] })  
             } 
             if(index === 1) {
              dispatch({ type: "srcRowOne", payload: data.data !== "" ? options : [] }) 
              dispatch({ type: "destRowOne", payload: data.data !== "" ? options : [] })   
             } 
             if(index === 2) {
              dispatch({ type: "srcRowTwo", payload: data.data !== "" ? options : [] })  
              dispatch({ type: "destRowTwo", payload: data.data !== "" ? options : [] })  
             } 
             if(index === 3) {
              dispatch({ type: "srcRowThree", payload: data.data !== "" ? options : [] })  
              dispatch({ type: "destRowThree", payload: data.data !== "" ? options : [] })  
             } 
             if(index === 4) {
              dispatch({ type: "srcRowFour", payload: data.data !== "" ? options : [] })  
              dispatch({ type: "destRowFour", payload: data.data !== "" ? options : [] })  
             } 
             if(index === 5) {
              dispatch({ type: "srcRowFive", payload: data.data !== "" ? options : [] }) 
              dispatch({ type: "destRowFive", payload: data.data !== "" ? options : [] })   
             } 
             if(index === 6) {
              dispatch({ type: "srcRowSix", payload: data.data !== "" ? options : [] })  
              dispatch({ type: "destRowSix", payload: data.data !== "" ? options : [] })  
             } 
             if(index === 7) {
              dispatch({ type: "srcRowSeven", payload: data.data !== "" ? options : [] })  
              dispatch({ type: "destRowSeven", payload: data.data !== "" ? options : [] })  
             } 
          });
      }
      else {
          dispatch({ type: field, payload: [] })
      }
  }

    //For handling source select related actions in flight search panel
    const onSourceSelect = (index,field,obj) => {
      state.src = obj;
      setSrcInfo(obj);
      state.selOrigin = obj.cityName;
      setSource(obj.cityName);
      console.log("selected origin ---> ", state.selOrigin);
      setSourceEnable(false);
      // setSourceClearEnable(true);
      const updatedRows = [...rows];
      updatedRows[index][field] = obj;
      updatedRows[index].clearSourceField = true;
      setRows(updatedRows);
      setUnsavedChanges(true);
      
      dispatch({ type: "srcRowZero", payload: [] })  
      dispatch({ type: "srcRowOne", payload: [] })  
      dispatch({ type: "srcRowTwo", payload: [] })  
      dispatch({ type: "srcRowThree", payload: [] })  
      dispatch({ type: "srcRowFour", payload: [] })  
      dispatch({ type: "srcRowFive", payload: [] }) 
      dispatch({ type: "srcRowSix", payload: [] })
      dispatch({ type: "srcRowSeven", payload: [] }) 

      
      
      dispatch({ type: "destRowZero", payload: [] })  
      dispatch({ type: "destRowOne", payload: [] })  
      dispatch({ type: "destRowTwo", payload: [] })  
      dispatch({ type: "destRowThree", payload: [] })  
      dispatch({ type: "destRowFour", payload: [] })  
      dispatch({ type: "destRowFive", payload: [] }) 
      dispatch({ type: "destRowSix", payload: [] })
      dispatch({ type: "destRowSeven", payload: [] }) 
    }

    //For handling destination select related actions in flight search panel
    const onDestinationSelect = (index,field,obj) => {
        state.dest = obj;
        state.selDestination = obj.cityName;
        setDestination(obj.cityName);
        //setDepartureDateFocus(true);
        setDestinationEnable(false);
        // setDestinationClearEnable(true);
        
        const updatedRows = [...rows];
        updatedRows[index][field] = obj;
        updatedRows[index].clearDestinationField = true;
        setRows(updatedRows);
        setUnsavedChanges(true);
      
        dispatch({ type: "destRowZero", payload: [] })  
        dispatch({ type: "destRowOne", payload: [] })  
        dispatch({ type: "destRowTwo", payload: [] })  
        dispatch({ type: "destRowThree", payload: [] })  
        dispatch({ type: "destRowFour", payload: [] })  
        dispatch({ type: "destRowFive", payload: [] }) 
        dispatch({ type: "destRowSix", payload: [] })
        dispatch({ type: "destRowSeven", payload: [] }) 
      
        dispatch({ type: "srcRowZero", payload: [] })  
        dispatch({ type: "srcRowOne", payload: [] })  
        dispatch({ type: "srcRowTwo", payload: [] })  
        dispatch({ type: "srcRowThree", payload: [] })  
        dispatch({ type: "srcRowFour", payload: [] })  
        dispatch({ type: "srcRowFive", payload: [] }) 
        dispatch({ type: "srcRowSix", payload: [] })
        dispatch({ type: "srcRowSeven", payload: [] }) 
    }

    //For handling source change related actions in flight search panel
    const onSourceChange = (index, field, data) => {
      setSrcPredectiveLoading(true);
      setSrcLoaded(false);
      console.log(data);
      setSource(data);
      handleOrigin(index, data);
      setSourceEnable(true);
      // setSourceClearEnable(true);

      const updatedRows = [...rows];
      updatedRows[index][field] = data;
      updatedRows[index].clearSourceField = true;
      setRows(updatedRows);
      setUnsavedChanges(true);
    };

    //For handling destination change related actions in flight search panel
    const onDestinationChange = (index, field, data) => {
      setDestPredectiveLoading(true);
      setDestLoaded(false);
      console.log(data);
      setDestination(data);
      handleDestination(index, data);
      setDestinationEnable(true);
      // setDestinationClearEnable(true);

      const updatedRows = [...rows];
      updatedRows[index][field] = data;
      updatedRows[index].clearDestinationField = true;
      setRows(updatedRows);
      setUnsavedChanges(true);
  };

    //For clearing the source field
    const clearSource = (index, field) => {      
      const updatedRows = [...rows];
      updatedRows[index][field].cityName = "";
      updatedRows[index].dep_city_en = "";
      updatedRows[index].clearSourceField = false;
      setRows(updatedRows);
      setUnsavedChanges(true);

      setSource('');
      state.selOrigin = [];
      setSourceEnable(false);
      // setSourceClearEnable(false);
    }

    //For clearing the destination field
    const clearDestination = (index,field) => {
      
      
      const updatedRows = [...rows];
      updatedRows[index][field].cityName = "";
      updatedRows[index].arr_city_en = "";
      updatedRows[index].clearDestinationField = false;
      setRows(updatedRows);
      setUnsavedChanges(true);

      setDestination('');
      state.selDestination = '';
      setDestinationEnable(false);
      setDestinationClearEnable(false);
    }


    return (
        <>
        <HeaderComp />
        <Alert className="notification" variant={notiVarient} show={notiMessageShow}
          onClose={() => setNotiMessageShow(false)}
          dismissible> {notiMessage}</Alert>
        <Container fluid>
        <Row>
          <Col xs={6} md={2} className="sideBar">
            <SidebarMenu active="Top Routes" />
          </Col>

          <Col xs={12} md={10} className="innerBgcolor">
                <Col className="d-flex justify-content-between align-center mt-4 pl-0 spaceBet">
                    <h6 className="ml-2 mb-0">People Flying Here</h6>
                </Col>
            <Row>

            <div className="tableForm">


            <div className="tableForminner">
                  <Form noValidate validated={validated} id="formId">
                  <div className='formSectionn'>

                    <Row>
                    <Col xs={3}>

                      <Form.Label>Select Country <sup>*</sup></Form.Label>
                      <Form.Group
                      controlId="ctyp"
                      >
                      <Select
                        disabled={enableUpdate}
                        options={countryTypes}
                        searchable={false}
                        values={state.selCountry}
                        onChange={(value) => updateCountry(value)}
                      />
                    </Form.Group>

                    </Col>


                    <Col xs={3}>

                      <Form.Label>Channel Type <sup>*</sup></Form.Label>
                      <Form.Group
                      controlId="ctyp"
                      >
                      <Select
                        disabled={enableUpdate}
                        options={chanelTypes}
                        searchable={false}
                        values={state.selChannel}
                        onChange={(value) => updateChanel(value)}
                      />
                    </Form.Group>

                    </Col>

                    <Col xs={2}>

                    </Col>

                    </Row>
                    </div>


          <div>
          {rows.map((row, index) => (
            <div key={index} className='upload-image mt-4'>
            <Row>
            <Col xs={6}>
                <Row>
                    <Col xs={12}>
                        <Form.Label>
                            Sector {index + 1} (English) <sup>*</sup>
                        </Form.Label>
                    </Col>
                    <Col xs={6}>                        
                        <Form.Group className='dest-from'>
                          <FormControl 
                            clear
                            type="text" 
                            value={row.src_info.cityName?row.src_info.cityName:row.dep_city_en} 
                            onChange={(e) => onSourceChange(index, "dep_city_en", e.target.value)}                            
                            placeholder="Departure City"
                          >
                          </FormControl>

                          
                          {index === 0 && (<><span class="focus-border">
                                <i></i>
                            </span>
                            {sourceEnable && <ul>
                              {!srcPredectiveLoading && !srcLoaded && source.length > 2 && <li className="no-match-found">
                              <span><i class="icon icon-Info"></i> Please enter a valid departure city.</span>
                              </li>}
                              {state.srcRowZero!==undefined && state.srcRowZero.length > 0 && state.srcRowZero.map((result) => (
                                <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onSourceSelect(index,'src_info',result)}>
                                  <div className="airportNamecode-box">
                                  <div className="airportNamecode">
                                      <span>{result.cityName}</span>
                                      <span>{result.airportCode}</span>
                                  </div>
                                  <span> {result.countryName} {result.airportName}</span>
                                  </div>                                
                                </li>
                              ))}
                          </ul>} 
                          
                          {row.clearSourceField &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearSource(index,'src_info')}/> }
                          </>)}

                          
                          {index === 1 && (<><span class="focus-border">
                                <i></i>
                            </span>
                            {sourceEnable && <ul>
                              {!srcPredectiveLoading && !srcLoaded && source.length > 2 && <li className="no-match-found">
                              <span><i class="icon icon-Info"></i> Please enter a valid departure city.</span>
                              </li>}
                              {state.srcRowOne!==undefined && state.srcRowOne.length > 0 && state.srcRowOne.map((result) => (
                                <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onSourceSelect(index,'src_info',result)}>
                                  <div className="airportNamecode-box">
                                  <div className="airportNamecode">
                                      <span>{result.cityName}</span>
                                      <span>{result.airportCode}</span>
                                  </div>
                                  <span> {result.countryName} {result.airportName}</span>
                                  </div>                                
                                </li>
                              ))}
                          </ul>} 
                          
                          {row.clearSourceField &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearSource(index,'src_info')}/> }
                          </>)}
                            

                          
                          {index === 2 && (<><span class="focus-border">
                          <i></i>
                      </span>
                      {sourceEnable && <ul>
                        {!srcPredectiveLoading && !srcLoaded && source.length > 2 && <li className="no-match-found">
                        <span><i class="icon icon-Info"></i> Please enter a valid departure city.</span>
                        </li>}
                        {state.srcRowTwo!==undefined && state.srcRowTwo.length > 0 && state.srcRowTwo.map((result) => (
                          <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onSourceSelect(index,'src_info',result)}>
                            <div className="airportNamecode-box">
                            <div className="airportNamecode">
                                <span>{result.cityName}</span>
                                <span>{result.airportCode}</span>
                            </div>
                            <span> {result.countryName} {result.airportName}</span>
                            </div>                                
                          </li>
                        ))}
                    </ul>} 
                    
                    {row.clearSourceField &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearSource(index,'src_info')}/> }
                    </>)}

                          
                    {index === 3 && (<><span class="focus-border">
                    <i></i>
                    </span>
                    {sourceEnable && <ul>
                    {!srcPredectiveLoading && !srcLoaded && source.length > 2 && <li className="no-match-found">
                    <span><i class="icon icon-Info"></i> Please enter a valid departure city.</span>
                    </li>}
                    {state.srcRowThree!==undefined && state.srcRowThree.length > 0 && state.srcRowThree.map((result) => (
                    <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onSourceSelect(index,'src_info',result)}>
                      <div className="airportNamecode-box">
                      <div className="airportNamecode">
                          <span>{result.cityName}</span>
                          <span>{result.airportCode}</span>
                      </div>
                      <span> {result.countryName} {result.airportName}</span>
                      </div>                                
                    </li>
                    ))}
                    </ul>} 
                    
                    {row.clearSourceField &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearSource(index,'src_info')}/> }
                    </>)}
                    

                          
                    {index === 4 && (<><span class="focus-border">
                          <i></i>
                      </span>
                      {sourceEnable && <ul>
                        {!srcPredectiveLoading && !srcLoaded && source.length > 2 && <li className="no-match-found">
                        <span><i class="icon icon-Info"></i> Please enter a valid departure city.</span>
                        </li>}
                        {state.srcRowFour!==undefined && state.srcRowFour.length > 0 && state.srcRowFour.map((result) => (
                          <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onSourceSelect(index,'src_info',result)}>
                            <div className="airportNamecode-box">
                            <div className="airportNamecode">
                                <span>{result.cityName}</span>
                                <span>{result.airportCode}</span>
                            </div>
                            <span> {result.countryName} {result.airportName}</span>
                            </div>                                
                          </li>
                        ))}
                    </ul>} 
                    
                    {row.clearSourceField &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearSource(index,'src_info')}/> }
                    </>)}

                          
                    {index === 5 && (<><span class="focus-border">
                    <i></i>
                    </span>
                    {sourceEnable && <ul>
                    {!srcPredectiveLoading && !srcLoaded && source.length > 2 && <li className="no-match-found">
                    <span><i class="icon icon-Info"></i> Please enter a valid departure city.</span>
                    </li>}
                    {state.srcRowFive!==undefined && state.srcRowFive.length > 0 && state.srcRowFive.map((result) => (
                    <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onSourceSelect(index,'src_info',result)}>
                      <div className="airportNamecode-box">
                      <div className="airportNamecode">
                          <span>{result.cityName}</span>
                          <span>{result.airportCode}</span>
                      </div>
                      <span> {result.countryName} {result.airportName}</span>
                      </div>                                
                    </li>
                    ))}
                    </ul>} 
                    
                    {row.clearSourceField &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearSource(index,'src_info')}/> }
                    </>)}
                    

                          
                    {index === 6 && (<><span class="focus-border">
                          <i></i>
                      </span>
                      {sourceEnable && <ul>
                        {!srcPredectiveLoading && !srcLoaded && source.length > 2 && <li className="no-match-found">
                        <span><i class="icon icon-Info"></i> Please enter a valid departure city.</span>
                        </li>}
                        {state.srcRowSix!==undefined && state.srcRowSix.length > 0 && state.srcRowSix.map((result) => (
                          <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onSourceSelect(index,'src_info',result)}>
                            <div className="airportNamecode-box">
                            <div className="airportNamecode">
                                <span>{result.cityName}</span>
                                <span>{result.airportCode}</span>
                            </div>
                            <span> {result.countryName} {result.airportName}</span>
                            </div>                                
                          </li>
                        ))}
                    </ul>} 
                    
                    {row.clearSourceField &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearSource(index,'src_info')}/> }
                    </>)}

                          
                    {index === 7 && (<><span class="focus-border">
                    <i></i>
                    </span>
                    {sourceEnable && <ul>
                    {!srcPredectiveLoading && !srcLoaded && source.length > 2 && <li className="no-match-found">
                    <span><i class="icon icon-Info"></i> Please enter a valid departure city.</span>
                    </li>}
                    {state.srcRowSeven!==undefined && state.srcRowSeven.length > 0 && state.srcRowSeven.map((result) => (
                    <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onSourceSelect(index,'src_info',result)}>
                      <div className="airportNamecode-box">
                      <div className="airportNamecode">
                          <span>{result.cityName}</span>
                          <span>{result.airportCode}</span>
                      </div>
                      <span> {result.countryName} {result.airportName}</span>
                      </div>                                
                    </li>
                    ))}
                    </ul>} 
                    
                    {row.clearSourceField &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearSource(index,'src_info')}/> }
                    </>)}

                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group className='dest-to'>
                            <Form.Control
                                type="text"
                                placeholder="Arrival City"
                                value={row.dest_info.cityName?row.dest_info.cityName:row.arr_city_en}
                                onChange={(e) => onDestinationChange(index, "arr_city_en", e.target.value)}
                            ></Form.Control>
                    
                        {index === 0 && ( <>
                                <span class="focus-border">
                                    <i></i>
                                </span>
                            {destinationEnable && <ul>
                                {!destPredectiveLoading && !destLoaded && destination.length > 2 && <li className="no-match-found">
                                <span><i class="icon icon-Info"></i> Please enter a valid Arrival city.</span>
                  </li>}
                                {state.destRowZero!==undefined && state.destRowZero.length > 0 && state.destRowZero.map((result) => (
                                    <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onDestinationSelect(index,'dest_info',result)}>
                                    <div className="airportNamecode-box">
                                    <div className="airportNamecode">
                                        <span>{result.cityName}</span>
                                        <span>{result.airportCode}</span>
                                    </div>
                                    <span> {result.countryName} {result.airportName}</span>
                                    </div>
                                    </li>
                                ))}
                            </ul>} 
                            {row.clearDestinationField && <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearDestination(index,'dest_info')}/> }
                            </>)}
                            
                            {index === 1 && ( <>
                              <span class="focus-border">
                                  <i></i>
                              </span>
                            {destinationEnable && <ul>
                              {!destPredectiveLoading && !destLoaded && destination.length > 2 && <li className="no-match-found">
                              <span><i class="icon icon-Info"></i> Please enter a valid Arrival city.</span>
                            </li>}
                              {state.destRowOne!==undefined && state.destRowOne.length > 0 && state.destRowOne.map((result) => (
                                  <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onDestinationSelect(index,'dest_info',result)}>
                                  <div className="airportNamecode-box">
                                  <div className="airportNamecode">
                                      <span>{result.cityName}</span>
                                      <span>{result.airportCode}</span>
                                  </div>
                                  <span> {result.countryName} {result.airportName}</span>
                                  </div>
                                  </li>
                              ))}
                            </ul>} 
                            {row.clearDestinationField && <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearDestination(index,'dest_info')}/> }
                            </>)}
                            
                            {index === 2 && ( <>
                              <span class="focus-border">
                                  <i></i>
                              </span>
                            {destinationEnable && <ul>
                              {!destPredectiveLoading && !destLoaded && destination.length > 2 && <li className="no-match-found">
                              <span><i class="icon icon-Info"></i> Please enter a valid Arrival city.</span>
                            </li>}
                              {state.destRowTwo!==undefined && state.destRowTwo.length > 0 && state.destRowTwo.map((result) => (
                                  <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onDestinationSelect(index,'dest_info',result)}>
                                  <div className="airportNamecode-box">
                                  <div className="airportNamecode">
                                      <span>{result.cityName}</span>
                                      <span>{result.airportCode}</span>
                                  </div>
                                  <span> {result.countryName} {result.airportName}</span>
                                  </div>
                                  </li>
                              ))}
                            </ul>} 
                            {row.clearDestinationField && <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearDestination(index,'dest_info')}/> }
                            </>)}
                            
                            {index === 3 && ( <>
                              <span class="focus-border">
                                  <i></i>
                              </span>
                            {destinationEnable && <ul>
                              {!destPredectiveLoading && !destLoaded && destination.length > 2 && <li className="no-match-found">
                              <span><i class="icon icon-Info"></i> Please enter a valid Arrival city.</span>
                            </li>}
                              {state.destRowThree!==undefined && state.destRowThree.length > 0 && state.destRowThree.map((result) => (
                                  <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onDestinationSelect(index,'dest_info',result)}>
                                  <div className="airportNamecode-box">
                                  <div className="airportNamecode">
                                      <span>{result.cityName}</span>
                                      <span>{result.airportCode}</span>
                                  </div>
                                  <span> {result.countryName} {result.airportName}</span>
                                  </div>
                                  </li>
                              ))}
                            </ul>} 
                            {row.clearDestinationField && <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearDestination(index,'dest_info')}/> }
                            </>)}
                            
                            {index === 4 && ( <>
                              <span class="focus-border">
                                  <i></i>
                              </span>
                            {destinationEnable && <ul>
                              {!destPredectiveLoading && !destLoaded && destination.length > 2 && <li className="no-match-found">
                              <span><i class="icon icon-Info"></i> Please enter a valid Arrival city.</span>
                            </li>}
                              {state.destRowFour!==undefined && state.destRowFour.length > 0 && state.destRowFour.map((result) => (
                                  <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onDestinationSelect(index,'dest_info',result)}>
                                  <div className="airportNamecode-box">
                                  <div className="airportNamecode">
                                      <span>{result.cityName}</span>
                                      <span>{result.airportCode}</span>
                                  </div>
                                  <span> {result.countryName} {result.airportName}</span>
                                  </div>
                                  </li>
                              ))}
                            </ul>} 
                            
                            {row.clearDestinationField && <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearDestination(index,'dest_info')}/> }
                            </>)}
                            
                            {index === 5 && ( <>
                              <span class="focus-border">
                                  <i></i>
                              </span>
                            {destinationEnable && <ul>
                              {!destPredectiveLoading && !destLoaded && destination.length > 2 && <li className="no-match-found">
                              <span><i class="icon icon-Info"></i> Please enter a valid Arrival city.</span>
                            </li>}
                              {state.destRowFive!==undefined && state.destRowFive.length > 0 && state.destRowFive.map((result) => (
                                  <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onDestinationSelect(index,'dest_info',result)}>
                                  <div className="airportNamecode-box">
                                  <div className="airportNamecode">
                                      <span>{result.cityName}</span>
                                      <span>{result.airportCode}</span>
                                  </div>
                                  <span> {result.countryName} {result.airportName}</span>
                                  </div>
                                  </li>
                              ))}
                            </ul>} 
                            
                            {row.clearDestinationField && <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearDestination(index,'dest_info')}/> }
                            </>)}
                            
                            {index === 6 && ( <>
                              <span class="focus-border">
                                  <i></i>
                              </span>
                            {destinationEnable && <ul>
                              {!destPredectiveLoading && !destLoaded && destination.length > 2 && <li className="no-match-found">
                              <span><i class="icon icon-Info"></i> Please enter a valid Arrival city.</span>
                            </li>}
                              {state.destRowSix!==undefined && state.destRowSix.length > 0 && state.destRowSix.map((result) => (
                                  <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onDestinationSelect(index,'dest_info',result)}>
                                  <div className="airportNamecode-box">
                                  <div className="airportNamecode">
                                      <span>{result.cityName}</span>
                                      <span>{result.airportCode}</span>
                                  </div>
                                  <span> {result.countryName} {result.airportName}</span>
                                  </div>
                                  </li>
                              ))}
                            </ul>} 
                            
                            {row.clearDestinationField && <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearDestination(index,'dest_info')}/> }
                            </>)}
                            
                            {index === 7 && ( <>
                              <span class="focus-border">
                                  <i></i>
                              </span>
                            {destinationEnable && <ul>
                              {!destPredectiveLoading && !destLoaded && destination.length > 2 && <li className="no-match-found">
                              <span><i class="icon icon-Info"></i> Please enter a valid Arrival city.</span>
                            </li>}
                              {state.destRowSeven!==undefined && state.destRowSeven.length > 0 && state.destRowSeven.map((result) => (
                                  <li key={`${result.airportName} ${result.cityName} ${result.countryName} ${result.airportCode}`} onClick={e => onDestinationSelect(index,'dest_info',result)}>
                                  <div className="airportNamecode-box">
                                  <div className="airportNamecode">
                                      <span>{result.cityName}</span>
                                      <span>{result.airportCode}</span>
                                  </div>
                                  <span> {result.countryName} {result.airportName}</span>
                                  </div>
                                  </li>
                              ))}
                            </ul>} 
                            
                            {row.clearDestinationField && <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearDestination(index,'dest_info')}/> }
                            </>)}
                        
                        </Form.Group>
                    </Col>
                </Row>
                </Col><Col xs={6}>
                <Row>
                    <Col xs={12}>
                        <Form.Label>
                            Sector {index + 1} (Arabic) <sup>*</sup>
                        </Form.Label>
                    </Col>
                    <Col xs={6}>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                disabled={state.disabled}
                                placeholder="Departure City"
                                value={row.dep_city_ar}
                                onChange={(e) => handleInputChange(index, 'dep_city_ar', e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group>
                            <Form.Control
                                disabled={state.disabled}
                                placeholder="Arrival City"
                                type="text"
                                value={row.arr_city_ar}
                                onChange={(e) => handleInputChange(index, 'arr_city_ar', e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                </Col>

                </Row>

                <Row>
                    <Col xs={3}>
                      <Form.Label>Destination Image <sup>*</sup></Form.Label>
                      <Form.Group controlId="recImgEn" className="mb-3">
                        <ImageUpload reportUrls={row.url_destination} sendFileUrl={updateFileName} index={index} />
                          {imgfile && (
                            <p className="vError">Please upload the Image</p>
                          )}

                          {/* <input
                              type="file"
                              accept=".jpg,.png"
                              onChange={(e) => handleImageUpload(index, e.target.files[0])}
                          />
                             </div>
                          <br />
                          <span>JPG or PNG (Dimensions: 480x180)</span> */}
                      </Form.Group>
                    </Col>
                    {/* <Col xs={2}>
                        {row.url_destination && (
                          <div className='toproutes-image'>
                            <img src={row.url_destination} alt="Uploaded" />
                            <span onClick={() => handleDeleteImage(index)}>×</span>
                            {row.showDeletePopup && (
                                <div>
                                    <Modal
        show={row.showDeletePopup}
        onHide={() => handleClosePopup(false)}
        backdrop="static"
        size="small"
        className="custom-dailog"
        keyboard={false}>
            <Modal.Header>
                <Modal.Title>Delete Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    <div>
                    <Form.Label className="custom-dailog-title">Are you sure you want to delete selected Image ?</Form.Label>
                    </div>

                                <Button onClick={() => handleClosePopup(index, true)}
                                    size="xs"
                                    variant="outline-primary"
                                    // disabled = {isLoading && pleasewait ? true : false }
                                    >
                                    Yes
                                </Button>
                        <Button
                            size="xs"
                            variant="outline-dark"
                            onClick={() => handleClosePopup(index, false)}
                            type="submit"> No </Button>
                </div>
             </Modal.Body>
    </Modal>






                                </div>
                            )}
                          </div>
                         )}
                    </Col> */}
              </Row>
            </div>
          ))}
          {rows.length < 8 ? <Button className="plusBtn popuHotel" onClick={handleAddRow}><span>+</span></Button> : null}
        </div>


                    <hr />

                    <Row>
                        <Col>
                        {/* <Form.Label>Status</Form.Label> */}
                        <Form.Group as={Col} className="topSwitch">
                            <Form.Check
                            type="switch"
                            id="act"
                            label="Status"
                            checked={act}
                            value={act}
                            onChange={handleChange}
                            />
                        </Form.Group>
                        </Col>


                    <Row className="submitBtn">
                      <Col className="d-flex">
                      {!enableUpdate && <Button type="button" variant="primary" size="sm" className="mr-3" onClick={handleCancel}>Cancel</Button>}
                      {cancelConfirmation && (
                            <div className="confirmation-popup">
                                <Modal
                        show={cancelConfirmation}
                        onHide={() => handleClosePopup(false)}
                        backdrop="static"
                        size="small"
                        className="custom-dailog alignDelc"
                        keyboard={false}>
                        {/* <Modal.Header>
                        <Modal.Title>Delete Image</Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body>
                        <div className="">
                        <div>
                        <Form.Label className="custom-dailog-title">Any unsaved data will be lost. Click OK to continue.</Form.Label>
                        </div>
                        <Button onClick={() => handleCancelConfirmation(true)}
                            size="xs"
                            variant="outline-primary"
                            className='mr-2'
                            // disabled = {isLoading && pleasewait ? true : false }
                            >
                            OK
                        </Button>
                        <Button
                            size="xs"
                            variant="outline-dark"
                            onClick={() => handleCancelConfirmation(false)}
                            type="submit"> Cancel </Button>
                        </div>
                        </Modal.Body>
                        </Modal>
                            </div>
                        )}

                        {!enableUpdate && <Button type="submit" className="saveBttn mr-2" variant="primary" size="sm" onClick={handleTopRoutesCreation}><span>Save</span> </Button>
                        }
                        {enableUpdate && <Button type="submit" variant="primary" size="sm" onClick={handleTopRoutesUpdation}>Update</Button>}
                      </Col>
                    </Row>
                    </Row>

                    </Form>
                    </div>

            </div>

            <div className='tableForm mt-3'>
            <div className="bodyContent">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbll taFeeq popolHotl"
                  data={res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={res.length >10 && res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  res.length >25 && res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                {res.length === 0 &&
                 <div className="norecord">
                 <strong>No Record Found.</strong>
                 </div>
                }
            </div>
            </div>
            </Row>
          </Col>
        </Row>
    </Container>
    <FooterComp />
    </>
    );
}

export default TopRoutes;