import PackageService from '../../services/PackageService';
/**
 * @description: CRUD operations for Branch
 * @author: Ubedullah
 * @param {*}
 * @function CNT Recommendations
 * @date : 16-05-2023
 */

//Loading Recommendations data
export const getRecommendations = async (obj) => {
    let list = [];
    await PackageService.loadRecommendations(obj).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}


//Save Recommendations 
export const saveRecommendations = async (data) => {
    let list = [];
    await PackageService.saveRecommendations(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Update Recommendations 
export const updateRecommendations = async (data) => {
    let list = [];
    await PackageService.updateRecommendations(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}




