import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { Container, Form, Row, Col, Modal, Alert, Button } from "react-bootstrap";
import HeaderComp from '../common/header';
import FooterComp from '../common/Footer';
import Select from "react-dropdown-select";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getPopularHotels, savePopularHotels, updatePopularHotels } from './operation';
import { FaEdit } from "react-icons/fa";
import ImageUpload from './ImageUpload';
import SidebarMenu from '../common/sidebar';
import { debounce } from "lodash";
import PackageService from '../../services/PackageService'


/**
 * Initial State Declaration
 */
const initialState = {
    _id: "", channel_type:"", country:"", url_en: "", url_ar: "",  active: false, selChannel:[], selCountry:[],
    htlRowZero: [], htlRowOne: [], htlRowTwo: [], htlRowThree: []}


  // Reducer function for useReact Hook which will update the state
  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
          ...state, id: "", channel_type:"", country:"", url_en: "", url_ar: "", active: false, htlRowZero: [], htlRowOne: [], htlRowTwo: [], htlRowThree: []
        };
      case 'editPopularHotels':
        return Object.assign(state, action.payload);
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

/**
 * @description:PopularHotels Screen
 * @author: Ubedullah
 * @param {*}
 * @function PopularHotels - CMS
 * @date : 05-15-2023
 */

const Popularhotels = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [notiMessage, setNotiMessage] = useState('');
    const [notiMessageShow, setNotiMessageShow] = useState(false);
    const [notiVarient, setNotiVarient] = useState();
    const [validated, setValidated] = useState(false);
    const [enableUpdate, setEnableUpdate] = useState(false);
    const [countryTypes, setCountryTypes] = useState([{value:"India",label:"India"},{value:"Saudi Arabia",label:"Saudi Arabia"},{value:"United Arab Emirates",label:"United Arab Emirates"},{value:"Qatar",label:"Qatar"},{value:"Oman",label:"Oman"}]);
    const [chanelTypes, setChannelTypes] = useState([{value:"B2B",label:"B2B"},{value:"B2C",label:"B2C"}]);
    const [imgfile, setImgFile] = useState(false);
    const [act, setAct] = useState(true);
    const [res, setRes] = useState([]);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [cancelConfirmation, setCancelConfirmation] = useState(false);

    // predective search state variables
    const [htlEn, setHtlEn] = useState();
    const [htlEnEnable,setHtlEnEnable] = useState(false);
    const [srcPredectiveLoading,setSrcPredectiveLoading] = useState(false);
    const [htlEnLoaded,setHtlEnLoaded] = useState(false);
    const [srcInfo, setHtlEnInfo] = useState({});


    useEffect(() => {
        getPopularHotels({"type":"ALL"}).then(response => {
          console.log('PopularHotels response', response)
          if (response.data.suc && response.data.res) {
            convertPopularHotelsData(response.data.res)
          }
        }).catch(function (error) {
          console.log('PopularHotels response error ', error)
        });
      }, [])


/**
 * @description:PopularHotels Screen Edit functionality
 * @author: Ubedullah
 * @param {*}
 * @function editPopularHotels - CMS
 * @date : 05-15-2023
 */

  const editPopularHotels=(obj)=>{
    dispatch({ type: 'editPopularHotels', payload: obj })
    dispatch({ type: 'selChannel', payload: [{value:obj.channel,label:obj.channel}] })
    dispatch({ type: 'selCountry', payload: [{value:obj.region,label:obj.region}] })
    setRowData(obj.htl_list);
    setAct(obj.status === "Active" ? true : false)
    setEnableUpdate(true)
  }

  /**
 * @description:PopularHotels Screen display UI in Table
 * @author: Ubedullah
 * @param {*}
 * @function convertPopularHotelsData - CMS
 * @date : 05-15-2023
 */

  const columns = [{
    dataField: 'country',
    text: 'Country Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'cha_type',
    text: 'Channel Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status'
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];


  const convertPopularHotelsData=(response)=>{
    let data = [];
    let index=1;
     for(let value of response){
      data.push({ country: value.region,cha_type:value.channel, status: value.status,
      options: <><span className="tbAction"><a className="ezyIcon icon-edit" onClick={()=>editPopularHotels(value)}><span>Edit</span></a></span>
      </> })
         index=index+1;
     }
     setRes(data)
  }

    const updateChanel=(obj)=>{
        dispatch({ type: 'channel_type', payload: obj[0].value })
        dispatch({ type: 'selChannel', payload: obj })
        setUnsavedChanges(true);
    }
    const updateCountry=(obj)=>{
        dispatch({ type: 'country', payload: obj[0].value })
        dispatch({ type: 'selCountry', payload: obj })
        setUnsavedChanges(true);
    }

    const updateFileName = (index, url) => {
      const updatedRows = [...rowData];
      updatedRows[index].img_url = url;
      setRowData(updatedRows);
      setUnsavedChanges(true);
    }

    const handleChange = (e) => {
      const { id, value } = e.target;
      if (id === 'act') {
          setAct(e.target.checked)
      }
      console.log("checked type:::::", e.target.id);
      setUnsavedChanges(true);
    };


  /**
 * @description:PopularHotels Screen Hiding success and Error validation message
 * @author: Ubedullah
 * @param {*}
 * @function hidemessage
 * @date : 05-15-2023
 */

  const hidemessage=() =>{
    setTimeout(function () {
        setNotiMessageShow(false);
        setNotiMessage('');
        setNotiVarient('');
    }, 4000);
  }

  /**
 * @description:PopularHotels Screen Validating Form
 * @author: Ubedullah
 * @param {*}
 * @function Validating Form
 * @date : 05-15-2023
 */

  const validatePopularHotels = () => {
    setImgFile(false)
    // setIsDuration(false)
    if(state.country===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Select Country');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(state.channel_type===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Select Channel Type');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(rowData.length < 4) {
      setNotiMessageShow(true);
      setNotiMessage('It is mandatory to add Four Most Popular Hotels');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }
    if(rowData.length!==0){
     for(let val of rowData){
        if(val.star_rating==""){
            setNotiMessageShow(true);
            setNotiMessage('Please Select Star Rating');
            setNotiVarient('danger')
            hidemessage();
            return false;
        }
       if(val.hot_en===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter Hotel(English)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }if(val.hot_ar===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter Hotel(Arabic)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }if(val.hot_add_en===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter description(English)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }if(val.hot_add_ar===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter description(Arabic)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }if(val.img_url===null){
        setNotiMessageShow(true);
        setNotiMessage('Please Upload Display Image');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }
     }
    }else if(rowData.length < 4) {
        setNotiMessageShow(true);
        setNotiMessage('It is mandatory to add Four Most Popular Hotels');
        setNotiVarient('danger')
        hidemessage();
        return false;
    }
    return true;
  }

  /**
   * @description:PopularHotels Screen Creation(save) data
   * @author: Ubedullah
   * @param {*}
   * @function handlePopularHotelsCreation - CMS
   * @date : 05-15-2023
   */

const handlePopularHotelsCreation = (event) => {
    event.preventDefault();
    if (validatePopularHotels()) {
      let suc = true;
      for (let val of res) {
        if (val.country?.toLowerCase() == state.country?.toLowerCase()) {
          if (
            val.country?.toLowerCase() == state.country?.toLowerCase() &&
            val.cha_type?.toLowerCase() == state.channel_type?.toLowerCase()
          ) {
            suc = false;
          }
        }
      }
      if(suc) {
        rowData.forEach(object => {
            delete object['showDeletePopup'];
            delete object['clearHtlEn'];
            delete object['htlName'];
        });
        const payload = {
            "region": state.country,
            "channel": state.channel_type,
            "status": act == true ? "Active" : "In-Active",
            "htl_list": rowData,
            "created_by": 1
        }
        savePopularHotels(payload).then(response => {
          console.log("Popular Hotels Save response " + JSON.stringify(response.data));
          if (response.data.suc) {
            window.scrollTo(0, 0);
            setNotiMessageShow(true);
            setNotiMessage('Most Popular Hotels are saved successfully...');
            setNotiVarient('success')
            setTimeout(function () {
              window.location="/cms/popular-hotels";
            }, 2000);

          } else {
            setNotiMessageShow(true);
            setNotiMessage('Most Popular Hotels details not saved');
            setNotiVarient('danger');
            hidemessage();
          }
        });
      } else {
        setNotiMessageShow(true);
        setNotiMessage('Country with saleschannel already exists');
        setNotiVarient('danger');
        hidemessage();
        return false;
      }
      }
    }

    /**
     * @description:PopularHotels Screen Updating(update) data
     * @author: Ubedullah
     * @param {*}
     * @function handlePopularHotelsUpdation - CMS
     * @date : 05-15-2023
     */

    const handlePopularHotelsUpdation = (event) => {
        event.preventDefault();
        if (validatePopularHotels()) {
            const status = act === true ? "Active" : "In-Active"
            state.htl_list = rowData
            state.updated_by = 1;
            state.status = status
            updatePopularHotels(state).then(response => {
            console.log("Itinarary Save response " + JSON.stringify(response.data));
            if (response.data.suc) {
              window.scrollTo(0, 0);
              setNotiMessageShow(true);
              setNotiMessage('Most Popular Hotels are updated successfully...');
              setNotiVarient('success')
              setTimeout(function () {
                window.location="/cms/popular-hotels";
              }, 2000);
            } else {
              setNotiMessageShow(true);
              setNotiMessage('Most Popular Hotels are not Updated');
              setNotiVarient('danger')
              hidemessage();
            }
          });
        }
    }

    /**
     * @description:PopularHotels Screen Image Rows Functionality (ADD)
     * @author: Ubedullah
     * @param {*}
     * @date : 05-15-2023
     */

    const [rowCount, setRowCount] = useState(1);
  const [rowData, setRowData] = useState([
    {
      star_rating: "",
      hot_en: "",
      hot_ar: "",
      hot_add_en: "",
      hot_add_ar: "",
      img_url: null,
      showDeletePopup: false,
      clearHtlEn: false,
      htl_info: {}, 
      htlName: [], 
    }
  ]);

  const handleAddRow = () => {
    setRowCount(rowCount + 1);
    setRowData([
      ...rowData,
      {
        star_rating: "",
        hot_en: "",
        hot_ar: "",
        hot_add_en: "",
        hot_add_ar: "",
        img_url: null,
        showDeletePopup: false,
        clearHtlEn: false,
        htl_info: {}, 
        htlName: [], 
      }
    ]);
  };

  const handleTextChange = (index, field, value) => {
    const updatedRowData = [...rowData];
    updatedRowData[index][field] = value;
    setRowData(updatedRowData);
    setUnsavedChanges(true);
  };


  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRowData = [...rowData];
    updatedRowData[index] = {
      ...updatedRowData[index],
      [name]: value
    };
    setRowData(updatedRowData);
    setUnsavedChanges(true);
  };

  const updateDescEn = (data) => {
    for(let val of rowData){
      if(Number(val.day)===Number(data[1])){
         val.hot_add_en=data[0];
       }
     }
     console.log("List:::::::::::::::",rowData);
  }

  const updateDescAr = (data) => {
      for(let val of rowData){
        if(Number(val.day)===Number(data[1])){
           val.hot_add_ar=data[0];
         }
       }
       console.log("List:::::::::::::::",rowData);
    }

  const handleImageUpload = (index, file) => {
    // Check if the file is a valid image with the correct dimensions
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;
          image.onload = () => {
            if (image.width === 480 && image.height === 580) {
              const updatedRowData = [...rowData];
              updatedRowData[index].img_url = URL.createObjectURL(file.split(" ").join(""));
              setRowData(updatedRowData);
              setUnsavedChanges(true);
            } else {
                // Invalid dimensions
                setNotiMessageShow(true);
                setNotiMessage('The image dimensions should be 480x580.');
                setNotiVarient('danger')
                hidemessage();
                return false;
            }
          };
        };
        reader.readAsDataURL(file);
      } else {
        setNotiMessageShow(true);
        setNotiMessage('Please select a JPG or PNG image.');
        setNotiVarient('danger')
        hidemessage();
        return false;
      }


        // Check if the file is a valid image with the correct dimensions
        // if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
        // const img = new Image();
        // img.src = URL.createObjectURL(file);

        // img.onload = () => {
        // if (img.width === 480 && img.height === 580) {
        //     const updatedRowData = [...rowData];
        //     updatedRowData[index].img_url = URL.createObjectURL(file);
        //     setRowData(updatedRowData);
        // } else {
        //     // Invalid dimensions
        //     setNotiMessageShow(true);
        //     setNotiMessage('The image dimensions should be 480x180.');
        //     setNotiVarient('danger')
        //     hidemessage();
        //     return false;
        // }
        // };
        // } else {
        //     // Invalid file type
        //     setNotiMessageShow(true);
        //     setNotiMessage('Please upload a JPG or PNG image.');
        //     setNotiVarient('danger')
        //     hidemessage();
        //     return false;
        // }
  };

  const handleDeleteImage = (index) => {
    const updatedRowData = [...rowData];
    updatedRowData[index].showDeletePopup = true;
    setRowData(updatedRowData);
  };

  const handleClosePopup = (index, confirmDelete) => {
    const updatedRowData = [...rowData];
    updatedRowData[index].showDeletePopup = false;

    if (confirmDelete) {
      updatedRowData[index].img_url = null;
    }

    setRowData(updatedRowData);
  };


  const numberOptions = [1, 2, 3, 4, 5];



  const handleCancel = () => {
    if (unsavedChanges) {
      setCancelConfirmation(true);
    } else {
      if(state.channel_type !== "" || state.country !== "") {
        dispatch({ type: 'channel_type', payload: "" })
        dispatch({ type: 'country', payload: "" })
      }
        setRowData([{
            star_rating: "",
            hot_en: "",
            hot_ar: "",
            hot_add_en: "",
            hot_add_ar: "",
            img_url: null,
            showDeletePopup: false
          }]);
        setAct(true)
    }
  };

  const handleCancelConfirmation = (confirmed) => {
    if (confirmed) {
      if(state.channel_type !== "" || state.country !== "") {
        dispatch({ type: 'channel_type', payload: "" })
        dispatch({ type: 'selChannel', payload: [{value:"", label: ""}] })
        dispatch({ type: 'country', payload: "" })
        dispatch({ type: 'selCountry', payload: [{value:"",label:""}] })
      }
      setRowData([{
        star_rating: "",
        hot_en: "",
        hot_ar: "",
        hot_add_en: "",
        hot_add_ar: "",
        img_url: null,
        showDeletePopup: false,
        clearHtlEn: false,
        htl_info: {}, 
        htlName: [], 
      }]);
      setAct(true)
      setUnsavedChanges(false);
    }
    setCancelConfirmation(false);
  };


    // predictive search for Hotel  
    //For handling origin related actions in Hotel search panel
    const handleOrigin = (index, input) => {
      console.log("input--", input);
      dispatch({ type: "selOrigin", payload: [] })
   
      if (input.length > 2) {
          state.selOrigin = [];
          let obj = {
              textValue: input,
              type: 'htlName',
              index: index
          }
          debouncedSave(obj);
      }
      else {
          state.selOrigin = [];
          dispatch({ type: 'htlName', payload: [] })  
      }
  }

  const debouncedSave = useCallback(
      debounce(obj => predectiveCall(obj.textValue, obj.type, obj.index), 500),
      [], // will be created only once initially
  );

  //For handling predective search hits
  const predectiveCall = async (input, field, index) => {
      if (input.length > 1) {
          await PackageService.retreieveHotelsSearch(input).then((data) => {
              console.log('res.......', data.data.data.property)
              const options = [];
              if(data.data.data.status !== false) {
              data && data.data && data.data.data && data.data.data.property.map(val => {
                  options.push({
                    country: `${val.country}`,
                    countryCode: `${val.countryCode}`,
                    description: `${val.description}`,
                    expediaCityId: `${val.expediaCityId}`,
                    expediapid: `${val.expediapid}`,
                    otilaCityId: `${val.otilaCityId}`,
                    otilapid: `${val.otilapid}`,
                    score: `${val.score}`,
                    text: `${val.text}`,
                    type: `${val.type}`,
                    weight: `${val.weight}`,
                  })
              })
             }
             if(index === 0) {
              dispatch({ type: "htlRowZero", payload: data.data !== "" ? options : [] })  
             } 
             if(index === 1) {
              dispatch({ type: "htlRowOne", payload: data.data !== "" ? options : [] })  
             } 
             if(index === 2) {
              dispatch({ type: "htlRowTwo", payload: data.data !== "" ? options : [] })  
             } 
             if(index === 3) {
              dispatch({ type: "htlRowThree", payload: data.data !== "" ? options : [] })  
             } 
          });
      }
      else {
          dispatch({ type: field, payload: [] })
      }
  }

    //For handling source select related actions in flight search panel
    const onHotelSelect = (index,field,obj) => {
      state.src = obj;
      setHtlEnInfo(obj);
      state.selOrigin = obj.text;
      setHtlEn(obj.text);
      console.log("selected origin ---> ", state.selOrigin);
      setHtlEnEnable(false);
      // setHotelClearEnable(true);
      
      const updatedRows = [...rowData];
      updatedRows[index][field] = obj;
      updatedRows[index].clearHtlEn = true;
      setRowData(updatedRows);
      setUnsavedChanges(true);
      
      dispatch({ type: "htlRowZero", payload: [] })  
      dispatch({ type: "htlRowOne", payload: [] })  
      dispatch({ type: "htlRowTwo", payload: [] })  
      dispatch({ type: "htlRowThree", payload: [] })  

    }

    //For handling Hotel change related actions in search panel
    const onHotelEnChange = (index, field, data) => {
      setSrcPredectiveLoading(true);
      setHtlEnLoaded(false);
      console.log(data);
      setHtlEn(data);
      handleOrigin(index, data);
      setHtlEnEnable(true);
      // setHotelClearEnable(true);
      
      const updatedRows = [...rowData];
      updatedRows[index][field] = data;
      updatedRows[index].clearHtlEn = true;
      setRowData(updatedRows);
      setUnsavedChanges(true);
    };

    //For clearing the Hotel search field
    const clearHtlName = (index, field) => {
      
      const updatedRows = [...rowData];
      updatedRows[index][field].text = "";
      updatedRows[index].hot_en = "";
      updatedRows[index].clearHtlEn = false;
      setRowData(updatedRows);
      setUnsavedChanges(true);

      setHtlEn('');
      state.selOrigin = [];
      setHtlEnEnable(false);
      // setHotelClearEnable(false);
    }

    return (
        <>
        <HeaderComp />
        <Alert className="notification" variant={notiVarient} show={notiMessageShow}
          onClose={() => setNotiMessageShow(false)}
          dismissible> {notiMessage}</Alert>
        <Container fluid>
        <Row>
          <Col xs={6} md={2} className="sideBar">
            <SidebarMenu active="Popular Hotels" />
          </Col>

          <Col xs={12} md={10} className="innerBgcolor">
                <Col className="d-flex justify-content-between align-center mt-4 pl-0 spaceBet">
                    <h6 className="ml-2 mb-0">Most Popular Hotels</h6>
                </Col>
            <Row>

            <div className="tableForm">


            <div className="tableForminner">
                  <Form noValidate validated={validated} id="formId">
                  <div className='formSectionn'>
                    <Row>
                    <Col xs={3}>

                      <Form.Label>Select Country <sup>*</sup></Form.Label>
                      <Form.Group
                      controlId="ctyp"
                      >
                      <Select
                        disabled={enableUpdate}
                        options={countryTypes}
                        searchable={false}
                        values={state.selCountry}
                        onChange={(value) => updateCountry(value)}
                      />
                    </Form.Group>

                    </Col>


                    <Col xs={3}>

                      <Form.Label>Channel Type <sup>*</sup></Form.Label>
                      <Form.Group
                      controlId="ctyp"
                      >
                      <Select
                        disabled={enableUpdate}
                        options={chanelTypes}
                        searchable={false}
                        values={state.selChannel}
                        onChange={(value) => updateChanel(value)}
                      />
                    </Form.Group>

                    </Col>

                    <Col xs={2}>

                    </Col>

                    </Row>
                    </div>


                    <div>
      {rowData.map((row, index) => (
        <div className="mt-4" key={index}>
            <Row>
                <Col xs={3}>
                    <Form.Label>Star Rating <sup>*</sup></Form.Label>
                    <Form.Group>
                    <select
                    className='selectStarrat form-control'
                    name="star_rating"
                    value={row.star_rating}
                    onChange={(event) => handleInputChange(index, event)}
                    >
                    <option value="">Select</option>
                    {numberOptions.map((number) => (
                        <option key={number} value={number}>
                        {number}
                        </option>
                    ))}
                    </select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={3}>
                    <Form.Label>
                    Hotel Name (English) <sup>*</sup>
                    </Form.Label>
                    <Form.Group className='dest-from'>
                    {/* <Form.Control
                        type="text"
                        disabled={state.disabled}
                        onChange={(e) => handleTextChange(index, "hot_en", e.target.value)}
                        value={row.hot_en}
                    ></Form.Control> */}
                    
                    <Form.Control 
                      clear
                      type="text" 
                      disabled={state.disabled}
                      value={row.htl_info?.text?row.htl_info?.text:row.hot_en} 
                      onChange={(e) => onHotelEnChange(index, "hot_en", e.target.value)}                            
                      placeholder="Search..."
                    >
                    </Form.Control>    
                      {index === 0 && (<><span class="focus-border">
                            <i></i>
                        </span>
                        {htlEnEnable && <ul>
                          {!srcPredectiveLoading && !htlEnLoaded && htlEn.length > 2 && <li className="no-match-found">
                          <span><i class="icon icon-Info"></i> Please enter a valid Hotel Name.</span>
                          </li>}
                          {state.htlRowZero!==undefined && state.htlRowZero.length > 0 && state.htlRowZero.map((result) => (
                            <li key={`${result.country} ${result.countryCode} ${result.description} ${result.text}`} onClick={e => onHotelSelect(index,'htl_info',result)}>
                              <div className="airportNamecode-box">
                              <div className="airportNamecode">
                                  <span>{result.description}</span>
                                  <span>{result.countryCode}</span>
                              </div>
                              </div>                                
                            </li>
                          ))}
                      </ul>}
                      {row.clearHtlEn &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearHtlName(index,'htl_info')}/> }
                     </>)}
                      
                      {index === 1 && (<><span class="focus-border">
                      <i></i>
                      </span>
                      {htlEnEnable && <ul>
                      {!srcPredectiveLoading && !htlEnLoaded && htlEn.length > 2 && <li className="no-match-found">
                      <span><i class="icon icon-Info"></i> Please enter a valid Hotel Name.</span>
                      </li>}
                      {state.htlRowOne!==undefined && state.htlRowOne.length > 0 && state.htlRowOne.map((result) => (
                      <li key={`${result.country} ${result.countryCode} ${result.description} ${result.text}`} onClick={e => onHotelSelect(index,'htl_info',result)}>
                        <div className="airportNamecode-box">
                        <div className="airportNamecode">
                            <span>{result.description}</span>
                            <span>{result.countryCode}</span>
                        </div>
                        </div>                                
                      </li>
                      ))}
                      </ul>}
                      {row.clearHtlEn &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearHtlName(index,'htl_info')}>x</Button> }
                       </>)}
                      
                      {index === 2 && (<><span class="focus-border">
                      <i></i>
                      </span>
                      {htlEnEnable && <ul>
                      {!srcPredectiveLoading && !htlEnLoaded && htlEn.length > 2 && <li className="no-match-found">
                      <span><i class="icon icon-Info"></i> Please enter a valid Hotel Name.</span>
                      </li>}
                      {state.htlRowTwo!==undefined && state.htlRowTwo.length > 0 && state.htlRowTwo.map((result) => (
                      <li key={`${result.country} ${result.countryCode} ${result.description} ${result.text}`} onClick={e => onHotelSelect(index,'htl_info',result)}>
                        <div className="airportNamecode-box">
                        <div className="airportNamecode">
                            <span>{result.description}</span>
                            <span>{result.countryCode}</span>
                        </div>
                        </div>                                
                      </li>
                      ))}
                      </ul>} 
                      
                      {row.clearHtlEn &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearHtlName(index,'htl_info')}/> }
                      </>)}
                      
                      {index === 3 && (<><span class="focus-border">
                      <i></i>
                      </span>
                      {htlEnEnable && <ul>
                      {!srcPredectiveLoading && !htlEnLoaded && htlEn.length > 2 && <li className="no-match-found">
                      <span><i class="icon icon-Info"></i> Please enter a valid Hotel Name.</span>
                      </li>}
                      {state.htlRowThree!==undefined && state.htlRowThree.length > 0 && state.htlRowThree.map((result) => (
                      <li key={`${result.country} ${result.countryCode} ${result.description} ${result.text}`} onClick={e => onHotelSelect(index,'htl_info',result)}>
                        <div className="airportNamecode-box">
                        <div className="airportNamecode">
                            <span>{result.description}</span>
                            <span>{result.countryCode}</span>
                        </div>
                        </div>                                
                      </li>
                      ))}
                      </ul>} 
                      
                      {row.clearHtlEn &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearHtlName(index,'htl_info')}/> }
                      </>)}
                      
                      {index === 4 && (<><span class="focus-border">
                      <i></i>
                      </span>
                      {htlEnEnable && <ul>
                      {!srcPredectiveLoading && !htlEnLoaded && htlEn.length > 2 && <li className="no-match-found">
                      <span><i class="icon icon-Info"></i> Please enter a valid Hotel Name.</span>
                      </li>}
                      {state.htlRowFour!==undefined && state.htlRowFour.length > 0 && state.htlRowFour.map((result) => (
                      <li key={`${result.country} ${result.countryCode} ${result.description} ${result.text}`} onClick={e => onHotelSelect(index,'htl_info',result)}>
                        <div className="airportNamecode-box">
                        <div className="airportNamecode">
                            <span>{result.description}</span>
                            <span>{result.countryCode}</span>
                        </div>
                        </div>                                
                      </li>
                      ))}
                      </ul>} 
                      
                      {row.clearHtlEn &&  <Button className="predictiveClear ezyIcon icon-close" onClick={e => clearHtlName(index,'htl_info')}/> }
                        </>)}
                    </Form.Group>
                </Col>
                <Col xs={3}>
                    <Form.Label>
                    Hotel Name(Arabic) <sup>*</sup>
                    </Form.Label>
                    <Form.Group>
                    <Form.Control
                        type="text"
                        disabled={state.disabled}
                        onChange={(e) => handleTextChange(index, "hot_ar", e.target.value)}
                        value={row.hot_ar}                     
                        placeholder="Hotel Name"
                    ></Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={3}>
                    <Form.Group className="mb-3" xs={16} controlId="hot_add_ar">
                      <Form.Label>Address(English)</Form.Label>
                        <textarea
                            className='form-control'
                            value={row.hot_add_en}
                            onChange={(e) =>
                            handleTextChange(index, "hot_add_en", e.target.value)
                            }
                        ></textarea>
                    </Form.Group>
                </Col>
                <Col xs={3}>
                    <Form.Group className="mb-3" xs={16} controlId="hot_add_ar">
                      <Form.Label>Address(Arabic)</Form.Label>
                        <textarea
                            className='form-control'
                            value={row.hot_add_ar}
                            onChange={(e) =>
                            handleTextChange(index, "hot_add_ar", e.target.value)
                            }
                        ></textarea>
                    </Form.Group>
                </Col>
                <Col xs={3}>

                <Form.Group controlId="recImgEn" className="mb-3">
                    <Form.Label>Display Image <sup>*</sup></Form.Label>
                      <ImageUpload reportUrls={row.img_url} sendFileUrl={updateFileName} index={index} />
                        {imgfile && (
                          <p className="vError">Please upload the Image</p>
                        )}
                        {/* <input
                        type="file"
                        accept="image/jpeg, image/png"
                        onChange={(e) => handleImageUpload(index, e.target.files[0])}
                        />
                        <span>JPG or PNG (Dimensions: 480x580)</span> */}
                    {/* {row.img_url && (
                        <div>
                            <img
                                src={row.img_url}
                                alt="Uploaded"
                                width="100"
                                height="100"
                            />
                            <button className="" onClick={() => handleDeleteImage(index)}>×</button>
                            {row.showDeletePopup && (
                                <div>
                                    <Modal
        show={row.showDeletePopup}
        onHide={() => handleClosePopup(false)}
        backdrop="static"
        size="small"
        className="custom-dailog"
        keyboard={false}>
            <Modal.Header>
                <Modal.Title>Delete Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    <div>
                    <Form.Label className="custom-dailog-title">Are you sure you want to delete selected Image ?</Form.Label>
                    </div>

                                <Button onClick={() => handleClosePopup(index, true)}
                                    size="xs"
                                    variant="outline-primary"
                                    // disabled = {isLoading && pleasewait ? true : false }
                                    >
                                    Yes
                                </Button>
                        <Button
                            size="xs"
                            variant="outline-dark"
                            onClick={() => handleClosePopup(index, false)}
                            type="submit"> No </Button>
                </div>
             </Modal.Body>
    </Modal>
                                </div>
                            )}
                        </div>
                    )} */}
                    </Form.Group>
                </Col>
            </Row>
        </div>
      ))}
      {rowData.length < 8 ? <Button className="plusBtn popuHotell" onClick={handleAddRow}><span>+</span></Button> : null}

    </div>


                    <hr />

                    <Row>
                        <Col>
                        {/* <Form.Label>Status</Form.Label> */}
                        <Form.Group as={Col} className="topSwitch">
                            <Form.Check
                            type="switch"
                            id="act"
                            label="Status"
                            checked={act}
                            value={act}
                            onChange={handleChange}
                            />
                        </Form.Group>
                        </Col>


                    <Row className="submitBtn">
                      <Col className="d-flex">
                        {!enableUpdate && <Button type="button" variant="primary" size="sm" className="mr-3" onClick={handleCancel}>Cancel</Button>}


                        {cancelConfirmation && (
                            <div className="confirmation-popup">
                                <Modal
        show={cancelConfirmation}
        onHide={() => handleClosePopup(false)}
        backdrop="static"
        size="small"
        className="custom-dailog alignDelc"
        keyboard={false}>
            {/* <Modal.Header>
                <Modal.Title>Delete Image</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <div className="">
                    <div>
                    <Form.Label className="custom-dailog-title">Any unsaved data will be lost. Click OK to continue.</Form.Label>
                    </div>

                                <Button onClick={() => handleCancelConfirmation(true)}
                                    size="xs"
                                    variant="outline-primary"
                                    className='mr-2'
                                    // disabled = {isLoading && pleasewait ? true : false }
                                    >
                                    OK
                                </Button>
                        <Button
                            size="xs"
                            variant="outline-dark"
                            onClick={() => handleCancelConfirmation(false)}
                            type="submit"> Cancel </Button>
                </div>
             </Modal.Body>
    </Modal>
                            </div>
                        )}


                        {!enableUpdate && <Button className="saveBttn" type="submit" variant="primary" size="sm" onClick={handlePopularHotelsCreation}><span>Save</span></Button>
                        }
                        {enableUpdate && <Button type="submit" variant="primary" size="sm" onClick={handlePopularHotelsUpdation}>Update</Button>}
                      </Col>
                    </Row>
                    </Row>

                    </Form>
                    </div>



            </div>
            <div className='tableForm mt-3'>
            <div className="bodyContent">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbll taFeeq popolHotl"
                  data={res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={res.length >10 && res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  res.length >25 && res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                {res.length === 0 &&
                 <div className="norecord">
                 <strong>No Record Found.</strong>
                 </div>
                }
            </div>
            </div>

            </Row>
          </Col>
        </Row>
    </Container>

    <FooterComp />
    </>
    );
}

export default Popularhotels;