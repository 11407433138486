import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Modal, Alert, Button, Nav, Table, Footer } from "react-bootstrap";
import HeaderComp from '../common/header';
import FooterComp from '../common/Footer';
import Tabs from '../common/Tabs';
import Create from './Create';
import Locations from './Locations';
import Events from './Events';

function ItinararyTabs(props) {

    const content = {
        Itinarary: <Create />,
        Location: <Locations />,
        Event: <Events />,

    };
    const [tab, setTab] = useState({
        active: 'Itinarary'
    })

    return (
        <>
            <HeaderComp />
            <Container fluid>
                <div className="submenu">

                       <div className="tabLink notification-tablink">
                            <Tabs active={tab.active} onChange={active => setTab({ active })}>
                                <span key="Itinarary">Itinararies</span>
                                <span key="Location">Locations</span>
                                <span key="Event">Events</span>
                            </Tabs>
                            <div className="tabContent">
                                {content[tab.active]}
                            </div>
                        </div>
                        </div>

                        </Container>
                        <FooterComp />


        </>

    )
}


export default ItinararyTabs
