import React, { useState, useEffect,useReducer } from 'react';
import { Container, Form, Row, Col, Modal, Alert, Button, Nav, Table, Footer } from "react-bootstrap";
import FileUpload from '../common/FileUpload';
import TextEditor from '../common/TextEditor';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Select from "react-dropdown-select";
import { saveEvent, updateEvent , getEvents } from './operation';
import { FaEdit } from "react-icons/fa";
import SidebarMenu from '../common/sidebar';
/**
 * Initial State Declaration
 */
 const initialState = {_id: "",channel_type:"",url:"",title_en:"",title_ar:"",description_en:"",description_ar:"" }
    // Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
          ...state, categoryName: ''
        };
      case 'editEve':
        return Object.assign(state, action.payload);
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

function Events(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [notiMessage, setNotiMessage] = useState('');
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [validated, setValidated] = useState(false);
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [res, setRes] = useState([]);
  const [chanelTypes, setChannelTypes] = useState([{value:"Saudi Arabia",label:"Saudi Arabia"},{value:"United Arab Emirates",label:"United Arab Emirates"},{value:"Qatar",label:"Qatar"}]);


  useEffect(() => {
    getEvents({"type":"ALL"}).then(response => {
      console.log('Package response', response)
      if (response.data.suc &&  response.data.res) {
        convertPackageData(response.data.res)
      }
    }).catch(function (error) {
      console.log('Package response error ', error)
    });
  }, [])

  const updateChanel=(obj)=>{
    dispatch({ type: 'channel_type', payload: obj[0].value })
    dispatch({ type: 'selChannel', payload: obj })
  }


    const handleChange = (e) => {
        const { id, value } = e.target;
        dispatch({ type: id, payload: value });
        console.log("checked type:::::", e.target.id);
      };

      function hidemessage() {
        setTimeout(function () {
            setNotiMessageShow(false);
            setNotiMessage('');
            setNotiVarient('')
        }, 3000);
      }

    const validateEvent=()=>{
        if(state.channel_type===""){
            setNotiMessageShow(true);
            setNotiMessage('Select Channel Type');
            setNotiVarient('danger')
            hidemessage();
            return false;
        }else if(state.url===""){
            setNotiMessageShow(true);
            setNotiMessage('Please upload the Image');
            setNotiVarient('danger')
            hidemessage();
            return false;
        }else if(state.title_en===""){
            setNotiMessageShow(true);
            setNotiMessage('Please Enter the Title');
            setNotiVarient('danger')
            hidemessage();
            return false;
        }else if(state.description_en===""){
            setNotiMessageShow(true);
            setNotiMessage('Please Enter the Description');
            setNotiVarient('danger')
            hidemessage();
            return false;
        }
        return true;
    }

    const saveEvents=(type)=>(event)=>{
        event.preventDefault();
        if(validateEvent()){
        if(type==="save"){
         state.created_by=1;
         state.active=true;
         saveEvent(state).then(response => {
             console.log("Location Save response " + JSON.stringify(response.data));
             if (response.data.suc) {
               window.scrollTo(0, 0);
               setNotiMessageShow(true);
               setNotiMessage('Event details are saved successfully...');
               setNotiVarient('success')
               hidemessage();
               setTimeout(function () {
                window.location="/cms/create-itinarary";
              }, 2000);
             } else {
               setNotiMessageShow(true);
               setNotiMessage('Event details not saved');
               setNotiVarient('danger')
               hidemessage();
             }
           });
        }else{
         state.updated_by=1;
         updateEvent(state).then(response => {
             console.log("Itinarary Save response " + JSON.stringify(response.data));
             if (response.data.suc) {
               window.scrollTo(0, 0);
               setNotiMessageShow(true);
               setNotiMessage('Event details are updated successfully...');
               setNotiVarient('success')
               hidemessage();
               setTimeout(function () {
                window.location="/cms/create-itinarary";
              }, 2000);
             } else {
               setNotiMessageShow(true);
               setNotiMessage('Event details not updated');
               setNotiVarient('danger')
               hidemessage();
             }
           });
        }
        }
       }

       const handleChangeActive = (obj,resp) => (e) => {
        let isActive=false;
        if(!obj.active){
         isActive=true;
         obj.active=true
        }else{
        obj.active=false;
        }
        updateEvent(obj).then(response => {
             if(response.data.suc){
              setNotiMessageShow(true);
              setNotiMessage("Location Status updated Successfully...");
              setNotiVarient('success')
              hidemessage();
             for(let val of resp){
               if(val._id===obj._id){
                  val.active=isActive;
                  break;
               }
             }
             convertPackageData(resp)
             }
         })
     }

       const convertPackageData=(response)=>{
        let data = [];
        let index=1;
         for(let value of response){
          data.push({ pname: value.title_en,channel_type:value.channel_type,
            options: <><span className="tbAction">
              <a className="ezyIcon icon-edit" onClick={()=>editEvent(value)}><span>Edit</span></a></span>
            <div className='custom-switch'>
            <Form.Group className="d-flex align-items-center m-0" controlId="is_active">
               <Form.Check  className="p-2"
                checked={value.active}
               type="checkbox"
               label=""
               value={value.active}
               onChange={handleChangeActive(value,response)}
               id={index+"pkg"+value.id}
               custom
                />
               </Form.Group>
               </div> </> })
               index=index+1;
           }
           setRes(data)
      }

      const editEvent=(obj)=>{
        dispatch({ type: 'editEve', payload: obj })
        dispatch({ type: 'selChannel', payload: [{value:obj.channel_type,label:obj.channel_type}] })
        setEnableUpdate(true)
      }

      const columns = [{
        dataField: 'pname',
        text: 'Title Name',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'channel_type',
        text: 'Channel Type',
        sort: true,
        filter: textFilter()
      },
      {
        dataField: 'options',
        text: 'Actions',
        class: 'tes'
      }];

      const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
      }];


      const updateFileName = (url) => {
        dispatch({ type: "url", payload: url });
      }

      const updateDescEn = (data) => {
        dispatch({ type: 'description_en', payload: data[0] })
      }

      const updateDescAr = (data) => {
        dispatch({ type: 'description_ar', payload: data[0] })
      }

    return (
        <>
        <Alert className="notification" variant={notiVarient} show={notiMessageShow}
          onClose={() => setNotiMessageShow(false)}
          dismissible> {notiMessage}</Alert>
        <Row>
        <Col xs={6} md={2} className="sideBar">
            <SidebarMenu active="Itinararies" />
          </Col>
          <Col xs={12} md={10} className="innerBgcolor">
            <Row>
              <div className="tableForm">
                <Col className="d-flex justify-content-between align-center mt-4 mb-4 pl-0 spaceBet">
                  <h6 className="m-0">Events</h6>
                </Col>
                <div className="tableForminner">
                  <Form noValidate validated={validated} id="formId">
                  <Row>
                    <Col xs={3}>

                      <Form.Label>Channel Type : <sup>*</sup></Form.Label>
                      <Form.Group
                       controlId="ctyp"
                      >
                      <Select
                        disabled={enableUpdate}
                        options={chanelTypes}
                        searchable={false}
                        values={state.selChannel}
                        onChange={(value) => updateChanel(value)}
                      />
                    </Form.Group>

                    </Col>
                    </Row>
                  <Row>
                  <Col xs={3}>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                          <FileUpload reportUrls={state.url} sendFileUrl={updateFileName} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3}>
                        <Form.Label>Title in English<sup>*</sup></Form.Label>
                        <Form.Group controlId="title_en">
                          <Form.Control
                            required
                            type="text"
                            maxLength="30"
                            autoComplete="off"
                            placeholder="Enter Title in English"
                            value={state.title_en}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={3}>
                        <Form.Label>Title in Arabic</Form.Label>
                        <Form.Group controlId="title_ar">
                          <Form.Control
                            required
                            type="text"
                            maxLength="30"
                            placeholder="Enter Title in Arabic"
                            value={state.title_ar}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                    <Col xs={6}>
                        <Form.Group as={Col} xs={16} controlId="description_en">
                          <Form.Label>Description English<sup>*</sup></Form.Label>
                          <TextEditor textType={"Desc_EN"} sendDescEn={updateDescEn} enableUpdate={enableUpdate} updateDescEn={state.description_en} />

                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className="mb-3" xs={16} controlId="description_ar">
                          <Form.Label>Description Arabic</Form.Label>
                          <TextEditor textType={"Desc_AR"} sendDescAr={updateDescAr} enableUpdate={enableUpdate} updateDescAr={state.description_ar} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="submitBtn">
                      <Col className="d-flex">
                        {!enableUpdate && <Button className="saveBttn" type="submit" variant="primary" size="sm" onClick={saveEvents("save")}><span>Save</span></Button>
                        }
                        {enableUpdate && <Button type="submit" variant="primary" size="sm" onClick={saveEvents("update")}>Update</Button>}
                      </Col>
                    </Row>

                  </Form>
                </div>
                <div className="bodyContent">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup taFeeq filterTbl"
                  data={res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={res.length >10 && res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  res.length >25 && res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                {res.length === 0 &&
                 <div className="norecord">
                 <strong>No Record Found.</strong>
                 </div>
                }
            </div>
              </div>
            </Row>
          </Col>
        </Row>
        </>
    )
}


export default Events
