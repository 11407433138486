import React, { useState, useEffect, useReducer } from 'react';
import { Container, Form, Row, Col, Modal, Alert, Button, Nav } from "react-bootstrap";
import HeaderComp from '../common/header';
import FooterComp from '../common/Footer';
import Select from "react-dropdown-select";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getHighInDemand, saveHighInDemand, updateHighInDemand } from './operation';
import { FaEdit } from "react-icons/fa"
import ImageUpload from './ImageUpload';
import ImageUploadPopupGallery from './ImageUploadPopupGallery';
import VideoUploadPopupGallery from './VideoUploadPopupGallery';
import SidebarMenu from '../common/sidebar';

/**
 * Initial State Declaration
 */
const initialState = {
    _id: "", channel_type:"", country:"", city_country_en: "", city_country_ar: "",  active: false, selChannel:[], selCountry:[], Emailid: ""}


  // Reducer function for useReact Hook which will update the state
  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
          ...state, id: "", channel_type:"", country:"",  city_country_en: "", city_country_ar: "", active: false, Emailid: ""
        };
      case 'editHighInDemand':
        return Object.assign(state, action.payload);
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

/**
 * @description:HighInDemand Screen
 * @author: Ubedullah
 * @param {*}
 * @function HighInDemand - CMS
 * @date : 05-15-2023
 */

const HighInDemand = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [notiMessage, setNotiMessage] = useState('');
    const [notiMessageShow, setNotiMessageShow] = useState(false);
    const [notiVarient, setNotiVarient] = useState();
    const [validated, setValidated] = useState(false);
    const [enableUpdate, setEnableUpdate] = useState(false);
    const [countryTypes, setCountryTypes] = useState([{value:"India",label:"India"},{value:"Saudi Arabia",label:"Saudi Arabia"},{value:"United Arab Emirates",label:"United Arab Emirates"},{value:"Qatar",label:"Qatar"},{value:"Oman",label:"Oman"}]);
    const [chanelTypes, setChannelTypes] = useState([{value:"B2B",label:"B2B"},{value:"B2C",label:"B2C"}]);
    const [imgfile, setImgFile] = useState(false);
    const [act, setAct] = useState(true);
    const [days, setDays] = useState(0);
    const [nights, setNights] = useState(0);
    const [res, setRes] = useState([]);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [cancelConfirmation, setCancelConfirmation] = useState(false);

    // Popup Gallery starts
    const [selectedFile, setSelectedFile] = useState(null);
    const [activeTab, setActiveTab] = useState("video");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [imageURL, setImageURL] = useState('');
    const [videoURL, setVideoURL] = useState('');
    // Popup Gallery ends

    const [index, setIndex] = useState(0);

    useEffect(() => {
        getHighInDemand({"type":"ALL"}).then(response => {
          console.log('HighinDemand response', response)
          if (response.data.suc && response.data.res) {
            convertHighInDemandData(response.data.res)
          }
        }).catch(function (error) {
          console.log('HighinDemand response error ', error)
        });
      }, [])


/**
 * @description:HighInDemand Screen Edit functionality
 * @author: Ubedullah
 * @param {*}
 * @function editHighInDemand - CMS
 * @date : 05-15-2023
 */

  const editHighInDemand=(obj)=>{
    const dateFormat = obj.packages_list.map((obj) => {
      return obj
    })
    const dateFormat2 = dateFormat.map((data) => {
      return {...data, expiry_date:data.expiry_date.slice(0,10)}
    })
    dispatch({ type: 'editHighInDemand', payload: obj })
    dispatch({ type: 'selChannel', payload: [{value:obj.channel,label:obj.channel}] })
    dispatch({ type: 'selCountry', payload: [{value:obj.region,label:obj.region}] })
    dispatch({ type: 'Emailid', payload: obj.email })
    setRowData(dateFormat2);
    setAct(obj.status === "Active" ? true : false)
    setEnableUpdate(true)
  }

  /**
 * @description:HighInDemand Screen display UI in Table
 * @author: Ubedullah
 * @param {*}
 * @function convertHighInDemandData - CMS
 * @date : 05-15-2023
 */

  const columns = [{
    dataField: 'country',
    text: 'Country Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'cha_type',
    text: 'Channel Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status'
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];


  const convertHighInDemandData=(response)=>{
    let data = [];
    let index=1;
     for(let value of response){
      data.push({ country: value.region,cha_type:value.channel, status: value.status,
      options: <><span className="tbAction"><a className="ezyIcon icon-edit" onClick={()=>editHighInDemand(value)}> <span>Edit</span></a></span>
      </> })
         index=index+1;
     }
     setRes(data)
  }

    const updateChanel=(obj)=>{
        dispatch({ type: 'channel_type', payload: obj[0].value })
        dispatch({ type: 'selChannel', payload: obj })
        setUnsavedChanges(true);
    }
    const updateCountry=(obj)=>{
        dispatch({ type: 'country', payload: obj[0].value })
        dispatch({ type: 'selCountry', payload: obj })
        setUnsavedChanges(true);
    }

    const updateFileName = (index, url) => {
      const updatedRows = [...rowData];
      updatedRows[index].url_img = url;
      setRowData(updatedRows);
      setUnsavedChanges(true);
    }

    const updateImageFileGallery = (index, url) => {
      const updatedRows = [...rowData];
      updatedRows[index].url = url;
      setRowData(updatedRows);
      setUnsavedChanges(true);
    }

    const updateVideoFileGallery = (index, url) => {
      const updatedRows = [...rowData];
      updatedRows[index].video = url;
      setRowData(updatedRows);
      setUnsavedChanges(true);
    }

    const handleChange = (e) => {
      const { id, value } = e.target;
      if (id === 'act') {
          setAct(e.target.checked)
      }
      console.log("checked type:::::", e.target.id);
      setUnsavedChanges(true);
    };

    const handleEmail = (e) => {
        dispatch({
        type: "Emailid",
        payload: e.target.value.toString(),
        });
        setUnsavedChanges(true);
    };


  /**
 * @description:HighInDemand Screen Hiding success and Error validation message
 * @author: Ubedullah
 * @param {*}
 * @function hidemessage
 * @date : 05-15-2023
 */

  const hidemessage=() =>{
    setTimeout(function () {
        setNotiMessageShow(false);
        setNotiMessage('');
        setNotiVarient('');
    }, 4000);
  }

  /**
 * @description:HighInDemand Screen Validating Form
 * @author: Ubedullah
 * @param {*}
 * @function Validating Form
 * @date : 05-15-2023
 */

  const validateHighInDemand = () => {
    setImgFile(false)
    if(state.country===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Select Country');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(state.channel_type===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Select Channel Type');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(state.Emailid===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Enter Email Id');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }
    if(rowData.length!==0){
     for(let val of rowData){
      if(val.city_country_en===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter City & Country(English)');
        setNotiVarient('danger')
        hidemessage();
        return false;
      }if(val.city_country_ar===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Enter City & Country(Arabic)');
        setNotiVarient('danger')
        hidemessage();
        return false;
      }if(val.days===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Select No. Of Days');
        setNotiVarient('danger')
        hidemessage();
        return false;
      }else if(val.nights===""){
        setNotiMessageShow(true);
        setNotiMessage('Please Select No. Of Nights');
        setNotiVarient('danger')
        hidemessage();
        return false;
      }
      if(val.url_img===null){
        setNotiMessageShow(true);
        setNotiMessage('Please Upload Display Image');
        setNotiVarient('danger')
        hidemessage();
        return false;
      }
      if(val.expiry_date==""){
        setNotiMessageShow(true);
        setNotiMessage('Please Select Expiry Date');
        setNotiVarient('danger')
        hidemessage();
        return false;
      }
      // if(val.video===null || val.images.length===0){
      //   setNotiMessageShow(true);
      //   setNotiMessage('Please Upload Popup Gallery Video or Images');
      //   setNotiVarient('danger')
      //   hidemessage();
      //   return false;
      // }
      if(rowData.length < 5) {
        setNotiMessageShow(true);
        setNotiMessage('It is mandatory to add Five High In Demand');
        setNotiVarient('danger')
        hidemessage();
        return false;
        }
     }
    }
    return true;
  }

  /**
   * @description:High In Demand Screen Creation(save) data
   * @author: Ubedullah
   * @param {*}
   * @function handleHighInDemandCreation - CMS
   * @date : 05-15-2023
   */

const handleHighInDemandCreation = (event) => {
    event.preventDefault();
    if (validateHighInDemand()) {
      let suc = true;
      for (let val of res) {
        if (val.country?.toLowerCase() == state.country?.toLowerCase()) {
          if (
            val.country?.toLowerCase() == state.country?.toLowerCase() &&
            val.cha_type?.toLowerCase() == state.channel_type?.toLowerCase()
          ) {
            suc = false;
          }
        }
      }
      if(suc) {
        rowData.forEach(object => {
            delete object['id'];
            delete object['showDeletePopup'];
            delete object['showVideo'];
            delete object['showImages'];
        });
        const payload = {
            "region": state.country,
            "channel": state.channel_type,
            "email": state.Emailid,
            "status": act == true ? "Active" : "In-Active",
            "packages_list": rowData,
            "created_by": 1,
        }
        saveHighInDemand(payload).then(response => {
          console.log("High In Demand Save response " + JSON.stringify(response.data));
          if (response.data.suc) {
            window.scrollTo(0, 0);
            setNotiMessageShow(true);
            setNotiMessage('High In Demand are saved successfully...');
            setNotiVarient('success')
            setTimeout(function () {
              window.location="/cms/high-in-demand";
            }, 2000);

          } else {
            setNotiMessageShow(true);
            setNotiMessage('High In Demand details not saved');
            setNotiVarient('danger');
            hidemessage();
          }
        });
      } else {
        setNotiMessageShow(true);
        setNotiMessage('Country with saleschannel already exists');
        setNotiVarient('danger');
        hidemessage();
        return false;
      }
      }
    }

    /**
     * @description:High In Demand Screen Updating(update) data
     * @author: Ubedullah
     * @param {*}
     * @function handleHighInDemandUpdation - CMS
     * @date : 05-15-2023
     */

    const handleHighInDemandUpdation = (event) => {
        event.preventDefault();
        if (validateHighInDemand()) {
            const status = act === true ? "Active" : "In-Active"
            state.packages_list = rowData;
            state.updated_by = 1;
            state.status = status
            updateHighInDemand(state).then(response => {
            console.log("High in Demand Save response " + JSON.stringify(response.data));
            if (response.data.suc) {
              window.scrollTo(0, 0);
              setNotiMessageShow(true);
              setNotiMessage('High In Demand are updated successfully...');
              setNotiVarient('success')
              setTimeout(function () {
                window.location="/cms/high-in-demand";
              }, 2000);
            } else {
              setNotiMessageShow(true);
              setNotiMessage('High In Demand are not Updated');
              setNotiVarient('danger')
              hidemessage();
            }
          });
        }
    }

    /**
     * @description:High In Demand Screen Image Rows Functionality (ADD)
     * @author: Ubedullah
     * @param {*}
     * @date : 23-05-2023
     */

    const [rowCount, setRowCount] = useState(1);
    const [rowData, setRowData] = useState([
    {
      id: 0,
      city_country_en: "",
      city_country_ar: "",
      days: "",
      nights : "",
      url_img: null,
      expiry_date: "",

      video: null,
      images: [],
      showVideo: true,
      showImages: false,

      showDeletePopup: false,
      is_emailed:false,
      mailed_date:null,
      index: 0
    }
  ]);

  const handleAddRow = () => {
    setIndex(index + 1)
    setRowCount(rowCount + 1);
    setRowData([
      ...rowData,
      {
        id: rowData.length + 1,
        city_country_en: "",
        city_country_ar: "",
        days: "",
        nights : "",
        url_img: null,
        expiry_date: "",

        video: null,
        images: [],
        showVideo: true,
        showImages: false,
        isDeletingVideo: false,

        showDeletePopup: false,
        is_emailed:false,
        mailed_date:null,
        index: index + 1
      }
    ]);
  };

  const handleTextChange = (index, field, value) => {
    const updatedRowData = [...rowData];
    updatedRowData[index][field] = value;
    updatedRowData[index].is_emailed = false;
    updatedRowData[index].mail_updated_dt = false;
    updatedRowData[index].mailed_date = null;
    setRowData(updatedRowData);
    setUnsavedChanges(true);
  };

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = now.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRowData = [...rowData];
    updatedRowData[index] = {
      ...updatedRowData[index],
      [name]: value
    };
    setRowData(updatedRowData);
    setUnsavedChanges(true);
  };

  const handleClosePopup = (index, confirmDelete) => {
    const updatedRowData = [...rowData];
    updatedRowData[index].showDeletePopup = false;

    if (confirmDelete) {
      updatedRowData[index].url_img = null;
    }

    setRowData(updatedRowData);
  };


  // Arrays of Days and Nights dropdown Options
  const daysOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const nightOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleCancel = () => {
    if (unsavedChanges) {
      setCancelConfirmation(true);
    } else {
      if(state.channel_type !== "" || state.country !== "") {
        dispatch({ type: 'channel_type', payload: "" })
        dispatch({ type: 'country', payload: "" })
      }
      dispatch({ type: "Emailid", payload: "" })
        setRowData([{
            city_country_en: "",
            city_country_ar: "",
            days: "",
            nights : "",
            url_img: null,
            expiry_date: "",
            showDeletePopup: false,
            video: null,
            images: [],
            showVideo: true,
            showImages: false,
            isDeletingVideo: false,
            id: 0
          }]);
        setAct(true)
    }
  };

  const handleCancelConfirmation = (confirmed) => {
    if (confirmed) {
      if(state.channel_type !== "" || state.country !== "") {
        dispatch({ type: 'channel_type', payload: "" })
        dispatch({ type: 'selChannel', payload: [{value:"", label: ""}] })
        dispatch({ type: 'country', payload: "" })
        dispatch({ type: 'selCountry', payload: [{value:"",label:""}] })
      }
      dispatch({ type: "Emailid", payload: "" })
      setRowData([{
        city_country_en: "",
        city_country_ar: "",
        days: "",
        nights : "",
        url_img: null,
        expiry_date: "",
        showDeletePopup: false,
        video: null,
        images: [],
        showVideo: true,
        showImages: false,
        isDeletingVideo: false,
        id: 0
      }]);
      setAct(true)
      setUnsavedChanges(false);
    }
    setCancelConfirmation(false);
  };


  // Popup Gallery starts

  const handleVideoUpload = (e, id) => {
    const updatedRows = rowData.map((row) => {
      if (row.id === id) {
        return { ...row, video: e.target.files[0] };
      }
      return row;
    });
    setRowData(updatedRows);
    setUnsavedChanges(true);
  };

  const handleImagesUpload = (e, id) => {
    const updatedRows = rowData.map((row) => {
      if (row.id === id) {
        return { ...row, images: Array.from(e.target.files) };
      }
      return row;
    });
    setRowData(updatedRows);
    setUnsavedChanges(true);
  };

  const handleCheckboxChange = (e, id, field) => {
    const updatedRows = rowData.map((row) => {
      if (row.id === id) {
        if (field === "showImages") {
          return {
            ...row,
            showImages: e.target.checked,
            showVideo: !e.target.checked
          };
        } else if (field === "showVideo") {
          return {
            ...row,
            showVideo: e.target.checked,
            showImages: !e.target.checked
          };
        }
      }
      return row;
    });
    setRowData(updatedRows);
    setUnsavedChanges(true);
  };

  const handleDeleteVideo = (id) => {
    const updatedRows = rowData.map((row) => {
      if (row.id === id) {
        return { ...row, video: null, isDeletingVideo: false };
      }
      return row;
    });
    setRowData(updatedRows);
    setUnsavedChanges(true);
  };

  const handleOpenDeleteVideoConfirmation = (id) => {
    const updatedRows = rowData.map((row) => {
      if (row.id === id) {
        return { ...row, isDeletingVideo: true };
      }
      return row;
    });
    setRowData(updatedRows);
  };

  const handleCancelDeleteVideo = (id) => {
    const updatedRows = rowData.map((row) => {
      if (row.id === id) {
        return { ...row, isDeletingVideo: false };
      }
      return row;
    });
    setRowData(updatedRows);
  };
  // Popup Gallery ends

    return (
        <>
        <HeaderComp />
        <Alert className="notification" variant={notiVarient} show={notiMessageShow}
          onClose={() => setNotiMessageShow(false)}
          dismissible> {notiMessage}</Alert>
        <Container fluid>
        <Row>
          <Col xs={6} md={2} className="sideBar">
            <SidebarMenu active="High In Demand" />
          </Col>

          <Col xs={12} md={10} className="innerBgcolor">
                <Col className="d-flex justify-content-between align-center mt-4 pl-0 spaceBet">
                    <h6 className="ml-2 mb-0">High In Demand</h6>
                </Col>
            <Row>

            <div className="tableForm">


            <div className="tableForminner">
                  <Form noValidate validated={validated} id="formId">

                    <Row>
                    <Col xs={3}>

                      <Form.Label>Select Country <sup>*</sup></Form.Label>
                      <Form.Group
                      controlId="ctyp"
                      >
                      <Select
                        disabled={enableUpdate}
                        options={countryTypes}
                        searchable={false}
                        values={state.selCountry}
                        onChange={(value) => updateCountry(value)}
                      />
                    </Form.Group>

                    </Col>


                    <Col xs={3}>

                      <Form.Label>Channel Type <sup>*</sup></Form.Label>
                      <Form.Group
                      controlId="ctyp"
                      >
                      <Select
                        disabled={enableUpdate}
                        options={chanelTypes}
                        searchable={false}
                        values={state.selChannel}
                        onChange={(value) => updateChanel(value)}
                      />
                    </Form.Group>
                    </Col>
                    <Col xs={3}>
                  <Form.Label>
                    Email id <sup>*</sup>
                  </Form.Label>
                <Form.Group controlId="Emailid">
                  <Form.Control
                    type="email"
                    disabled={state.disabled}
                    placeholder='Enter Email Id'
                    onChange={handleEmail}
                    value={state.Emailid}
                  ></Form.Control>
                </Form.Group>

                    </Col>
                    </Row>

                    <div>
      {rowData.map((row, index) => (
        <div key={index}>
            <Row>
                <Col xs={3}>
                    <Form.Label>
                    City & Country Name(English) <sup>*</sup>
                    </Form.Label>
                    <Form.Group>
                    <Form.Control
                        type="text"
                        disabled={state.disabled}
                        placeholder='Eg : Doha, Qatar'
                        onChange={(e) => handleTextChange(index, "city_country_en", e.target.value)}
                        value={row.city_country_en}
                    ></Form.Control>
                    </Form.Group>
                </Col>
                <Col xs={3}>
                    <Form.Label>
                    City & Country Name(Arabic) <sup>*</sup>
                    </Form.Label>
                    <Form.Group>
                    <Form.Control
                        type="text"
                        disabled={state.disabled}
                        placeholder=""
                        onChange={(e) => handleTextChange(index, "city_country_ar", e.target.value)}
                        value={row.city_country_ar}
                    ></Form.Control>
                    </Form.Group>
                </Col>

                <Col xs={2}>
                    <Form.Label>
                    Expires on <sup>*</sup>
                    </Form.Label>
                    <Form.Group>
                    <Form.Control
                        type="date"
                        disabled={state.disabled}
                        onChange={(e) => handleTextChange(index, "expiry_date", e.target.value)}
                        value={row.expiry_date}
                        min={getCurrentDate()}
                    ></Form.Control>
                    </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col xs={2}>
                    <Form.Label>No. Of Days <sup>*</sup></Form.Label>
                    <Form.Group>
                    <select
                    name="days"
                    className='noOffday'
                    value={row.days}
                    onChange={(event) => handleInputChange(index, event)}
                    >
                    <option value="">Select</option>
                    {daysOptions.map((number) => (
                        <option key={number} value={number}>
                        {number}
                        </option>
                    ))}
                    </select>
                    </Form.Group>
                </Col>

                <Col xs={2}>
                    <Form.Label>No. Of Nights <sup>*</sup></Form.Label>
                    <Form.Group>
                    <select
                    name="nights"
                    className='noOffday'
                    value={row.nights}
                    onChange={(event) => handleInputChange(index, event)}
                    >
                    <option value="">Select</option>
                    {nightOptions.map((number) => (
                        <option key={number} value={number}>
                        {number}
                        </option>
                    ))}
                    </select>
                    </Form.Group>
                </Col>

                <Col xs={3}>
                <Form.Group controlId="recImgEn" className="mb-3">
                    <Form.Label>Display Image(English) <sup>*</sup></Form.Label>
                    <ImageUpload reportUrls={row.url_img} sendFileUrl={updateFileName} index={index} />
                        {imgfile && (
                          <p className="vError">Please upload the Image</p>
                        )}
                    </Form.Group>
                </Col>

            </Row>


         {/* Popup gallery starts */}
          {/* <Row>
                   <Col>
            <div className='showRadiobtn d-flex'>
            <h6 className="mb-3">Popup Gallery:</h6>
<div className='d-flex showVideo'>
              <input
                type="radio"
                checked={row.showVideo}
                onChange={(e) => handleCheckboxChange(e, row.id, "showVideo")}
              />
               <label>Video
            </label>
            </div>
            <div className='d-flex showVideo'>
              <input
                type="radio"
                checked={row.showImages}
                onChange={(e) => handleCheckboxChange(e, row.id, "showImages")}
              />
               <label> Images
            </label>
            </div>
          </div>

            </Col>
          </Row> */}
            <Row>
              <Col xs={3}>
                   {row.showVideo && (
            <div>
              {/* <input
              className='addlogo'
                type="file"
                accept="video/*"
                onChange={(e) => handleVideoUpload(e, row.id)}
              /> */}
              {/* <VideoUploadPopupGallery reportUrls={row.video} sendFileUrl={updateVideoFileGallery} index={index} /> */}
              {row.video && (
                <div>
                  <video
                    src={URL.createObjectURL(row.video)}
                    style={{ width: "100%" }}
                    controls
                  />
                  {!row.isDeletingVideo ? (
                    <span
                      onClick={() => handleOpenDeleteVideoConfirmation(row.id)}
                    >
                      Delete Video
                    </span>
                  ) : (
                    <div>
                          <Modal
                            show={row.isDeletingVideo}
                            onHide={() => handleClosePopup(false)}
                            backdrop="static"
                            size="small"
                            className="custom-dailog"
                            keyboard={false}>
                              <Modal.Header>
                                  <Modal.Title>Delete Image</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                  <div className="">
                                      <div>
                                      <Form.Label className="custom-dailog-title">
                                      Are you sure you want to delete the video?</Form.Label>
                                      </div>

                                        <Button onClick={() => handleDeleteVideo(row.id)}
                                            size="xs"
                                            variant="outline-primary"
                                            >
                                            Yes
                                        </Button>
                                <Button
                                    size="xs"
                                    variant="outline-dark"
                                    onClick={() => handleCancelDeleteVideo(row.id)}
                                    type="submit"> No </Button>
                              </div>
                          </Modal.Body>
                        </Modal>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {row.showImages && (
            <div>
              <ImageUploadPopupGallery reportUrls={row.url} sendFileUrl={updateImageFileGallery} index={index} />
              {row.images.length > 0 && (
                <div>
                  {row.images.map((image) => (
                    <img
                      key={image.name}
                      src={URL.createObjectURL(image)}
                      alt={image.name}
                      width={100}
                    />
                  ))}
                </div>
              )}
            </div>
          )}

              </Col>
            </Row>
        </div>
      ))}

      {rowData.length < 5 ? <Button onClick={handleAddRow}>Add More Packages</Button> : null}
    </div>
                               <br />
                                <hr />
                                <br />
    {/* // Popup Gallery ends */}


                    <hr />

                    <Row>
                        <Col>
                        {/* <Form.Label>Status</Form.Label> */}
                        <Form.Group as={Col} className="topSwitch">
                            <Form.Check
                            type="switch"
                            id="act"
                            label="Status"
                            checked={act}
                            value={act}
                            onChange={handleChange}
                            />
                        </Form.Group>
                        </Col>


                    <Row className="submitBtn">
                      <Col className="d-flex">
                        {!enableUpdate && <Button type="button" variant="primary" size="sm" className="mr-3" onClick={handleCancel}>Cancel</Button>}


                        {cancelConfirmation && (
                            <div className="confirmation-popup">
                                <Modal
        show={cancelConfirmation}
        onHide={() => handleClosePopup(false)}
        backdrop="static"
        size="small"
        className="custom-dailog alignDelc"
        keyboard={false}>
            {/* <Modal.Header>
                <Modal.Title>Delete Image</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <div className="d-flex popCancel">
                                        <Form.Label className="custom-dailog-title">Any unsaved data will be lost. Click OK to continue.</Form.Label>

<div className='d-flex'>
                                <Button onClick={() => handleCancelConfirmation(true)}
                                    size="xs"
                                    className='mr-2'
                                    variant="outline-primary"
                                    // disabled = {isLoading && pleasewait ? true : false }
                                    >
                                    OK
                                </Button>
                        <Button
                            size="xs"
                            variant="outline-dark"
                            onClick={() => handleCancelConfirmation(false)}
                            type="submit"> Cancel </Button>
                </div>
                </div>
             </Modal.Body>
    </Modal>
                            </div>
                        )}


                        {!enableUpdate && <Button  className="saveBttn" type="submit" variant="primary" size="sm" onClick={handleHighInDemandCreation}><span>Save</span> </Button>
                        }
                        {enableUpdate && <Button type="submit" variant="primary" size="sm" onClick={handleHighInDemandUpdation}>Update</Button>}
                      </Col>
                    </Row>
                    </Row>

                    </Form>
                    </div>



            </div>
            <div className='tableForm mt-3'>
            <div className="bodyContent">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbll taFeeq"
                  data={res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={res.length >10 && res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  res.length >25 && res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                {res.length === 0 &&
                 <div className="norecord">
                 <strong>No Record Found.</strong>
                 </div>
                }
            </div>
            </div>

            </Row>
          </Col>
        </Row>
    </Container>
    <FooterComp />
    </>
    );
}

export default HighInDemand;