import PackageService from '../../services/PackageService';
/**
 * @description: CRUD operations for Branch
 * @author: Ubedullah
 * @param {*}
 * @function TopRoutes
 * @date : 16-05-2023
 */

//Loading TopRoutes data
export const getTopRoutes = async (obj) => {
    let list = [];
    await PackageService.loadTopRoutes(obj).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}


//Save TopRoutes 
export const saveTopRoutes = async (data) => {
    let list = [];
    await PackageService.saveTopRoutes(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Update TopRoutes 
export const updateTopRoutes = async (data) => {
    let list = [];
    await PackageService.updateTopRoutes(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}




