/**
 * @description: Email Validation
 * @author: Anil Kumar ch
 * @param {*}
 * @function emailValidation
 * @date : 18-11-2020
 * Uppercase (A-Z) and lowercase (a-z) letters
 * Digits (0-9)
 * Characters such as ! # $ % & ‘ * + – / = ? ^ _ ` { | } ~
 * Character . ( period, dot or fullstop) but it should not be the first or last character 
 * and should not come one after the other
 */
export const emailValidation = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return true;
        }
        return false
}

/**
 * @description: mobileNumber Validation
 * @author: Anil Kumar ch
 * @param {*}
 * @function: mobileNumberValidation
 * @date : 18-11-2020
 * Below Formats it will work
 * +XX-XXXX-XXXX
 * +XX.XXXX.XXXX
 * +XX XXXX XXXX (/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/)
 */

export const mobileNumberValidation = (ph) => {
    if (/^[0-9]+$/.test(ph)) {
        return true;
    }
    else {
        return false;
    }
}

/**
 * @description: to check if the value has only characters
 * @author : Azamuddin
 * @date : 19-11-2020
 */
export const characterValidation = (chr) => {
    if(/^\s|\s$/.test(chr)) {
        console.log('init spaces')
        return false
} else {
    if (/^[a-zA-Z0-9& ]+$/.test(chr)) {
        console.log('got succ')
        return true;
    }
    else {
        console.log('special character')
        return false;
    }
}
}
export const companyCharacterValidation = (chr) => {
    if(/^\s|\s$/.test(chr)) {
        console.log('init spaces')
        return false
} else {
    if (/^[a-zA-Z0-9&_. ]+$/.test(chr)) {
        console.log('got succ')
        return true;
    }
    else {
        console.log('special character')
        return false;
    }
}
}


/**
 * @description: to check if the value has only characters
 * @author : Azamuddin
 * @date : 19-11-2020
 */
 export const characternameValidation = (chr) => {
    if(/^\s|\s$/.test(chr)) {
        console.log('init spaces')
        return false
} else {
    if (/^[a-zA-Z ]+$/.test(chr)) {
        console.log('got succ')
        return true;
    }
    else {
        console.log('special character')
        return false;
    }
}
}
/**
 * @description: to check if the value has  characters and numbers
 * @author : Azamuddin
 * @date : 19-11-2020
 */
export const characterNumberValidation = (chr) => {
    if (/^[0-9a-zA-Z]+$/.test(chr)) {
        return true;
    }
    else {
        return false;
    }
}

export const ticketNumberValidation = (tkt) => {
    if (/^[0-12]+$/.test(tkt)) {
        return false;
    }
    else {
        return true;
    }
}

//Alphanumeric validation
export const alpahnumericvalidate = (chr) => {

    if (/^[a-zA-Z0-9.]+$/.test(chr)) {
        console.log('got succ')
        return true;
    }
    else {
        console.log('special character')
        return false;
    }
}

