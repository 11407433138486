import React from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import LoginForm from '../home/LoginForm';
import Package from '../package/Index';
import PackageCreate from '../package/Create';
import ProtectedRoute from '../router/protected.route';
import ItinararyMain from '../itinarary/main';
import ItinararyTabs from '../itinarary/ItinararyTabs';
import CNTRecommendations from '../cntrecommendations/index';
import Toproutes from '../toproutes/index';
import Topdestinations from '../topdestinations/index';
import Popularhotels from '../popularhotels/index'
import HighInDemand from '../highdemand/index';

function RouterApp() {

    var filename = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    if(filename===""){
     window.location="/cms/login";
    }

        return (
            <>
                <Router>
                    <>
                        <Switch>
                            <Route path="/" exact component={LoginForm} />
                            <Route path="/cms/login" exact component={LoginForm} />
                            <ProtectedRoute path="/cms/package" exact component={PackageCreate} />
                            <ProtectedRoute path="/cms/create-package" exact component={Package} />
                            <ProtectedRoute path="/cms/create-itinarary" exact component={ItinararyTabs} />
                            <ProtectedRoute path="/cms/cnt-recommendations" exact component={CNTRecommendations} />
                            <ProtectedRoute path="/cms/top-routes" exact component={Toproutes} />
                            <ProtectedRoute path="/cms/high-in-demand" exact component={HighInDemand} />
                            <ProtectedRoute path="/cms/top-destinations" exact component={Topdestinations} />
                            <ProtectedRoute path="/cms/popular-hotels" exact component={Popularhotels} />

                        </Switch>
                    </>
                </Router>
            </>
        )
}


export default RouterApp