import PackageService from '../../services/PackageService';
/**
 * @description: CRUD operations for Branch
 * @author: Ubedullah
 * @param {*}
 * @function PopularHotels
 * @date : 16-05-2023
 */

//Loading PopularHotels data
export const getPopularHotels = async (obj) => {
    let list = [];
    await PackageService.loadPopularHotels(obj).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}


//Save PopularHotels 
export const savePopularHotels = async (data) => {
    let list = [];
    await PackageService.savePopularHotels(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Update PopularHotels 
export const updatePopularHotels = async (data) => {
    let list = [];
    await PackageService.updatePopularHotels(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}




