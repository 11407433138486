import React, { useState, useEffect, useReducer } from 'react';
import { Container, Form, Row, Col, Modal, Alert, Button, Nav, Table, Footer } from "react-bootstrap";
import HeaderComp from '../common/header';
import FooterComp from '../common/Footer';
import TextEditor from '../common/TextEditor';
import FileUpload from '../common/FileUpload';
import DateUtils from '../common/DateUtils';
import { savePackage, updatePackage } from './operation';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { mobileNumberValidation } from '../common/FormValidations';
import "react-datepicker/dist/react-datepicker.css";
const _ = require('lodash')
/**
 * Initial State Declaration
 */
const initialState = {
  _id: "", is_trending: false, is_staynearby: false, is_topdestination: false, package_name_en: "", package_name_ar: "", description_en: "", description_ar: "", image: "", amt: "",terms_en: "", terms_ar: "",duration:0,nights:0,
  from_date: "", to_date: "", overview_en: "", overview_ar: "", inclusive_en: "", inclusive_ar: "", cancellation_en: "", cancellation_ar: "", active: false, fromDate: "", toDate: "", cha_b2b: false, cha_b2c: false
}


// Reducer function for useReact Hook which will update the state
const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...state, categoryName: ''
      };
    case 'editPackage':
      return Object.assign(state, action.payload);
    default:
      return { ...state, [action.type]: action.payload };
  }
};
/**
 * @description:This Package listing
 * @author: Satya
 * @param {*}
 * @function NoRecord
 * @date : 14-10-2021
 */
function Package(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [notiMessage, setNotiMessage] = useState('');
  const [notiMessageShow, setNotiMessageShow] = useState(false);
  const [notiVarient, setNotiVarient] = useState();
  const [validated, setValidated] = useState(false);
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [desc, setDesc] = useState(false);
  const [inclusion, setIncluseion] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [overview, setOverView] = useState(false);
  const [price, setPrice] = useState(false);
  const [priceValid, setPriceValid] = useState(false);
  const [isfrom, setIsfrom] = useState(false);
  const [isto, setIsto] = useState(false);
  const [imgfile, setImgFile] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [showDuration, setShowDuration] = useState(false);
  const [showNights, setShowNingts] = useState(false);
  const [isDuration, setIsDuration] = useState(false);
  const [isNights, setIsNights] = useState(false);


  useEffect(() => {
    if (props.location.state !== undefined && props.location.state.req !== undefined &&
      props.location.state.req._id !== 0) {
      dispatch({ type: "editPackage", payload: props.location.state.req });
      setFromDate(DateUtils.convertNewToDate(props.location.state.req.from_date))
      setToDate(DateUtils.convertNewToDate(props.location.state.req.to_date))
      setEnableUpdate(true)
    }
  }, [props.location !== undefined])

  const handleChange = (e) => {
    const { id, value } = e.target;
    console.log("checked type:::::", e.target.id);
    dispatch({ type: id, payload: value });
    if (e.target.id === "is_trending") {
      if (state.is_trending) {
        dispatch({ type: "is_trending", payload: false });
      } else {
        dispatch({ type: "is_trending", payload: true });
      }
    }
    if (e.target.id === "is_staynearby") {
      if (state.is_staynearby) {
        dispatch({ type: "is_staynearby", payload: false });
      } else {
        dispatch({ type: "is_staynearby", payload: true });
      }
    }
    if (e.target.id === "is_topdestination") {
      if (state.is_topdestination) {
        dispatch({ type: "is_topdestination", payload: false });
      } else {
        dispatch({ type: "is_topdestination", payload: true });
      }
    }
    if (e.target.id === "cha_b2b") {
      if (state.cha_b2b) {
        dispatch({ type: "cha_b2b", payload: false });
      } else {
        dispatch({ type: "cha_b2b", payload: true });
      }
    }
    if (e.target.id === "cha_b2c") {
      if (state.cha_b2c) {
        dispatch({ type: "cha_b2c", payload: false });
      } else {
        dispatch({ type: "cha_b2c", payload: true });
      }
    }
  };

  const updateFileName = (url) => {
    dispatch({ type: "image", payload: url });
  }

  const changedate = (date) => {
    console.log("old date", fromDate)
    setFromDate(date)
  }

  const updateDescEn = (data) => {
    dispatch({ type: 'description_en', payload: data })
  }

  const updateDescAr = (data) => {
    dispatch({ type: 'description_ar', payload: data })
  }

  const updateOverview_EN = (data) => {
    dispatch({ type: 'overview_en', payload: data })
  }
  const updateOverview_AR = (data) => {
    dispatch({ type: 'overview_ar', payload: data })
  }

  const updateInclu_EN = (data) => {
    dispatch({ type: 'inclusive_en', payload: data })
  }

  const updateInclu_AR = (data) => {
    dispatch({ type: 'inclusive_ar', payload: data })
  }

  const updateCancel_EN = (data) => {
    dispatch({ type: 'cancellation_en', payload: data })
  }

  const updateCancel_AR = (data) => {
    dispatch({ type: 'cancellation_ar', payload: data })
  }

  const updateTermsEn = (data) => {
    dispatch({ type: 'terms_en', payload: data })
  }

  const updateTermsAr = (data) => {
    dispatch({ type: 'terms_ar', payload: data })
  }

  const validatePackage = () => {
    setNameErr(false)
    setDesc(false)
    setOverView(false)
    setCancel(false)
    setIncluseion(false)
    setPrice(false)
    setPriceValid(false)
    setIsfrom(false)
    setIsto(false)
    setImgFile(false)
    setIsTerms(false)
    setShowDuration(false)
    setShowNingts(false)
    setIsDuration(false)
    setIsNights(false)
    if (!state.is_trending && !state.is_staynearby && !state.is_topdestination) {
      setNotiMessageShow(true);
      setNotiMessage('Please select atleast one Marketing Type');
      setNotiVarient('danger')
      window.scrollTo(0, 0);
      setTimeout(function () {
        setNotiMessageShow(false);
        setNotiMessage('');
        setNotiVarient('')
      }, 3000);
      return false;
    } else if (fromDate === undefined) {
      setIsfrom(true)
      return false;
    } else if (toDate === undefined) {
      setIsto(true)
      return false;
    } else if (state.package_name_en === "") {
      setNameErr(true)
      window.scrollTo(0, 0);
      return false;
    } else if (state.overview_en === "") {
      setOverView(true)
      window.scrollTo(0, 0);
      return false;
    } else if (state.inclusive_en === "") {
      setIncluseion(true)
      window.scrollTo(0, 0);
      return false;
    } else if (state.cancellation_en === "") {
      setCancel(true)
      window.scrollTo(0, 0);
      return false;
    } else if (state.amt === "") {
      setPrice(true)
      window.scrollTo(0, 0);
      return false;
    } else if (state.amt !== "" && !mobileNumberValidation(state.amt)) {
      setPriceValid(true)
      window.scrollTo(0, 0);
      return false;
    } else if (state.image === "") {
      setImgFile(true)
      window.scrollTo(0, 0);
      return false;
    } else if (!state.cha_b2b && !state.cha_b2c) {
      setNotiMessageShow(true);
      setNotiMessage('Please select atleast one Channel Type');
      setNotiVarient('danger')
      window.scrollTo(0, 0);
      setTimeout(function () {
        setNotiMessageShow(false);
        setNotiMessage('');
        setNotiVarient('')
      }, 3000);
      return false;
    }else if (state.terms_en === ""){
      setIsTerms(true)
      window.scrollTo(0, 0);
      return false;
    }else if(state.duration==="0"){
      setIsDuration(true)
      window.scrollTo(0, 0);
      return false;
    }else if(state.nights==="0"){
      setIsNights(true)
      window.scrollTo(0, 0);
      return false;
    }
    if(state.duration !== 0){
      if(!mobileNumberValidation(state.duration)){
          setShowDuration(true)
          window.scrollTo(0, 0);
          return false;
    }else if(state.nights !== 0){
      if(!mobileNumberValidation(state.nights)){
            setShowNingts(true)
            window.scrollTo(0, 0);
            return false;
      }
      }
    }
    return true;
  }

 const mobileNumberValidation = (ph) => {
    if (/^[0-9]+$/.test(ph)) {
        return true;
    }
    else {
        return false;
    }
}
  function hidemessage() {
    setTimeout(function () {
      dispatch({ type: 'notiMessageShow', payload: false })
      dispatch({ type: 'notiMessage', payload: '' })
      dispatch({ type: 'notiVarient', payload: '' })
    }, 3000);
  }

  const handlePackageCreation = (event) => {
    event.preventDefault();
    if (validatePackage()) {
      state.from_date = DateUtils.convertStringToDate(fromDate);
      state.to_date = DateUtils.convertStringToDate(toDate);
      state.active = true;
      state.crby = 1;
      savePackage(state).then(response => {
        console.log("Package Save response " + JSON.stringify(response.data));
        if (response.data.suc) {
          window.scrollTo(0, 0);
          setNotiMessageShow(true);
          setNotiMessage('Package details are saved successfully...');
          setNotiVarient('success')
          setTimeout(function () {
            history.push("/cms/package");
          }, 2000);

        } else {
          setNotiMessageShow(true);
          setNotiMessage('Package details not saved');
          setNotiVarient('danger')
        }
      });
    }
  }

  const handlePackageUpdatation = (event) => {
    event.preventDefault();
    if (validatePackage()) {
      state.from_date = DateUtils.convertStringToDate(fromDate);
      state.to_date = DateUtils.convertStringToDate(toDate);
      state.active = true;
      state.upby = 1;
      updatePackage(state).then(response => {
        console.log("Package Save response " + JSON.stringify(response.data));
        if (response.data.suc) {
          window.scrollTo(0, 0);
          setNotiMessageShow(true);
          setNotiMessage('Package details are updated successfully...');
          setNotiVarient('success')
          setTimeout(function () {
            history.push("/cms/package");
          }, 2000);
        } else {
          setNotiMessageShow(true);
          setNotiMessage('Package details are not saved');
          setNotiVarient('danger')
        }
      });
    }
  }

  const back = () => {
    window.location = "/cms/package"
  }

  return (
    <>

      <HeaderComp />
      <Container fluid>
        <Alert className="notification" variant={notiVarient} show={notiMessageShow}
          onClose={() => setNotiMessageShow(false)}
          dismissible> {notiMessage}</Alert>
        <Row>
          <Col xs={6} md={2} className="sideBar">

            <Nav defaultActiveKey="/home" className="flex-column">
              <Nav.Link href="/cms/package" className="pl-0">Packages</Nav.Link>
            </Nav>
            <Nav defaultActiveKey="/home" className="flex-column">
              <Nav.Link href="/cms/create-itinarary" className="pl-0">Tawfeeq Travel Itinararies<span className="zyIcon icon-arrowDown"></span></Nav.Link>
            </Nav>
           <Nav defaultActiveKey="/home" className="flex-column">
              <Nav.Link href="/cms/cnt-recommendations" className="pl-0">CNT Recommendations<span className="zyIcon icon-arrowDown"></span></Nav.Link>
            </Nav>
           <Nav defaultActiveKey="/home" className="flex-column">
              <Nav.Link href="/cms/top-routes" className="pl-0">People Flying Here<span className="zyIcon icon-arrowDown"></span></Nav.Link>
            </Nav>
            <Nav defaultActiveKey="/home" className="flex-column">
              <Nav.Link href="/cms/high-in-demand" className="pl-0">High In Demand<span className="zyIcon icon-arrowDown"></span></Nav.Link>
            </Nav>
           <Nav defaultActiveKey="/home" className="flex-column">
              <Nav.Link href="/cms/top-destinations" className="pl-0">You will love it<span className="zyIcon icon-arrowDown"></span></Nav.Link>
            </Nav>
           <Nav defaultActiveKey="/home" className="flex-column">
              <Nav.Link href="/cms/popular-hotels" className="pl-0">Most Popular Hotels<span className="zyIcon icon-arrowDown"></span></Nav.Link>
            </Nav>
          </Col>
          <Col xs={12} md={10} className="innerBgcolor">

            <Row>

              <div className="tableForm">
                <Col className="d-flex justify-content-between align-center mt-4 mb-4 pl-0">
                  <h6 className="m-0">Packages</h6>

                </Col>

                <div className="tableForminner">
                  <Form noValidate validated={validated} id="formId">
                    <Col>
                    <Row>
                      <Form.Label>Channel Type : <sup>*</sup></Form.Label>
                      <Form.Group as={Col} xs={2} className="mb-3" controlId="cha_b2b">
                        <Form.Check
                          type="checkbox"
                          label="B2B"
                          custom
                          checked={state.cha_b2b}
                          value={state.cha_b2b}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} xs={2} className="mb-3" controlId="cha_b2c">
                        <Form.Check
                          type="checkbox"
                          label="B2C"
                          custom
                          checked={state.cha_b2c}
                          value={state.cha_b2c}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>
                    </Col>
                    <Col>
                    <Row>
                      <Form.Label>Marketing Type : <sup>*</sup></Form.Label>
                      <Form.Group as={Col} xs={2} className="mb-3" controlId="is_trending">
                        <Form.Check
                          type="checkbox"
                          label="Trending"
                          custom
                          checked={state.is_trending}
                          value={state.is_trending}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} xs={2} className="mb-3" controlId="is_staynearby">
                        <Form.Check
                          type="checkbox"
                          label="Stay Near By"
                          custom
                          checked={state.is_staynearby}
                          value={state.is_staynearby}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} xs={3} className="mb-3" controlId="is_topdestination">
                        <Form.Check
                          type="checkbox"
                          label="Top Destinations"
                          custom
                          checked={state.is_topdestination}
                          value={state.is_topdestination}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>
                    </Col>
                    <Row>
                      <Col>
                        <Form.Label>Package Name in English<sup>*</sup></Form.Label>
                        <Form.Group controlId="package_name_en">
                          <Form.Control
                            required
                            type="text"
                            maxLength="30"
                            autoComplete="off"
                            placeholder="Enter Package Name in English"
                            value={state.package_name_en}
                            onChange={handleChange}
                          />
                          {nameErr && (
                            <p className="vError">Please Enter Package Name</p>
                          )}
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Label>Package Name in Arabic</Form.Label>
                        <Form.Group controlId="package_name_ar">
                          <Form.Control
                            required
                            type="text"
                            maxLength="30"
                            placeholder="Enter Package Name in Arabic"
                            value={state.package_name_ar}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* <Row>
                      <Col>
                        <Form.Group as={Col} xs={16} controlId="description_en">
                          <Form.Label>Description English<sup>*</sup></Form.Label>
                          <TextEditor textType={"Desc_EN"} sendDescEn={updateDescEn} enableUpdate={enableUpdate} updateDescEn={state.description_en} />
                          {desc && (
                            <p className="vError">Please Enter Description</p>
                          )}
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className="mb-3" xs={16} controlId="description_ar">
                          <Form.Label>Description Arabic</Form.Label>
                          <TextEditor textType={"Desc_AR"} sendDescAr={updateDescAr} enableUpdate={enableUpdate} updateDescAr={state.description_ar} />
                        </Form.Group>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col xs={3}>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                          <FileUpload reportUrls={state.image} sendFileUrl={updateFileName} />
                          {imgfile && (
                            <p className="vError">Please upload the Image</p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={3}>
                        <Form.Group controlId="amt">
                          <Form.Label>Amount (QAR) <sup>*</sup></Form.Label>
                          <Form.Control
                            required
                            type="text"
                            maxLength="10"
                            className="amountField"
                            value={state.amt}
                            onChange={handleChange}
                          />
                          {price && (
                            <p className="vError">Please Enter Price</p>
                          )}
                          {priceValid && (
                            <p className="vError">Enter Valid Price</p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Label>Package Duration <sup>*</sup></Form.Label>
                    <Row>
                    <Col xs={2} >
                      <div className='packDuration d-flex align-items-center'>
                        <Form.Group controlId="duration">
                        <div className='d-flex align-items-center'>
                          <Form.Control
                            required
                            type="text"
                            maxLength="10"
                            className="amountField"
                            value={state.duration}
                            onChange={handleChange}
                          />
                          <span>Days</span>
                          </div>
                          {isDuration && (
                            <p className="vError">Please Enter days</p>
                          )}
                          {showDuration && (
                            <p className="vError">Please Enter Vaild days</p>
                          )}
                        </Form.Group>
                        </div>
                        </Col>
                        <Col xs={3} >
                        <div className='packDuration d-flex align-items-center'>
                        <Form.Group controlId="nights">
                          <div className='d-flex align-items-center'>
                          <Form.Control
                            required
                            type="text"
                            maxLength="50"
                            className="amountField"
                            value={state.nights}
                            onChange={handleChange}
                          />
                           <span>Nights</span>
                           </div>
                           {isNights && (
                            <p className="vError">Please Enter Nights</p>
                          )}
                          {showNights && (
                            <p className="vError">Please Enter proper nights</p>
                          )}
                        </Form.Group>
                        </div>
                        </Col>

                    </Row>
                    <Form.Label>Validity<sup>*</sup></Form.Label>
                    <Row>
                      <Col xs={3} >
                        <Form.Group className="selectplugin datePicker" controlId="fromDate">

                          <DatePicker
                            selected={fromDate}
                            onChange={date => (setToDate(), changedate(date))}
                            fromDate={fromDate}
                            placeholderText="From"
                            dateFormat="dd-MM-yyyy"
                            toDate={toDate}
                            minDate={new Date()}
                          />
                          <span className="calendar ezyIcon icon-newcalendar"></span>
                          {isfrom && (
                            <p className="vError">Please select validity From date</p>
                          )}
                        </Form.Group>
                      </Col>

                      <Col xs={3} className="pr-0 pl-0">

                        <Form.Group as={Col} className="selectplugin datePicker secondCalender" controlId="fromDate">
                          <DatePicker
                            selected={toDate}
                            onChange={date => setToDate(date) + 1}
                            fromDate={fromDate}
                            placeholderText="To"
                            dateFormat="dd-MM-yyyy"
                            toDate={toDate}
                            minDate={fromDate ? (props.hotel !== undefined ? state.setHDate : fromDate) : (props.hotel !== undefined ? state.setIHdate : new Date())}
                            className="datefield"
                          />
                          <span className="calendar ezyIcon icon-newcalendar"></span>
                          {isto && (
                            <p className="vError">Please select validity To date</p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="overview_en">
                          <Form.Label>Overview English<sup>*</sup></Form.Label>
                          <TextEditor textType={"Overview_EN"} sendOverview_EN={updateOverview_EN} enableUpdate={enableUpdate} updateOverEn={state.overview_en} />
                          {overview && (
                            <p className="vError">Please Enter Overview</p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="overview_ar">
                          <Form.Label>Overview Arabic</Form.Label>
                          <TextEditor textType={"Overview_AR"} sendOverview_AR={updateOverview_AR} enableUpdate={enableUpdate} updateOverAr={state.overview_ar} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="inclusive_en">
                          <Form.Label>Inclusions & Exclusions English<sup>*</sup></Form.Label>
                          <TextEditor textType={"Inclusions_EN"} sendInclusions_EN={updateInclu_EN} enableUpdate={enableUpdate} updateIncluEn={state.inclusive_en} />
                          {inclusion && (
                            <p className="vError">Please Enter Inclusions & Exclusions</p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="inclusive_ar">
                          <Form.Label>Inclusions & Exclusions Arabic</Form.Label>
                          <TextEditor textType={"Inclusions_AR"} sendInclusions_AR={updateInclu_AR} enableUpdate={enableUpdate} updateIncluAr={state.inclusive_ar} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="cancellation_en">
                          <Form.Label>Cancellation English<sup>*</sup></Form.Label>
                          <TextEditor textType={"Cancel_EN"} sendCancel_EN={updateCancel_EN} enableUpdate={enableUpdate} updateCanEn={state.cancellation_en} />
                          {cancel && (
                            <p className="vError">Please Enter Cancellation</p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="cancellation_ar">
                          <Form.Label>Cancellation Arabic</Form.Label>
                          <TextEditor textType={"Cancel_AR"} sendCancel_AR={updateCancel_AR} enableUpdate={enableUpdate} updateCanAr={state.cancellation_ar} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Col} xs={16} controlId="description_en">
                          <Form.Label>Terms & Conditions English<sup>*</sup></Form.Label>
                          <TextEditor textType={"Terms_EN"} sendTermEn={updateTermsEn} enableUpdate={enableUpdate} updateTermEn={state.terms_en} />
                          {isTerms && (
                            <p className="vError">Please Enter Terms & Conditions</p>
                          )}
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className="mb-3" xs={16} controlId="description_ar">
                          <Form.Label>Terms & Conditions Arabic</Form.Label>
                          <TextEditor textType={"Terms_AR"} sendTermAr={updateTermsAr} enableUpdate={enableUpdate} updateTermAr={state.terms_ar} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="submitBtn">
                      <Col className="d-flex">
                        {!enableUpdate && <Button type="submit" variant="primary" size="sm" onClick={handlePackageCreation}>Save</Button>
                        }
                        {enableUpdate && <Button type="submit" variant="primary" size="sm" onClick={handlePackageUpdatation}>Update</Button>}
                      </Col>
                    </Row>
                  </Form>
                  <Button className="mr-2 backButton" type="submit" variant="primary" size="sm" onClick={back}>Back</Button>
                </div>
              </div>
            </Row>

          </Col>
        </Row>
      </Container>
      <FooterComp />
    </>
  )
}
export default Package