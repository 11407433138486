import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col,Modal,Alert,Button } from "react-bootstrap";
/**
 *  @description:This Package listing
 * @author: Satya
 * @param {*}
 * @function NoRecord
 * @date : 14-10-2021
 */
function Footer(props) {
  const { state } = props || {}


  return (
    <div className="footerContent">
   <span>© 2021. All rights reserved.</span>
   </div>
  )
}
export default Footer