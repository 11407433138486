import React, { useState, useEffect, useReducer } from 'react';
import { Container, Form, Row, Col, Modal, Alert, Button, Nav } from "react-bootstrap";
import HeaderComp from '../common/header';
import FooterComp from '../common/Footer';
import Select from "react-dropdown-select";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getRecommendations, saveRecommendations, updateRecommendations } from './operation';
import { FaEdit } from "react-icons/fa"
import ImageUpload from './ImageUpload';
import SidebarMenu from '../common/sidebar';


/**
 * Initial State Declaration
 */
const initialState = {
    _id: "", channel_type:"", country:"",  active: false, selChannel:[], selCountry:[],
    Emailid: ""}


  // Reducer function for useReact Hook which will update the state
  const reducer = (state, action) => {
    switch (action.type) {
      case 'clear':
        return {
          ...state, id: "", channel_type:"", country:"", url_en: "", url_ar: "", active: false, Emailid: ""
        };
      case 'editRecommendations':
        return Object.assign(state, action.payload);
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

/**
 * @description:CNTRecommendations Screen
 * @author: Ubedullah
 * @param {*}
 * @function CNTRecommendations - CMS
 * @date : 05-15-2023
 */

const CNTRecommendations = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [notiMessage, setNotiMessage] = useState('');
    const [notiMessageShow, setNotiMessageShow] = useState(false);
    const [notiVarient, setNotiVarient] = useState();
    const [validated, setValidated] = useState(false);
    const [enableUpdate, setEnableUpdate] = useState(false);
    const [countryTypes, setCountryTypes] = useState([{value:"India",label:"India"},{value:"Saudi Arabia",label:"Saudi Arabia"},{value:"United Arab Emirates",label:"United Arab Emirates"},{value:"Qatar",label:"Qatar"},{value:"Oman",label:"Oman"}]);
    const [chanelTypes, setChannelTypes] = useState([{value:"B2B",label:"B2B"},{value:"B2C",label:"B2C"}]);
    const [imgfile, setImgFile] = useState(false);
    const [act, setAct] = useState(true);
    const [expireDate, setExpireDate] = useState("");
    const [res, setRes] = useState([]);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [cancelConfirmation, setCancelConfirmation] = useState(false);

    const [index, setIndex] = useState(0);

    useEffect(() => {
        getRecommendations({"type":"ALL"}).then(response => {
          console.log('Recommendations response', response)
          if (response.data.suc && response.data.res) {
            convertRecommendationsData(response.data.res)
          }
        }).catch(function (error) {
          console.log('Recommendations response error ', error)
        });
      }, [])

/**
 * @description:CNTRecommendations Screen edit functionality
 * @author: Ubedullah
 * @param {*}
 * @function editRecommendations - CMS
 * @date : 05-15-2023
 */

  const editRecommendations=(obj)=>{
    const dateFormat = obj.rec_images_list.map((obj) => {
      return obj
    })

    const dateFormat2 = dateFormat.map((data) => {
      return {...data, expiry_date:data.expiry_date.slice(0,10)}
    })

    dispatch({ type: 'editRecommendations', payload: obj })
    dispatch({ type: 'selChannel', payload: [{value:obj.channel,label:obj.channel}] })
    dispatch({ type: 'selCountry', payload: [{value:obj.region,label:obj.region}] })
    dispatch({ type: 'Emailid', payload: obj.email })
    setRows(dateFormat2);
    setAct(obj.status === "Active" ? true : false)
    setEnableUpdate(true)
  }

  /**
 * @description:CNTRecommendations Screen display UI in Table
 * @author: Ubedullah
 * @param {*}
 * @function convertRecommendationsData - CMS
 * @date : 05-15-2023
 */

  const columns = [{
    dataField: 'country',
    text: 'Country Name',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'cha_type',
    text: 'Channel Type',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'status',
    text: 'Status'
  },
  {
    dataField: 'options',
    text: 'Actions',
    class: 'tes'
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const convertRecommendationsData=(response)=>{
    let data = [];
    let index=1;
     for(let value of response){
      data.push({ country: value.region,cha_type:value.channel, email: value.email, status: value.status,
      options: <><span className="tbAction"><a className="ezyIcon icon-edit" onClick={()=>editRecommendations(value)}> <span>Edit</span></a></span>
      </> })
         index=index+1;
     }
     setRes(data)
  }

    const updateChanel=(obj)=>{
        dispatch({ type: 'channel_type', payload: obj[0].value })
        dispatch({ type: 'selChannel', payload: obj })
        setUnsavedChanges(true);
    }
    const updateCountry=(obj)=>{
        dispatch({ type: 'country', payload: obj[0].value })
        dispatch({ type: 'selCountry', payload: obj })
        setUnsavedChanges(true);
    }

    const updateFileNameEn = (index, url) => {
      const updatedRows = [...rows];
      updatedRows[index].url_en = url;
      setRows(updatedRows);
      setUnsavedChanges(true);
    }

    const updateFileNameAr = (index, url) => {
      const updatedRows = [...rows];
      updatedRows[index].url_ar = url;
      setRows(updatedRows);
      setUnsavedChanges(true);
    }

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'act') {
        setAct(e.target.checked)
    }
    console.log("checked type:::::", e.target.id);
    setUnsavedChanges(true);
  };

  const handleEmail = (e) => {
      dispatch({
        type: "Emailid",
        payload: e.target.value.toString(),
      });
      setUnsavedChanges(true);
    };

  /**
 * @description:CNTRecommendations Screen Hiding success and Error validation message
 * @author: Ubedullah
 * @param {*}
 * @function hidemessage
 * @date : 05-15-2023
 */

  const hidemessage=() =>{
    setTimeout(function () {
        setNotiMessageShow(false);
        setNotiMessage('');
        setNotiVarient('');
    }, 4000);
  }

  /**
 * @description:CNTRecommendations Screen Validating Form
 * @author: Ubedullah
 * @param {*}
 * @function Validating Form
 * @date : 05-15-2023
 */

  const validateRecommendations = () => {
    setImgFile(false)
    if(state.country===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Select Country');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(state.channel_type===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Select Channel Type');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }else if(state.Emailid===""){
      setNotiMessageShow(true);
      setNotiMessage('Please Enter Email Id');
      setNotiVarient('danger')
      hidemessage();
      return false;
    }
    else if(rows.length <= 2) {
        setNotiMessageShow(true);
        setNotiMessage('Please Add Atleast three CNT Recommendations');
        setNotiVarient('danger')
        hidemessage();
        return false;
    }
    if(rows.length!==0){
     for(let val of rows){
       if(val.url_en===null){
        setNotiMessageShow(true);
        setNotiMessage('Please Upload Recommendations Image (English)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }if(val.url_ar===null){
        setNotiMessageShow(true);
        setNotiMessage('Please Upload Recommendations Image (Arabic)');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }if(val.expiry_date===null){
        setNotiMessageShow(true);
        setNotiMessage('Please Select Expiry Date');
        setNotiVarient('danger')
        hidemessage();
        return false;
       }
     }
    }
    return true;
  }

/**
 * @description:CNTRecommendations Screen Creation(save) data
 * @author: Ubedullah
 * @param {*}
 * @function handleRecommendationsCreation - CMS
 * @date : 05-15-2023
 */

const handleRecommendationsCreation = (event) => {
    event.preventDefault();
    if (validateRecommendations()) {
      let suc = true;
      for (let val of res) {
        if (val.country?.toLowerCase() == state.country?.toLowerCase()) {
          if (
            val.country?.toLowerCase() == state.country?.toLowerCase() &&
            val.cha_type?.toLowerCase() == state.channel_type?.toLowerCase()
          ) {
            suc = false;
          }
        }
      }
      if(suc) {
        const payload = {
            "region": state.country,
            "channel": state.channel_type,
            "email": state.Emailid,
            "status": act == true ? "Active" : "In-Active",
            "rec_images_list": rows
        }
        saveRecommendations(payload).then(response => {
          console.log("Recommendations Save response " + JSON.stringify(response.data));
          if (response.data.suc) {
            window.scrollTo(0, 0);
            setNotiMessageShow(true);
            setNotiMessage('CNT Recommendations details are saved successfully...');
            setNotiVarient('success')
            setTimeout(function () {
              window.location="/cms/cnt-recommendations";
            }, 2000);

          } else {
            setNotiMessageShow(true);
            setNotiMessage('CNT Recommendations details not saved');
            setNotiVarient('danger');
            hidemessage();
          }
        });
        } else {
            setNotiMessageShow(true);
            setNotiMessage('Country with saleschannel already exists');
            setNotiVarient('danger');
            hidemessage();
            return false;
        }
      }
    }

/**
 * @description:CNTRecommendations Screen Updating(update) data
 * @author: Ubedullah
 * @param {*}
 * @function handleRecommendationsUpdation - CMS
 * @date : 05-15-2023
 */

    const handleRecommendationsUpdation = (event) => {
        event.preventDefault();
        if (validateRecommendations()) {
            const status = act === true ? "Active" : "In-Active"
            state.rec_images_list = rows;
            state.updated_by = 1;
            state.status = status
            updateRecommendations(state).then(response => {
            console.log("Recommendations Save response " + JSON.stringify(response.data));
            if (response.data.suc) {
              window.scrollTo(0, 0);
              setNotiMessageShow(true);
              setNotiMessage('CNT Recommendations details are updated successfully...');
              setNotiVarient('success')
              setTimeout(function () {
                window.location="/cms/cnt-recommendations";
              }, 2000);
            } else {
              setNotiMessageShow(true);
              setNotiMessage('CNT Recommendations details are not Updated');
              setNotiVarient('danger')
              hidemessage();
            }
          });
        }
    }

/**
 * @description:CNTRecommendations Screen Image Rows Functionality (ADD)
 * @author: Ubedullah
 * @param {*}
 * @date : 05-15-2023
 */

    const [rows, setRows] = useState([
        { url_en: null, url_ar: null, expiry_date: null, is_emailed:false, mailed_date:null, index: 0 }
      ]);

      const handleImage1Upload = (index, file) => {
        const updatedRows = [...rows];
        updatedRows[index].url_en = URL.createObjectURL(file.split(" ").join(""));
        setRows(updatedRows);
        // updatedRows[index].url_en.splice(index, 1);
        // setRows(updatedRows);
        setUnsavedChanges(true);
      };

      const handleImage2Upload = (index, file) => {
        const updatedRows = [...rows];
        updatedRows[index].url_ar = URL.createObjectURL(file.split(" ").join(""));
        setRows(updatedRows);
        setUnsavedChanges(true);
      };

      const handleDateChange = (index, expiry_date) => {
        const updatedRows = [...rows];
        updatedRows[index].expiry_date = expiry_date;
        updatedRows[index].is_emailed = false;
        updatedRows[index].mail_updated_dt = false;
        updatedRows[index].mailed_date = null;
        setRows(updatedRows);
        setUnsavedChanges(true);
      };

      const handleAddRow = () => {
        setIndex(index + 1)
        setRows([...rows, { url_en: null, url_ar: null, expiry_date: null, is_emailed:false, mailed_date:null, index: index + 1 }]);
      };

    const handleRemoveEnglishImage = (index) => {
        const confirmed = window.confirm('Are you sure you want to delete selected image?');
        if (confirmed) {
        const updatedRows = [...rows];
        updatedRows[index].url_en = null;
        setRows(updatedRows);
        }
    };

    const handleRemoveArabicImage = (index) => {
        const confirmed = window.confirm('Are you sure you want to delete selected image?');
        if (confirmed) {
        const updatedRows = [...rows];
        updatedRows[index].url_ar = null;
        setRows(updatedRows);
        }
    };


      const Images = ({
        index,
        url_en,
        url_ar,
        expiry_date,
        onImage1Upload,
        onImage2Upload,
        onDateChange
      }) => {
        const handleImage1Upload = (event) => {
          const file = event.target.files[0];

          // Check if the file is a valid image with the correct dimensions
            if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
                const img = new Image();
                img.src = URL.createObjectURL(file.split(" ").join(""));

                img.onload = () => {
                if (img.width === 480 && img.height === 180) {
                    onImage1Upload(index, file);
                } else {
                    // Invalid dimensions
                    setNotiMessageShow(true);
                    setNotiMessage('The image dimensions should be 480x180.');
                    setNotiVarient('danger')
                    hidemessage();
                    return false;
                }
                };
            } else {
                // Invalid file type
                setNotiMessageShow(true);
                setNotiMessage('Please upload a JPG or PNG image.');
                setNotiVarient('danger')
                hidemessage();
                return false;
            }
        };

        const handleImage2Upload = (event) => {
          const file = event.target.files[0];

          // Check if the file is a valid image with the correct dimensions
          if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            const img = new Image();
            img.src = URL.createObjectURL(file.split(" ").join(""));

            img.onload = () => {
            if (img.width === 480 && img.height === 180) {
                onImage2Upload(index, file);
            } else {
                // Invalid dimensions
                setNotiMessageShow(true);
                setNotiMessage('The image dimensions should be 480x180.');
                setNotiVarient('danger')
                hidemessage();
                return false;
            }
            };
        } else {
            // Invalid file type
            setNotiMessageShow(true);
            setNotiMessage('Please upload a JPG or PNG image.');
            setNotiVarient('danger')
            hidemessage();
            return false;
        }
        };

        const handleDateChange = (event) => {
          const expiry_date = event.target.value;
          onDateChange(index, expiry_date);
        };

        const getCurrentDate = () => {
          const now = new Date();
          const year = now.getFullYear();
          let month = now.getMonth() + 1;
          if (month < 10) {
            month = `0${month}`;
          }
          let day = now.getDate();
          if (day < 10) {
            day = `0${day}`;
          }
          return `${year}-${month}-${day}`;
        };


        return (
          <div className='upload-image mt-4'>
            <Row>
                <Col xs={3}>
                    <Form.Group controlId="recImgEn" className="mb-3">
                        <Form.Label>Recommendations Image (English) <sup>*</sup></Form.Label>
                        <ImageUpload reportUrls={url_en} sendFileUrl={updateFileNameEn} index={index} />
                          {imgfile && (
                            <p className="vError">Please upload the Image</p>
                          )}
                        {/*
                        <input type="file" accept="image/jpeg, image/png" onChange={handleImage1Upload} />
                        </div>
                        <span className='jpgPNg'>JPG or PNG (Dimensions: 480x180)</span>

                        {url_en && <div className='imgUplo'><img src={url_en} alt="Uploaded English Image" />
                        <button onClick={() => handleRemoveEnglishImage(index)}>x</button></div>}

                        <br />
                        <span>JPG or PNG (Dimensions: 480x180)</span> */}
                    </Form.Group>
                </Col>
                <Col xs={3}>
                    <Form.Group controlId="recImgAr" className="mb-3">
                        <Form.Label>Recommendations Image (Arabic) <sup>*</sup></Form.Label>
                        <ImageUpload reportUrls={url_ar} sendFileUrl={updateFileNameAr} index={index} />
                          {imgfile && (
                            <p className="vError">Please upload the Image</p>
                          )}
                        {/* <input type="file" accept="image/jpeg, image/png" onChange={handleImage2Upload} />
                        {url_ar && <div><img src={url_ar} alt="Uploaded Arabic Image" />
                        <button onClick={() => handleRemoveArabicImage(index)}>x</button></div>}
                        <br />
                        <span>JPG or PNG (Dimensions: 480x180)</span> */}
                    </Form.Group>
                </Col>
                <Col xs={2}>

                    <Form.Group className="selectplugin datePicker secondCalender expireOn" controlId="expiryDate">
                        <Form.Label>Expires On <sup>*</sup></Form.Label>
                        {console.log("SDebug expire date >", expireDate)}
                        <input type="date" value={expiry_date} onChange={handleDateChange} min={getCurrentDate()} />
                    </Form.Group>
                </Col>
            </Row>
          </div>
        );
      };

      const handleClosePopup = (index, confirmDelete) => {
        const updatedRowData = [...rows];
        updatedRowData[index].showDeletePopup = false;

        if (confirmDelete) {
          updatedRowData[index].img_url = null;
        }

        setRows(updatedRowData);
      };


      const handleCancel = (e) => {
        if (unsavedChanges) {
          setCancelConfirmation(true);
        } else {
          if(state.channel_type !== "" || state.country !== "") {
            dispatch({ type: 'channel_type', payload: "" })
            dispatch({ type: 'country', payload: "" })
          }
            dispatch({ type: "Emailid", payload: "" })
            setRows([{
                url_en: "",
                url_ar: "",
                expiry_date: null
              }]);
            setAct(true)
        }
      };

      const handleCancelConfirmation = (confirmed) => {
        if (confirmed) {
          if(state.channel_type !== "" || state.country !== "") {
            dispatch({ type: 'channel_type', payload: "" })
            dispatch({ type: 'selChannel', payload: [{value:"", label: ""}] })
            dispatch({ type: 'country', payload: "" })
            dispatch({ type: 'selCountry', payload: [{value:"",label:""}] })
          }
          dispatch({ type: "Emailid", payload: "" })
          setRows([{
            url_en: "",
            url_ar: "",
            expiry_date: null
          }]);
          setAct(true)
          setUnsavedChanges(false);
        }
        setCancelConfirmation(false);
      };

    return (
        <>
        <HeaderComp />
        <Alert className="notification" variant={notiVarient} show={notiMessageShow}
          onClose={() => setNotiMessageShow(false)}
          dismissible> {notiMessage}</Alert>
        <Container fluid>
        <Row>
          <Col xs={6} md={2} className="sideBar">
            <SidebarMenu active="CNT Recommendations" />
          </Col>

          <Col xs={12} md={10} className="innerBgcolor">
                <Col className="d-flex justify-content-between align-center mt-4 pl-0 spaceBet">
                    <h6 className="ml-2 mb-0">CNT Recommendations</h6>
                </Col>
            <Row>

            <div className="tableForm">


            <div className="tableForminner">
                  <Form noValidate validated={validated} id="formId">
                <div className='formSectionn'>
                    <Row>
                    <Col xs={3}>

                      <Form.Label>Select Country <sup>*</sup></Form.Label>
                      <Form.Group
                      controlId="ctyp"
                      >
                      <Select
                        disabled={enableUpdate}
                        options={countryTypes}
                        searchable={false}
                        values={state.selCountry}
                        onChange={(value) => updateCountry(value)}
                      />
                    </Form.Group>

                    </Col>


                    <Col xs={3}>

                      <Form.Label>Channel Type <sup>*</sup></Form.Label>
                      <Form.Group
                      controlId="ctyp"
                      >
                      <Select
                        disabled={enableUpdate}
                        options={chanelTypes}
                        searchable={false}
                        values={state.selChannel}
                        onChange={(value) => updateChanel(value)}
                      />
                    </Form.Group>

                    </Col>


                    <Col xs={3}>


                  <Form.Label>
                    Email id <sup>*</sup>
                  </Form.Label>
                <Form.Group controlId="Emailid">
                  <Form.Control
                    type="email"
                    disabled={state.disabled}
                    placeholder='Enter Email Id'
                    onChange={handleEmail}
                    value={state.Emailid}
                  ></Form.Control>
                </Form.Group>

                    </Col>
                       </Row>
                  </div>

                    {rows.map((row, index) => (
            <Images
              key={index}
              index={index}
              url_en={row.url_en}
              url_ar={row.url_ar}
              expiry_date={row.expiry_date}
              onImage1Upload={handleImage1Upload}
              onImage2Upload={handleImage2Upload}
              onDateChange={handleDateChange}
            />
          ))}
          {rows.length <= 5 ? <Button className="plusBtn recoMand" onClick={handleAddRow}><span>+</span></Button> : null}
          <hr />
                            <Row>
                        <Col>
                        {/* <Form.Label>Status</Form.Label> */}
                        <Form.Group className="topSwitch">
                            <Form.Check
                            type="switch"
                            id="act"
                            label="Status"
                            checked={act}
                            value={act}
                            onChange={handleChange}
                            />
                        </Form.Group>
                        </Col>


                    <Row className="submitBtn popBox">
                      <Col className="d-flex">
                      {!enableUpdate && <Button type="button" variant="primary" size="sm" className="mr-3" onClick={handleCancel}>Cancel</Button>}

                        {cancelConfirmation && (
                            <div className="confirmation-popup">
                                <Modal
                        show={cancelConfirmation}
                        onHide={() => handleClosePopup(false)}
                        backdrop="static"
                        size="small"
                        className="custom-dailog alignDelc"
                        keyboard={false}>
                        {/* <Modal.Header>
                        <Modal.Title>Delete Image</Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body>
                        <div className="submitBtn">
                        <div>
                        <Form.Label className="custom-dailog-title">Any unsaved data will be lost. Click OK to continue.</Form.Label>
                        </div>
                        <div className='d-flex justify-content-center'>
                        <Button onClick={() => handleCancelConfirmation(true)}
                            size="xs"
                            variant="outline-primary saveBttn mr-2"
                            // disabled = {isLoading && pleasewait ? true : false }
                            >
                            OK
                        </Button>
                        <Button
                            size="xs"
                            variant="outline-dark"
                            onClick={() => handleCancelConfirmation(false)}
                            type="submit"> Cancel </Button>
                            </div>
                        </div>
                        </Modal.Body>
                        </Modal>
                            </div>
                        )}

                        {!enableUpdate && <Button className="saveBttn" type="submit" variant="primary" size="sm" onClick={handleRecommendationsCreation}><span>Save</span></Button>
                        }
                        {enableUpdate && <Button type="submit" variant="primary" size="sm" onClick={handleRecommendationsUpdation}>Update</Button>}
                      </Col>
                    </Row>
                    </Row>

                    </Form>
                    </div>



            </div>


<div className='tableForm mt-3'>
<div className="bodyContent">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  classes="markup filterTbll taFeeq cntRecom"
                  data={res}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  filter={filterFactory()}
                  pagination={res.length >10 && res.length <=25 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25}]}):
                  res.length >25 && res.length <=30 ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30}]}):
                  res.length >30  ? paginationFactory({sizePerPageList:[{text: '10', value: 10},{text: '25', value: 25},{text: '30', value: 30},{text: '50', value: 50}]}):''}
                />
                {res.length === 0 &&
                 <div className="norecord">
                 <strong>No Record Found.</strong>
                 </div>
                }
            </div>
</div>
            </Row>
          </Col>
        </Row>
    </Container>
    <FooterComp />
    </>
    );
}

export default CNTRecommendations;