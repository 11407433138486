import React from 'react';
import RouterApp from '../src/components/router';
function App(props) {

  return (
    <>
      <RouterApp />
    </>
  );
}




export default App;
