import React from 'react';
/**
 * @description:This component will work for processing popUp .
 * @author: Pravas
 * @param {*}
 * @function Processing
 * @date : 01-09-2020
 */
function Processing(props) {

  return (
    <>
          <div className="layover zIndex999">
            <div className="popUpBox processing">
              <div className="icons process"></div>
              <strong>Please wait…</strong>
              {
                props.onhold !== undefined && props.onhold == "yes" ? (
                  <p className="processInfo">Deleting Selected Image.</p>
                ) : (
                  <p className="processInfo">Deleting Selected Image.</p>
                )
              }

              <p className="m-0">Do not refresh the page</p>

            </div>
          </div>

    </>
  )
}
export default Processing