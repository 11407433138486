import React, { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';

const TextEditor = (props) => {
    const [descEn, setDescEn] = useState(RichTextEditor.createEmptyValue());
    const [descAr, setDescAr] = useState(RichTextEditor.createEmptyValue());
    const [overEn, setOverEn] = useState(RichTextEditor.createEmptyValue());
    const [overAr, setOverAr] = useState(RichTextEditor.createEmptyValue());
    const [inclusionEn, setInclusionEn] = useState(RichTextEditor.createEmptyValue());
    const [inclusionAr, setInclusionAr] = useState(RichTextEditor.createEmptyValue());
    const [cancelEn, setCancelEn] = useState(RichTextEditor.createEmptyValue());
    const [cancelAr, setCnacelAr] = useState(RichTextEditor.createEmptyValue());
    const [termEn, setTermEn] = useState(RichTextEditor.createEmptyValue());
    const [termAr, setTermAr] = useState(RichTextEditor.createEmptyValue());

    useEffect(() => {
        if(props.enableUpdate){
            if(props.updateDescEn!=="" && props.updateDescEn!==undefined){
                setDescEn(RichTextEditor.createValueFromString(props.updateDescEn, 'html'))
              }
            if(props.updateDescAr!=="" && props.updateDescAr!==undefined){
                setDescAr(RichTextEditor.createValueFromString(props.updateDescAr, 'html'))
            }
            if(props.updateOverEn!=="" && props.updateOverEn!==undefined){
                setOverEn(RichTextEditor.createValueFromString(props.updateOverEn, 'html'))
              }
            if(props.updateOverAr!=="" && props.updateOverAr!==undefined){
                setOverAr(RichTextEditor.createValueFromString(props.updateOverAr, 'html'))
            }
            if(props.updateIncluEn!=="" && props.updateIncluEn!==undefined){
                setInclusionEn(RichTextEditor.createValueFromString(props.updateIncluEn, 'html'))
              }
            if(props.updateIncluAr!=="" && props.updateIncluAr!==undefined){
                setInclusionAr(RichTextEditor.createValueFromString(props.updateIncluAr, 'html'))
            }
            if(props.updateCanEn!=="" && props.updateCanEn!==undefined){
                setCancelEn(RichTextEditor.createValueFromString(props.updateCanEn, 'html'))
              }
            if(props.updateCanAr!=="" && props.updateCanAr!==undefined){
                setCnacelAr(RichTextEditor.createValueFromString(props.updateCanAr, 'html'))
            }

            if(props.updateTermEn!=="" && props.updateTermEn!==undefined){
              setTermEn(RichTextEditor.createValueFromString(props.updateTermEn, 'html'))
            }
          if(props.updateTermAr!=="" && props.updateTermAr!==undefined){
            setTermAr(RichTextEditor.createValueFromString(props.updateTermAr, 'html'))
          }
        }
    }, [props.enableUpdate])

  const onChange = (value) => {
    if (props.onChange) {
      props.onChange(value.toString('html'));
    }
    if(props.textType==="Desc_EN"){
        setDescEn(value);
        props.sendDescEn(value.toString('html'))
    }else if(props.textType==="Desc_AR"){
        setDescAr(value)
        props.sendDescAr(value.toString('html'))
    }else if(props.textType==="Overview_EN"){
        setOverEn(value)
        props.sendOverview_EN(value.toString('html'))
    }else if(props.textType==="Overview_AR"){
        setOverAr(value)
        props.sendOverview_AR(value.toString('html'))
    }else if(props.textType==="Inclusions_EN"){
        setInclusionEn(value)
        props.sendInclusions_EN(value.toString('html'))
    }else if(props.textType==="Inclusions_AR"){
        setInclusionAr(value)
        props.sendInclusions_AR(value.toString('html'))
    }else if(props.textType==="Cancel_EN"){
        setCancelEn(value)
        props.sendCancel_EN(value.toString('html'))
    }else if(props.textType==="Cancel_AR"){
        setCnacelAr(value)
        props.sendCancel_AR(value.toString('html'))
    }else if(props.textType==="Terms_EN"){
      setTermEn(value)
      props.sendTermEn(value.toString('html'))
  }else if(props.textType==="Terms_AR"){
      setTermAr(value)
      props.sendTermAr(value.toString('html'))
  }
    
  };

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
  };

  
  return(
      <div>
      {props.textType==="Desc_EN" &&
      
        <>
       <RichTextEditor toolbarConfig={toolbarConfig} value={descEn} onChange={onChange} 
       />
       </>
        }
        {props.textType==="Desc_AR" &&
        <>
       <RichTextEditor toolbarConfig={toolbarConfig} value={descAr} onChange={onChange} />
       </>
        }
        {props.textType==="Overview_EN" &&
        <>
       <RichTextEditor toolbarConfig={toolbarConfig} value={overEn} onChange={onChange} />
       </>
        }
        {props.textType==="Overview_AR" &&
        <>
       <RichTextEditor toolbarConfig={toolbarConfig} value={overAr} onChange={onChange} />
       </>
        }
        {props.textType==="Inclusions_EN" &&
        <>
       <RichTextEditor toolbarConfig={toolbarConfig} value={inclusionEn} onChange={onChange} />
       </>
        }
        {props.textType==="Inclusions_AR" &&
        <>
       <RichTextEditor toolbarConfig={toolbarConfig} value={inclusionAr} onChange={onChange} />
       </>
        }
        {props.textType==="Cancel_EN" &&
        <>
       <RichTextEditor toolbarConfig={toolbarConfig} value={cancelEn} onChange={onChange} />
       </>
        }
        {props.textType==="Cancel_AR" &&
        <>
       <RichTextEditor toolbarConfig={toolbarConfig} value={cancelAr} onChange={onChange} />
       </>
        }
        {props.textType==="Terms_EN" &&
        <>
       <RichTextEditor toolbarConfig={toolbarConfig} value={termEn} onChange={onChange} />
       </>
        }
        {props.textType==="Terms_AR" &&
        <>
       <RichTextEditor toolbarConfig={toolbarConfig} value={termAr} onChange={onChange} />
       </>
        }
      </div>
  )
  
  
};

export default TextEditor;