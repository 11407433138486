import PackageService from '../../services/PackageService';
/**
 * @description: CRUD operations for Branch
 * @author: Lakshmi
 * @param {*}
 * @function Package
 * @date : 13-08-2020
 */

//Loading Package data
export const getAllPackages = async () => {
    let list = [];
    await PackageService.loadPackages().then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}


//Save Package 
export const savePackage = async (data) => {
    let list = [];
    await PackageService.savePackage(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Save Package 
export const updatePackage = async (data) => {
    let list = [];
    await PackageService.updatePackage(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Save Package 
export const updatePackageStatus = async (obj) => {
    let list = [];
    await PackageService.updatePackage(obj).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}




