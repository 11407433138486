import PackageService from '../../services/PackageService';
/**
 * @description: CRUD operations for Branch
 * @author: Ubedullah
 * @param {*}
 * @function TopDestinations
 * @date : 16-05-2023
 */

//Loading TopDestinations data
export const getTopDestinations = async (obj) => {
    let list = [];
    await PackageService.loadTopDestinations(obj).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}


//Save TopDestinations 
export const saveTopDestinations = async (data) => {
    let list = [];
    await PackageService.saveTopDestinations(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}

//Update TopDestinations 
export const updateTopDestinations = async (data) => {
    let list = [];
    await PackageService.updateTopDestinations(data).then(response => {
        if (response.data.suc) {
            list = response;
        }
    });
    return Promise.resolve(list);
}




